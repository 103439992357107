import React, { useState } from "react";
import { FaFacebook } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
import "./Footer.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function Footer() {

  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  
  const location = useLocation()
  const [toggleimge, settoggle] = useState(false)
  const [toggleimge2, settoggle2] = useState(false)
  const [toggleimge3, settoggle3] = useState(false)
  const [toggleimge4, settoggle4] = useState(false) 

  return (
    <>
      <div className="container footerweb mt-5 ">
        <div className="row  h-100 boxshadowmainfooter ">
          <div className="col-2  d-flex justify-content-center align-items-center">
            <p className="  linear-text">Enbill</p>
          </div>
          <div className="col-8  d-flex flex-column justify-content-center align-items-center">
            <div className="row">
              <Link to="/dashboard" className="mr-1 ml-1 text-decoration-none footerfonts">Home | </Link>
              <Link to="/gethelp" className="ml-1 mr-1 text-decoration-none footerfonts">Get Help | </Link>
              <Link to="/privacypolicy" className="ml-1 mr-1  text-decoration-none footerfonts">Privacy Policy | </Link>
              <Link to="/termsandconditions" className="ml-1 mr-1  text-decoration-none footerfonts">Terms & Conditions | </Link>
            </div>
          </div>

          <div className="col-2  d-flex flex-column justify-content-center align-items-center">
            <div className="row">
              <p className="floowouson mb-1">Follow Us on-</p>
            </div>
            <div className="row ">
              <div className="d-flex icons-dash justify-content-end">
                <FiInstagram className="icons-name" />
                <FaFacebook className="icons-name" />
                <AiFillTwitterCircle className="icons-name" />
                <AiFillLinkedin className="icons-name" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footermobile bg-white w-100 ">
        <div className="row  justify-content-center align-content-center ">
          <div className="col-3  pr-0" onClick={() => { settoggle(false); settoggle2(false); settoggle3(false); settoggle4(false) }}>
            <div className="position-relative" style={{ top: "4px" }} >
              {location.pathname === '/dashboard' ?  
                <img src={require('../Assets/Images/customer isasascons_home.png')} className="homeimagefootermobile" onClick={()=>{settoggle(false);}}/> 
              : 
                <Link to="/dashboard">
                  <img src={require('../Assets/Images/cusewqewqtomer icons_home.png')} className="homeimagefootermobile" />
                </Link>
              }
              <p className="homenavbarfooter justify-content-center mb-0">Home</p>
            </div>
          </div>

          <div className="col-3 ">
            <div className="position-relative" style={{ top: "4px" }}>
              {location.pathname === '/search' ?   
                <img src={require('../Assets/Images/customer icsaSAsons_search.png')} className="homeimagefootermobile" onClick={()=>{settoggle2(false)}}/>
              : 
                <Link to="/search">
                  <img src={require('../Assets/Images/ddsadsadaas.png')} className="homeimagefootermobile" onClick={()=>{settoggle2(true);settoggle(false);settoggle3(false);settoggle4(false)}}/>
                </Link>
              }
              <p className="homenavbarfooter justify-content-center">Search</p>
            </div>
          </div>

          <div className="col-3 ">
            {cookies.skip != 0 ? 
              <div className="position-relative" style={{ top: "4px" }}>
                {location.pathname === '/cart' ?  
                  <img src={require('../Assets/Images/customerdsadsa icons_bag.png')} className="homeimagefootermobile" onClick={()=>{settoggle4(false)}}/>
                :  
                  <Link to="/cart">
                    <img src={require('../Assets/Images/customedsadsadr icons_bag.png')} className="homeimagefootermobile" onClick={()=>{settoggle2(false);settoggle(false);settoggle3(false);settoggle4(true)}}/>
                  </Link>
                }
                <p className="homenavbarfooter justify-content-center">Bag</p>
              </div>
            :
              <div onClick={() => {navigate("/")}} className="position-relative" style={{ top: "4px" }}>
                <img src={require('../Assets/Images/customedsadsadr icons_bag.png')} className="homeimagefootermobile" onClick={() => { settoggle2(false); settoggle(false); settoggle3(false); settoggle4(true) }} />
                <p className="homenavbarfooter justify-content-center">Bag</p>
              </div>
            }
          </div>

          <div className="col-3 ">
            {cookies.skip != 0 ? 
              <div className="position-relative" style={{ top: "4px" }}>
                {location.pathname === '/account' ?    
                  <img src={require('../Assets/Images/customer icondasdsads-account.png')} className="homeimagefootermobile" onClick={()=>{settoggle3(false)}}/>
                : 
                  <Link to="/account">
                    <img src={require('../Assets/Images/customer dsdsaicons-account.png')} className="homeimagefootermobile" onClick={()=>{settoggle3(true);settoggle2(false);settoggle(false);settoggle4(false)}}/>
                  </Link>
                }
                <p className="homenavbarfooter justify-content-center">Account</p>
              </div> 
            :
              <div onClick={()=>{navigate("/")}} className="position-relative" style={{ top: "4px" }}>
                <img src={require('../Assets/Images/customer dsdsaicons-account.png')} className="homeimagefootermobile" onClick={()=>{settoggle3(true);settoggle2(false);settoggle(false);settoggle4(false)}}/>
                <p className="homenavbarfooter justify-content-center">Account</p>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}