import "./Termsofuse.css";
import React,{useEffect,useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import Headerformobile2 from "../Header/Headerformobile2";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
export default function Termsofuse()
{
        const[cookies,setCookies,removeCookies]=useCookies(["token"])
        const[datakaa2,setdatakaa2]=useState('')
        const navigate = useNavigate();
        useEffect(()=>{
                
                const gettandcofcustomer = {
                    method: "GET",
                    url: Helper.getWsUrl(cookies.skip) + `/settings/get_terms_and_condition_customer/`,
                    headers: { 

                        "Content-Type": "application/json", "token": cookies.token 

                             },
                    };
                    axios(gettandcofcustomer)
                    .then((response) => {
                        
                        setdatakaa2(atob(response.data.data.customer_terms_and_condition))
                        console.log("terms policy",(response.data.data).customer_terms_and_condition)
                        if (response.data.code === 401) {
                                navigate(`/`)
                                removeCookies("logged_in")
                                removeCookies("token")}
                    
                    })
                  
                    .catch((err) => {
                    console.log(err)
                    })       
        },[])
       
        useEffect(() => {
                const disablePinchZoom = (e) => {
                  if (e.touches.length > 1) {
                    e.preventDefault()
                  }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                  document.removeEventListener("touchmove", disablePinchZoom)
                }
              }, [])
            
      
    
    
    
    
    
    
    
    
    
    return(
        <>
         <Headerformobile2 pagename="Terms of Use" navigate={navigate}/>
        {
                <div dangerouslySetInnerHTML={{__html: datakaa2}}   className="mtop1web mt-0 "/>
        }

           {/* <div className="mainconatainerforweb2">
                <div className="container">
                <div className='row m-lg-3 '>
                        <div className="col-12 mt-2">
                                    <div className="breacrumbmainclass1 d-none d-lg-block">
                                    <ol className="breadcrumb bg-white p-0 m-0">
                                        <li className="breadcrumb-item "><Link to="/dashboard" className=' textcolor7a1 text-decoration-none text-dark'  >Home</Link></li>
                                      
                                        <li className="breadcrumb-item text-dark"><Link to="/termsandconditions" className='textcolor7a1  text-decoration-none text-dark' >Terms of Use</Link></li>
                                    </ol>
                                    </div>
                                    <h1 className="tou d-block d-lg-none mt-2">Terms of Use</h1>
                        </div>
                        <div className="col-12 mt-2">
                                <p className="paragraphtermsofuse text-left ">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12 mt-2">
                                <p className="paragraphtermsofuse text-left ">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12 mt-2">
                                <p className="paragraphtermsofuse text-left ">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>
                        <div className="col-12">
                                <p className="paragraphtermsofuse text-left">Nunc nibh tincidunt Ut risus nec urna ex orci leo. Donec viverra gravida at faucibus elit tincidunt facilisis lacus non, Nunc ipsum eu ex. Ut volutpat fringilla cursus dui. laoreet scelerisque facilisis elit dui quis volutpat turpis non</p>
                        </div>

                        
                    </div>
                </div>
                
           </div> */}
            
        </>
    )
    
}