import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import "./Newcss.css"
import Headerformobile2 from '../Header/Headerformobile2';
import axios from "axios";
import { useCookies } from 'react-cookie'
import { useEffect } from 'react';
import Helper from '../util/Helper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from '../Header/Header';

const CartShop = ({ setKeyName, setMerchantIdForBookedItems }) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [emptystate, setemptystate] = useState(false)
    const [lazyLoading, setLazyLoading] = useState(true);
    const [bookingShopList, setBookingShopList] = useState([])

    useEffect(() => {
        const disablePinchZoom = (e) => {
                if (e.touches.length > 1) {
                        e.preventDefault()
                }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
                document.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    useEffect(() => {
        setKeyName(null)
        const getBillListUrl = {
                method: "GET",
                url: Helper.getWsUrl(cookies.skip) + `/booking/get_shop_list_for_cart/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
                .then((response) => {
                        if (response.data.code === 200) {
                                setBookingShopList(Helper.encypt_decrypt(response.data.data))
                                setemptystate(false)
                                setLazyLoading(false)
                        } else if (response.data.code === 401) {
                                removeCookie("logged_in")
                                removeCookie("token")
                                setTimeout(() => {
                                        navigate('/')
                                        localStorage.setItem("isAuthenticated", false)
                                        localStorage.removeItem("isAuthenticated")
                                }, 1000)
                        }  else if (response.data.code === 204) {
                                setemptystate(true)
                                setLazyLoading(false)
                        }
                })
                .catch((error) => {
                        alert(error);
                })
    },[])

    return (
        <>
            <Headerformobile2  pagename="Bag (Shop List)" navigate={navigate} />
            <Header />
            <div className='row no-gutters mtp100' >
                <div className='path_direction dashbbdbooking  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                    <p >
                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                        &gt;
                        <Link to="/cart" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Cart</Link>
                    </p>
                </div>
            </div>

            {(emptystate == true) ?
                <div className=' mobileviewforudhaar324  pl-2 pr-2 '>
                    <div className='emptystateset d-flex justify-content-center align-items-center flex-column h-100'>
                        <img src={require('../Assets/Images/bagcart.svg').default} className='imhgemptycart' />
                        <p className='paraforempty2 mt-3'>Add items from same or different shops.</p>
                    </div>
                </div>
            :
                
                <div className='shopCartCard' >
                    {lazyLoading ? 
                        <Skeleton height={"125px"} width={"100%"} count={6} ></Skeleton> 
                    :
                        <>
                            {bookingShopList && bookingShopList.map((value, index) => {
                                return(
                                    <div onClick={() => {setMerchantIdForBookedItems(value.contact_id); navigate('/cart/items')}} className='bookingShopCard'>
                                        <div className='bookingShopCardImgCard'>
                                            <img src={value.m_logo} onError={(e) => {
                                                if(e.target.onerror === null) {
                                                    e.target.src= require("../Assets/Images/log.png")
                                                }
                                            }}/>
                                        </div>
                                        <div className='bookingShopCardDetailCard'>
                                            <h6 className='bookingShopCard_heading'>Shop Name & Address</h6>
                                            <p className='shopName'>{value.shop_name}</p>
                                            <p className='shopAddress'>{value.address}</p>
                                            <p className='itemQty'>Total Item : <span>{value.item_qty}</span></p>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            }
        </>
    )
}

export default CartShop;