import React from 'react';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import { MarkerF } from '@react-google-maps/api'

const MapContainer = ({ userlatitude, userlongitude, showStaticMap }) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ',
  });
  const defaultCenter = {
    lat: userlatitude, lng: userlongitude
  }
  
  const marker = {
    location: {
      lat: userlatitude,
      lng: userlongitude,
    }
  }

  // const onMapClick = (e) => {
  //   setuserlatitude(e.latLng.lat());
  //   setuserlongitude(e.latLng.lng());
  // }

  const renderMap = () => (
    <GoogleMap mapContainerStyle={{ height: "60vh", width: "100%" }}
      zoom={!showStaticMap ? 13 : 4}
      center={defaultCenter} 
      // onClick={(e) => {onMapClick(e)}}
      >  
      {!showStaticMap ? 
        <MarkerF position={marker.location} ></MarkerF> 
      : 
        null
      }
    </GoogleMap>
  )
  return isLoaded ? renderMap() : null;
}

export default MapContainer

