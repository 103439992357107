import {useState} from "react"
import {BrowserRouter,Routes,Route} from "react-router-dom"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cartcomponent from './component/Cartcomponent';
import CartShop from "./component/CartShop";
import Productpage from './component/Productpage';
import Account from './component/Account/Account';
import Termsofuse from './Termsofuse/Termsofuse';
import Privacypolicy from './Privacypolicy/Privacypolicy';
import Udharshop from './Udhaar/Udharshop';
import Profile from './component/Profile';
import Dashboard from './Dashboard/Dashboard';
import Cancelorderpage from './Bookingstatus/Cancelorderpage'
import Mainudhaarfile from './Udhaar/Mainudhaarfile';
import Mainbookingstatus from './Bookingstatus/Mainbookingstatus';
import Mainproductlist from './component/Mainproductlist';
import Footer from './Footer/Footer';
import Search from './Search/Search';
import Locations from './location/Locations';
import Favorite from './favorite/Favorite';
import Login from './Login/Login';
import Billdetails from './Billrecords/Billdetails';
import GetHelp from './Gethelp/Gethelp';
import Mainbillrecordspage from './Billrecords/Mainbillrecordspage';
import Emptypage from './component/Emptypage/Emptypage';
import Address from './Address/Address'
import LocationAddress from "./Address/LocationAddress";
import AddAddress from "./Address/AddAddress";
import ProductCategory from "./component/ProductCategory";
import Chatting from "./Bookingstatus/Chatting";

function App() {

      const [ividforproductpage,setividforproductpage]=useState(null)
      const [dashcardidforshopdetails,setdashcardidforshopdetails]=useState(null)
      const [lat,setlat]=useState(null)
      const [lng,setlng]=useState(null)
      const [productpage,shoprodctpage]=useState(false)
      const [keyName, setKeyName] = useState(null);
      const [adress_mobile_dsh, setadress_mobile_dsh] = useState(null);
      const [logo_shop,setlogo_shop]=useState(null)
      const [globalLatitude, setGlobalLatitude] = useState(null)
      const [globalLongitude, setGlobalLongitude] = useState(null)
      const [bookingId,setBookingId]=useState([]);
      const [specificLatitude, setSpecificLatitude] = useState(null)
      const [specificLongitude, setSpecificLongitude] = useState(null)
      const [denyLocation,setDenyLocation]=useState(false);
      const [runEffect,setRunEffect]=useState(false)      
      const [kmData,setKmData]=useState(null);
      const [billId, setBillId] = useState(null);
      const [merchantIdForBookedItems, setMerchantIdForBookedItems] = useState(null)
      const [getAddressToAdd, setGetAddressToAdd] = useState(null)
      const [getPincodeToAdd, setGetPincodeToAdd] = useState(null)
      const [getLatToAdd, setGetLatToAdd] = useState(null)
      const [getLngToAdd, setGetLngToAdd] = useState(null)
      const [getAddressId, setGetAddressId] = useState(null)
      const [udhaarBillId, setUdhaarBillId] = useState(null);
      const [icidFrmIcToShop, setIcidFrmIcToShop] = useState(null)

      return (          
            <div className="App">              
                  <BrowserRouter>    
                        <Routes>
                              <Route path="/" element={[<Login setSpecificLatitude = {setSpecificLatitude} setSpecificLongitude = {setSpecificLongitude} />]}/>
                              <Route path="/search" element={[<Search shoprodctpage = {shoprodctpage} setividforproductpage = {setividforproductpage} setKeyName={setKeyName} keyName={keyName} setlat={setlat} setlng={setlng} lat={lat} lng={lng}/>]}/>
                              <Route path="/location" element={[<Locations  globalLongitude = {globalLongitude} globalLatitude = {globalLatitude} setGlobalLongitude = {setGlobalLongitude} setGlobalLatitude = {setGlobalLatitude} setadress_mobile_dsh={setadress_mobile_dsh} adress_mobile_dsh={adress_mobile_dsh}/>,<Footer/>]}/>
                              <Route path="/dashboard" element={[<Dashboard setIcidFrmIcToShop = {setIcidFrmIcToShop} setKmData={setKmData} kmData={kmData} keyName={keyName} setKeyName={setKeyName} logo_shop={logo_shop} setlogo_shop={setlogo_shop} setRunEffect={setRunEffect} runEffect={runEffect} denyLocation={denyLocation} setDenyLocation={setDenyLocation} specificLongitude = {specificLongitude} specificLatitude = {specificLatitude} globalLongitude = {globalLongitude} globalLatitude = {globalLatitude} setividforproductpage = {setividforproductpage} shoprodctpage = {shoprodctpage}  setdashcardidforshopdetails={setdashcardidforshopdetails} dashcardidforshopdetails={dashcardidforshopdetails} setlat={setlat} setlng={setlng} lat={lat} lng={lng} adress_mobile_dsh={adress_mobile_dsh}/>,<Footer/>]}/>
                              <Route path="/shop/itemCategory" element={[<ProductCategory setIcidFrmIcToShop = {setIcidFrmIcToShop} setKeyName={setKeyName} />,<Footer/>]} />
                              <Route path="/shop" element={[<Mainproductlist icidFrmIcToShop = {icidFrmIcToShop} setKmData={setKmData} kmData={kmData} setKeyName={setKeyName} keyName = {keyName} shoprodctpage={shoprodctpage} productpage={productpage} ividforproductpage={ividforproductpage} setividforproductpage={setividforproductpage}  setdashcardidforshopdetails={setdashcardidforshopdetails} dashcardidforshopdetails={dashcardidforshopdetails} setlat={setlat} setlng={setlng} lat={lat} lng={lng} logo_shop={logo_shop} />,<Footer/>]}/>
                              <Route path="/shop/product" element={[<Productpage setKmData={setKmData} kmData={kmData} keyName= {keyName} ividforproductpage={ividforproductpage}/>,<Footer/>]}/>
                              <Route path="/bookingstatus" element={[<Mainbookingstatus setBookingId = {setBookingId} />,<Footer/>]}/>
                              <Route path="/bookingstatus/detail" element={[<Cancelorderpage bookingId = {bookingId} />,<Footer/>]}/>
                              <Route path="/chatting" element={[<Chatting bookingId = {bookingId} />]} />                          
                              <Route path="/billrecords" element={[<Mainbillrecordspage setBillId = {setBillId} />,<Footer/>]}/>
                              <Route path="/billdetail" element={[<Billdetails billId = {billId} />,<Footer/>]}/>
                              <Route path="/cart" element={[<CartShop keyName={keyName} setMerchantIdForBookedItems = {setMerchantIdForBookedItems} setKeyName={setKeyName} />,<Footer/>]}/>
                              <Route path="/cart/items" element={[<Cartcomponent keyName={keyName} merchantIdForBookedItems = {merchantIdForBookedItems} setKeyName={setKeyName} />]}/>
                              <Route path="/address" element={[<Address setGetAddressId = {setGetAddressId} setKeyName={setKeyName} />,<Footer/>]}/>
                              <Route path="/address/location" element={[<LocationAddress  globalLongitude = {globalLongitude} globalLatitude = {globalLatitude} getAddressToAdd = {getAddressToAdd} setGetLngToAdd = {setGetLngToAdd} setGetLatToAdd = {setGetLatToAdd} setGetAddressToAdd = {setGetAddressToAdd} setGetPincodeToAdd = {setGetPincodeToAdd} />]}/>
                              <Route path="/address/addaddress" element={[<AddAddress getAddressId = {getAddressId} setGetLngToAdd = {setGetLngToAdd} setGetLatToAdd = {setGetLatToAdd} setGetAddressToAdd = {setGetAddressToAdd} setGetPincodeToAdd = {setGetPincodeToAdd} getLngToAdd = {getLngToAdd} getLatToAdd = {getLatToAdd} getPincodeToAdd = {getPincodeToAdd} getAddressToAdd = {getAddressToAdd} />]} />
                              <Route path="/account" element={[<Account setKeyName={setKeyName} keyName = {keyName}  />,<Footer/>]}/>
                              <Route path="/udhaar" element={[<Mainudhaarfile setUdhaarBillId = {setUdhaarBillId} />,<Footer/>]}/>
                              <Route path="/udharshop" element={[<Udharshop setBillId={setBillId} udhaarBillId = {udhaarBillId} />,<Footer/>]}/>
                              <Route path="/favourite" element={[<Favorite setdashcardidforshopdetails={setdashcardidforshopdetails} dashcardidforshopdetails={dashcardidforshopdetails}  ividforproductpage={ividforproductpage} setividforproductpage={setividforproductpage} shoprodctpage={shoprodctpage} lat={lat} lng={lng}/>,<Footer/>]}/>
                              <Route path="/profile/" element={[<Profile keyName={keyName} setKeyName={setKeyName} />,<Footer/>]}/>
                              <Route path="/termsandconditions" element={[<Termsofuse/>,<Footer/>]}/>
                              <Route path="/privacypolicy" element={[<Privacypolicy/>,<Footer/>]}/>
                              <Route path="/emptypage" element={[<Emptypage/>,<Footer/>]}/>
                              <Route path="/gethelp" element={[<GetHelp/>,<Footer/>]}/>
                              <Route path="/*" element={<h1>PAGE LEFT </h1>}/>                                                  
                        </Routes>               
                  </BrowserRouter>             
            </div>
      );
}

export default App;
