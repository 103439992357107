import "./Privacypolicy.css";
import React, { useEffect, useState } from "react";
import Headerformobile2 from "../Header/Headerformobile2";
import { Link, useNavigate } from "react-router-dom";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";
export default function Privacypolicy() {
  const [cookies, setCookies, removeCookies] = useCookies(["token"])
  const [pridata, setPriData] = useState(null);
  const [key, setKey] = useState(null)
  const [datakaa, setdatakaa] = useState('');

  useEffect(() => {

    const conprivacypolicy = {
      method: "GET",
      url: Helper.getWsUrl(cookies.skip) + `/settings/get_privacy_policy_customer/?web=1`,
      headers: {

        "Content-Type": "application/json", "token": cookies.token

      },
    };
    axios(conprivacypolicy)
      .then((response) => {
        setdatakaa(atob(response.data.data.customer_privacy_policy))
        console.log("privacy policy", (response.data.data.customer_privacy_policy))

        if (response.data.code === 401) {
          navigate(`/`)
          removeCookies("logged_in")
          removeCookies("token")
        }


        // console.log("privacy policy",Helper.encypt_decrypt(response.data.data).customer_privacy_policy))
        // console.log("privacy policy",Helper.encypt_decrypt(response.data.data).customer_privacy_policy)

      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  console.log("keyy", pridata)





  const navigate = useNavigate()


  return (
    <>
      <Headerformobile2 pagename="Privacy Policy" navigate={navigate} />


      {
        <div dangerouslySetInnerHTML={{ __html: datakaa }} className="mtop1webprivacy mt-0 " />
      }



    </>
  )

}