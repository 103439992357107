import React, { useEffect, useState } from "react";
import Productlist from "./Productlist";
import Productpage from "./Productpage";
import Headerformobile2 from "../Header/Headerformobile2";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

export default function Mainproductlist({keyName, setKeyName, logo_shop,setividforproductpage, shoprodctpage, dashcardidforshopdetails,lat,lng,kmData,setKmData, icidFrmIcToShop}) {

    const navigate=useNavigate()

    const [searchItem, setSearchItem] = useState(null)

    useEffect(()=>{
      setKeyName(null);
    },[])

    return(
        <>
            <Headerformobile2 searchItem = {searchItem} setSearchItem = {setSearchItem} pagename="Shop" navigate={navigate}/>
            <Header />         
            {/* {productpage ?
                <Productpage logo_shop={logo_shop} keyName= {keyName} ividforproductpage={ividforproductpage} shoprodctpage={shoprodctpage} />
            : */}
                <Productlist icidFrmIcToShop = {icidFrmIcToShop} searchItem = {searchItem} kmData={kmData} setKmData={setKmData} logo_shop={logo_shop} setividforproductpage={setividforproductpage} shoprodctpage={shoprodctpage} dashcardidforshopdetails={dashcardidforshopdetails} lng={lng} lat={lat}  />
            {/* }  */}
        </>
    )
}