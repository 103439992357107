import React, { Component, useRef } from "react";
import OtpInput from "react-otp-input";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Login.css";
import { useState } from "react";
import Countdown from "react-countdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useEffect } from "react";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function Login({ setSpecificLongitude, setSpecificLatitude }) {

  const [cookies, setCookies, removeCookies] = useCookies(
    ["token"],
    ["logged_in"], ['skip']
  );
  const [numberscreen, setnumberscree] = useState(true);
  const [otpscreen, setotpscreen] = useState(false);
  const [img1, setimg1] = useState(true);
  const [fotter1login, setfotter1login] = useState(true);
  const [img2, setimg2] = useState(false);
  const [fotter1login2, setfotter1login2] = useState(false);
  const [img3, setimg3] = useState(false);
  const [fotter1login3, setfotter1login3] = useState(false);
  const [mobilecrousel, setmobilecrousel] = useState(false);
  const [logintootp, setlogintootp] = useState(true);
  const [inptotp, setinptotp] = useState(true);
  const [timer, settimer] = useState(true);
  const [phonenumber, setphonenumber] = useState("");
  const [phonenumber22, setphonenumber22] = useState("");
  const [otp1, setotp1] = useState(" ");
  const [hideresendotp, sethideresendotp] = useState(true);
  const [otp, setotp] = useState(" ");
  const [countdownsh, setcountdownsh] = useState(true);
  const [dashboardlink, setdashboardlink] = useState(true);
  const [otpbyuser, setotpbyuser] = useState("");
  const [otpbyuser2, setotpbyuser2] = useState("");
  const [dashonoff, setdashonoff] = useState(false);
  const [sentoptonclick, setsentoptonclick] = useState("");
  const [enteravalidotp, setenteravalidotp] = useState(true)
  const [otpmatched, setotpmatched] = useState(true);
  const [input_notmatching_onclick, setinput_notmatching_onclick] = useState(false);

  let navigate = useNavigate();

  function chckphonenumber(event) {
    const value11 = event.target.value.replace(/\D/g, "");
    setphonenumber(value11);
  }

  function chckphonenumber22(event) {
    const value112 = event.target.value.replace(/\D/g, "");
    setphonenumber22(value112);
  }

  function HandleChange(otp) {
    setotp(otp.replace(/\D/g, " "));
    setotpbyuser(otp.replace(/\D/g, ""));
    setotpmatched(true);
  }

  function HandleChange1(otp1) {
    setotp1(otp1.replace(/\D/g, " "));
    setotpbyuser2(otp1.replace(/\D/g, ""));
    setotpmatched(true);
  }

  const increment = useRef(null);
  const [time, setTime] = useState(30);

  const handleStart = () => {
    increment.current = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setcountdownsh(false);
    sethideresendotp(false);
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setTime(30);
  };

  const formatTime = () => {
    const getSeconds = `0${time % 60}`.slice(-2);
    if (getSeconds <= 0) {
      setcountdownsh(false);
      sethideresendotp(false);
      handlePause();
      return "00 : 00";
    } else {
      return `00 : ${getSeconds}`;
    }
  };

  const [opacity, setopacity] = useState(true)

  const getotponclick = () => {
    const requestOptions = {
      method: "POST",
      url: Helper.getWsUrl(1) + `/otp/send_otp/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        app_type: 3,
        mobile_number:
          (phonenumber && phonenumber) || (phonenumber22 && phonenumber22),
        version: 1.15,
      },
    };
    axios(requestOptions)
      .then((response) => {
        if (response.data.code === 200) {
          setsentoptonclick(Helper.encypt_decrypt(response.data.data).otp);
        } else if
          (response.data.code === 401) {
          removeCookies("logged_in");
          // removeCookies("token")
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const matchOtpFunc = () => {
    const requestOptions2 = {
      method: "POST",
      url: Helper.getWsUrl(1) + `/otp/match_otp/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        app_type: 3,
        mobile_number: phonenumber || phonenumber22,
        otp: otpbyuser.toString() || otpbyuser2.toString(),
      },
    };
    axios(requestOptions2)
      .then((response) => {
        if (response.data.code === 200) {
          if (phonenumber22 === "9354564599") {
            setSpecificLongitude("78.1821")
            setSpecificLatitude("26.2100")
          }
          setSpecificLongitude(null)
          setSpecificLatitude(null)
          setCookies("token", response.headers.token);
          setCookies("skip", 1);
          navigate("/dashboard");
        } else if (response.data.code === 204) {
          console.log("MATCH for token", response);
          setinput_notmatching_onclick(true);
          setenteravalidotp(true)
          setotpmatched(false)
        } else if (response.data.code === 401) {
          removeCookies("logged_in");
          setTimeout(() => {
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="webviewlogin">
        <div className="div  d-flex justify-content-center align-items-center maindivforlogin p-5 overflow-auto webviewlogin">
          <div className="div div1  d-flex justify-content-center align-items-center">
            <div className="container ">
              <div className="row justify-content-center align-items-center heightrow">
                <div className="col-11 ">
                  <h2 className="localgrahak1">
                    {" "}
                    Local Grahak <br /> Local Dukaan{" "}
                  </h2>
                </div>
                <div className="col-10   ">
                  <Carousel
                    className="cardashboard14 mt-3"
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={2000}
                  >
                    <div className="card  border-0">
                      <img
                        src={require("../Assets/Images/background.png")}
                        className="d-block hwimagecarousellogin m-auto "
                      />
                      <div className="card-body ">
                        <p className="card-text webscreenh5 justify-content-center ">
                          Know your locality more
                        </p>
                        <p className="card-text webscreenhp">
                          {" "}
                          Ek hi shop par kyu hai jana <br /> Multiple shops kre
                          try!!
                        </p>
                      </div>
                    </div>
                    <div className="card  border-0">
                      <img
                        src={require("../Assets/Images/background1.png")}
                        className="d-block hwimagecarousellogin m-auto "
                      />
                      <div className="card-body ">
                        <h5 className="card-text webscreenh5 justify-content-center ">
                          Easy to pay
                        </h5>
                        <p className="card-text webscreenhp">
                          {" "}
                          Pie pie bachana h, to kro digitally pay
                        </p>
                      </div>
                    </div>
                    <div className="card border-0 ">
                      <img
                        src={require("../Assets/Images/background2.png")}
                        className="d-block hwimagecarousellogin m-auto "
                      />
                      <div className="card-body ">
                        <h5 className="card-text webscreenh5 text-center justify-content-center">
                          Search, Book, Go to bag
                        </h5>
                        <p className="card-text webscreenhp">
                          {" "}
                          Ab hazaaron options m se chune apna pasandida item or
                          jb mrji book kre.
                        </p>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          {numberscreen ? (
            <div className="div div2  d-flex justify-content-center align-items-center">
              <div className="container ">
                <div className="row justify-content-center align-items-center heightrow">
                  <div className="col-11  ">
                    <img src={require("../Assets/Images/log.png")} className="hwlogoenbill" />
                  </div>

                  <div className="col-11  mt-5">
                    <h5 className="entermnum">Enter your mobile number</h5>
                  </div>

                  <div className="col-11 mt-3 d-flex justify-content-center">
                    <div
                      style={{ width: "306px" }}
                      className=" position-relative"
                    >
                      <input
                        type="text"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        id="contact"
                        className=" inputboxenternumberlogin "
                        onChange={chckphonenumber}
                        maxLength="10"
                        value={phonenumber}
                        autoComplete="off"
                        autoFocus
                      ></input>
                      <span className=" sidp91none"> +91 </span>
                    </div>
                  </div>

                  <div className="col-11  mt-4">
                    {phonenumber.length >= 1 && phonenumber.length <= 9 ? (
                      <button type="button" className="btn-sm getotpbtn ">
                        {" "}
                        Enter a valid number{" "}
                      </button>
                    ) : null}

                    {phonenumber.length == 0 ? (
                      <button type="button" className="btn-sm getotpbtn ">
                        {" "}
                        Get OTP{" "}
                      </button>
                    ) : null}
                    {phonenumber.length == 10 ? (
                      <button
                        type="button"
                        className="btn-sm getotpbtn "
                        onClick={() => {
                          setnumberscree(false);
                          setotpscreen(true);
                          getotponclick();
                          handleReset();
                          handleStart();
                        }}
                      >
                        {" "}
                        Get OTP{" "}
                      </button>
                    ) : null}
                  </div>
                  <div className="col-11  mt-4">
                    <h6 className="reciveotp">
                      {" "}
                      You will receive One Time Password <br />
                      to this number{" "}
                    </h6>
                  </div>
                  <div className="col-11  mt-3">
                    <h6 className="recive1timeotp">
                      {" "}
                      By continuing you agree to our <br /> Terms of Use and
                      Privacy Policy.{" "}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* newdiv after otp */}
          {otpscreen ? (
            <div className="div div2  d-flex d-flex justify-content-center align-items-center">
              <div className="container ">
                <div className="row justify-content-center align-items-center heightrow">
                  <div className="col-11  mb-3">
                    <img src={require('../Assets/Images/log.png')} className="hwlogoenbill" />
                  </div>

                  <div className="col-11  mt-4">
                    <h5 className="entermnum">
                      Enter One Time Pasword received on <br></br>+91{" "}
                      {phonenumber}
                    </h5>
                  </div>

                  <div className="col-11 mt-4 d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={HandleChange}
                      shouldAutoFocus="true"
                      numInputs={4}
                      className={
                        input_notmatching_onclick
                          ? "inputofotponchange"
                          : "inputofotp"
                      }
                      autoComplete="off"
                      // autoFocus="true"
                      isInputNum={Boolean}
                    />
                  </div>

                  <div className="col-11  mt-5">
                    {otpbyuser.length >= 1 && otpbyuser.length < 4 ? (
                      <button type="button" className="btn-sm getotpbtn">
                        Enter a valid OTP
                      </button>
                    ) : null}

                    {otpbyuser.length == 0 ? (
                      <button type="button" className="btn-sm getotpbtn">
                        Continue
                      </button>
                    ) : null}

                    {otpbyuser.length == 4 ? (
                      <button
                        type="button"
                        className="btn-sm getotpbtn"
                        onClick={() => {
                          setdashonoff(true);
                          matchOtpFunc();
                        }}
                      >
                        {otpmatched ? "Match otp" : "Enter a valid OTP"}
                      </button>
                    ) : null}
                  </div>
                  <div className="col-11  mt-1">
                    {countdownsh ? (
                      <h6 >
                        <p className="webtimecolor mt-2">{formatTime()}</p>
                        <span className="resenweb">Resend OTP </span>
                      </h6>
                    ) : null}
                  </div>
                  <div className="col-11  mt-3">
                    {hideresendotp ? null : (
                      <button
                        className="resendotptext border-0 bg-transparent"
                        onClick={() => {
                          setcountdownsh(true);
                          sethideresendotp(true);
                          handleReset();
                          handleStart();
                          getotponclick();
                        }}
                      >
                        {" "}
                        Resend OTP{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* mobile            */}

      <div className={opacity ? "mobileviewforlogin" : "mobileviewforlogin opacadd"}>
        {mobilecrousel ? (
          <div className="webdivformobile d-flex ">
            <div className="row justify-content-center no-gutters ">
              <div className="col-11  p-0 d-flex justify-content-end">
                <button
                  className="border-0 bg-transparent d-flex justify-content-center align-items-center "
                  onClick={() => {
                    setmobilecrousel(false);
                  }}
                >
                  <p className=" skipbtn mb-0">Skip</p>
                </button>
              </div>
              <div className="col-11  p-0 d-flex justify-content-center">
                <h2 className="localgrahak13">
                  {" "}
                  Local Grahak <br /> Local Dukaan{" "}
                </h2>
              </div>
              {img1 ? (
                <div className="col-11  p-0 d-flex justify-content-center">
                  <div className="card  border-0 mt-1">
                    <img
                      src={require('../Assets/Images/background.png')}
                      className="d-block hwimagecarousellogin21 m-auto "
                    />
                    <div className="card-body ">
                      <p className="webscreenh52 justify-content-center mb-0 mt-2">
                        Know your locality more
                      </p>
                      <p className="webscreenhp2 mt-3">
                        {" "}
                        Ek hi shop par kyu hai jana Multiple shops kre try!!
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {img2 ? (
                <div className="col-11  p-0 d-flex justify-content-center">
                  <div className="card  border-0 mt-1">
                    <img
                      src={require('../Assets/Images/background1.png')}
                      className="d-block hwimagecarousellogin21 m-auto "
                    />
                    <div className="card-body ">
                      <p className="webscreenh52 justify-content-center mb-0 mt-2">
                        Easy to pay
                      </p>
                      <p className="webscreenhp2 mt-3">
                        {" "}
                        Pie pie bachana h, to kro digitally pay
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {img3 ? (
                <div className="col-11  p-0 d-flex justify-content-center">
                  <div className="card  border-0 mt-1">
                    <img
                      src={require('../Assets/Images/background2.png')}
                      className="d-block hwimagecarousellogin21 m-auto "
                    />
                    <div className="card-body ">
                      <p className="webscreenh52 justify-content-center mb-0 mt-2">
                        Search, Book, Go to bag
                      </p>
                      <p className="webscreenhp2 mt-3">
                        {" "}
                        Ab hazaaron options m se chune apna pasandida item or jb
                        mrji book kre.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="col-11  p-0 d-flex justify-content-center">
                {fotter1login ? (
                  <div className="row justify-content-between w-100 p-2 ">
                    <div className="col-4 p-2 ">
                      <p className="step123 mb-1">Step 1 of 3</p>
                      <ProgressBar now={33.33} className="progressbarstep" />
                    </div>
                    <div className="col-5  d-flex  justify-content-end p-0">
                      <button
                        className="nextbtnlogin "
                        onClick={() => {
                          setimg1(false);
                          setfotter1login(false);
                          setimg2(true);
                          setfotter1login2(true);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : null}
                {fotter1login2 ? (
                  <div className="row justify-content-between w-100 p-2 ">
                    <div className="col-4 p-2 ">
                      <p className="step123 mb-1">Step 2 of 3</p>
                      <ProgressBar now={66.66} className="progressbarstep" />
                    </div>
                    <div className="col-5  d-flex  justify-content-end p-0">
                      <button
                        className="nextbtnlogin "
                        onClick={() => {
                          setimg1(false);
                          setfotter1login(false);
                          setimg2(false);
                          setfotter1login2(false);
                          setimg3(true);
                          setfotter1login3(true);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : null}
                {fotter1login3 ? (
                  <div className="row justify-content-between w-100 p-2 ">
                    <div className="col-4 p-2 ">
                      <p className="step123 mb-1">Step 3 of 3</p>
                      <ProgressBar now={100} className="progressbarstep" />
                    </div>
                    <div className="col-5  d-flex  justify-content-end p-0">
                      <button
                        className="nextbtnlogin "
                        onClick={() => {
                          setimg1(false);
                          setfotter1login(false);
                          setimg2(false);
                          setfotter1login2(false);
                          setimg3(false);
                          setfotter1login3(false);
                          setmobilecrousel(false);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          // component after carousel

          <div className="divformobilelogin2 ">
            <div className="row justify-content-center no-gutters">
            <div style={{display: "block",
                zIndex: "1",
                position: "absolute",
                width: "100%",
                marginTop: "40px"}}>
                {
                cookies.skip != 0 ? null :

                 <button className=" p-2 backLogin" onClick={() => { navigate(-1) }} >
                  <img src={require('../Assets/Images/Left 2.png')} className="backLoginimg" />
                 </button>

                 
                 }
                  <button className="skipLoginbtn" onClick={() => { setSpecificLongitude("78.1821"); setSpecificLatitude("26.2100"); setCookies('skip', 0); navigate("/dashboard") }}>Skip</button>
                </div>
              <div className="col-12 p-0  " style={{ position: "relative" }}>
                <img
                  src={require("../Assets/Images/Artboard 1 copy 1.png")}
                  className="imageformobillogin"
                ></img>
              
               
               


              </div>

              <div className="col-12 p-0  mt-3">
                <h2 className="localgrahak1mob">

                  Local Grahak<br />Local Dukaan
                </h2>
              </div>

              <div className="col-11 p-0  ">
                {logintootp ? (
                  <h2 className="localgrahak1mobsignup pb-1">
                    {" "}
                    Log in or Sign up{" "}
                  </h2>
                ) : (
                  <h2 className="localgrahak1mobsignup pb-1">
                    {" "}
                    OTP Verification{" "}
                  </h2>
                )}
              </div>
              <div className="col-12  d-flex justify-content-center">
                {inptotp ? (
                  <div
                    style={{ width: "335px" }}
                    className=" position-relative"
                  >
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      id="contact2"
                      className=" inputboxenternumberlogin23 "
                      onChange={chckphonenumber22}
                      maxLength={10}
                      value={phonenumber22}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          setlogintootp(false);
                          setinptotp(false);
                          settimer(false);
                          getotponclick();
                          handleReset();
                          handleStart();
                          // checkDeactivatedNumber();
                        }
                      }}
                    ></input>
                    <span className=" sidp91none23">
                      {" "}
                      <img
                        src={require('../Assets/Images/cross.svg').default}
                        onClick={() => {
                          console.log(
                            (document.querySelector("#contact2").value = " ")
                          );
                        }}
                      ></img>
                    </span>
                    {phonenumber22.length <= 9 ? (
                      <button style={{ whiteSpace: "nowrap" }} type="button" className="btn-sm getotpbtn22 mt-2">
                        {" "}
                        Confirm and Continue{" "}
                      </button>
                    ) : (
                      <button
                        style={{ whiteSpace: "nowrap" }}
                        type="button"
                        className="btn-sm getotpbtn2233 mt-2"
                        onClick={() => {
                          setlogintootp(false);
                          setinptotp(false);
                          settimer(false);
                          getotponclick();
                          // checkDeactivatedNumber()
                          handleReset();
                          handleStart();
                        }}
                      >
                        Confirm and Continue
                      </button>
                    )}
                  </div>
                ) : (
                  <div>
                    <OtpInput
                      value={otp1}
                      onChange={HandleChange1}
                      numInputs={4}
                      shouldAutoFocus="true"
                      //  className="inputofotp223"
                      className={
                        input_notmatching_onclick
                          ? "inputofotp223"
                          : "inputofotp22"
                      }
                      autoComplete="off"
                      isInputNum={Boolean}
                    />

                    <div className="">
                      {otpbyuser2.length >= 1 && otpbyuser2.length < 4 ? (
                        <button
                          type="button"
                          className="btn-sm getotpbtn22335 mt-2"
                        >
                          Confirm and Continue
                        </button>
                      ) : null}

                      {otpbyuser2.length == 0 ? (
                        <button
                          type="button"
                          className="btn-sm getotpbtn22335 mt-2"
                        >
                          Confirm and Continue
                        </button>
                      ) : null}

                      {otpbyuser2.length == 4 ? (
                        <button
                          type="button"
                          className={(otpmatched == true) ? "btn-sm getotpbtn22335 mt-2" : "btn-sm getotpbtn223354 mt-2"}


                          onClick={() => {
                            setdashonoff(true);
                            matchOtpFunc();
                          }}
                        >
                          {otpmatched ? "Confirm " : "Enter Correct OTP"}
                        </button>
                      ) : null}
                      {/* {
                                        (otpbyuser2== sentoptonclick)?<p>otp match</p>:<p>not matched</p>
                                       } */}
                    </div>
                  </div>
                )}
              </div>
              {/* <button style={{width:"37%"}} type="button" className="btn-sm getotpbtn22335 mt-2" onClick={() => {setCookies('skip', 0);navigate("/dashboard")}}>Skip login</button> */}

              <div className="col-12 p-0  mt-3"></div>
            </div>
            <div className="col-12 p-0  mt-n2">
              {
                timer ? (
                  <p className="termsandcond2">
                    By clicking, I accept the T&C and Privacy
                    Policy
                  </p>
                ) : (
                  <div>
                    {countdownsh ? (
                      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        {/* <Countdown
                          date={Date.now() + 30000}
                          renderer={renderer}
                        /> */}
                        <h6 onClick={() => { window.location.reload() }} style={{ fontSize: "16px", marginTop: "-7px", textAlign: "center", fontWeight: "500", cursor: "pointer" }}>
                          Re-enter your number |
                        </h6>

                        <h6 style={{ fontSize: "16px", marginTop: "-7px", textAlign: "center", fontWeight: "500" }}>
                          Resend OTP in {formatTime()}
                        </h6>
                      </div>
                    ) : null}
                    {hideresendotp ? null : (
                      <button
                        className="resendotptext text-dark border-0 bg-transparent fsresend"
                        style={{ fontSize: "12pt", marginTop: "-7px", textAlign: "center", fontWeight: "500" }}
                        onClick={() => {
                          setcountdownsh(true);
                          sethideresendotp(true);
                          handleReset();
                          handleStart();
                          getotponclick();
                        }}
                      >
                        {" "}
                        Resend OTP{" "}
                      </button>
                    )}
                  </div>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Login;
