import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Chatting.css';
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useEffect, useRef } from 'react';
import Headerformobile2 from "../Header/Headerformobile2";
// import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Chatting = ({bookingId}) => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [getBookingChatList, setGetBookingChatList] = useState([])
    const [bookingChatTempList, setBookingChatTempList] = useState([])
    const [selectedChatTempId, setSelectedChatTempId] = useState(null)
    const [selectedChatTempMsg, setSelectedChatTempMsg] = useState(null)
    const [chattingEmptyState, setChattingEmptyState] = useState(false)
    const [open, setOpen] = useState(false); 

    const ScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }));
        return <div ref={elementRef} />;
    };
    
    useEffect(() => {
        if(bookingId && bookingId.length > 0) {
            getBookingChat()
            getCustomerBookingTemp()
        } else {
            navigate('/bookingstatus')
        }
    },[bookingId])

    const getBookingChat = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_chat_new/${bookingId}/3/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {        
                if (response.data.code === 200) {
                    setGetBookingChatList(Helper.encypt_decrypt(response.data.data))
                    console.log("chat data ", Helper.encypt_decrypt(response.data.data))
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                    navigate("/");
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("isAuthenticated");
                    }, 1000);
                } else {
                    setChattingEmptyState(true)
                    setGetBookingChatList([])
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    
    const getCustomerBookingTemp = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_temp_new/?profile_type=3&web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {        
                if (response.data.code === 200) {
                    setBookingChatTempList(Helper.encypt_decrypt(response.data.data).temp)
                    console.log(Helper.encypt_decrypt(response.data.data))
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                    navigate("/");
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const postCustomerBookingChat = () => {
        if(selectedChatTempMsg && selectedChatTempMsg != null) {
            const request = {
                method: "POST",
                url: Helper.getWsUrl(cookies.skip) + `/booking/add_booking_chat_new/${bookingId}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
                data: {
                    temp_id: selectedChatTempId && selectedChatTempId,
                    added_by: 3
                }
            }
            axios(request)
                .then((response) => {        
                    if (response.data.code === 201) {
                        setSelectedChatTempMsg(null)
                        getBookingChat()
                    } else if(response.data.code === 401) {
                        removeCookie("logged_in");
                        setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Headerformobile2 pagename="Chatting" navigate={navigate} />  
            <div className='chattingMainDiv'>
                {chattingEmptyState ? 
                    <div className='chattingEmptyData'>
                        <img src={require("../Assets/Images/No-Messages-Yet_Customer.png")} />
                    </div>
                :
                <>
                    <div className='chattingData'>
                        {getBookingChatList && getBookingChatList.map((value) => {
                            return (
                                <div style={{alignItems: value.added_by != 0 ? "end" : "start"}} className='chatDiv'>
                                    <div className='chatMessage' style={{background: value.added_by != 0 ? "#ee7a37" : "#008c53"}}>
                                        <p>{value.msg}</p>
                                        <small style={{padding: value.added_by != 0 ? "0" : "3px 0"}}>{value.add_date_time.slice(-8)} 
                                            <span>
                                                {value.added_by != 0 && value.seen != 0 ? 
                                                    <DoneAllIcon sx={{width:"15px", margin:"0 0 2px 5px",height:"auto"}}></DoneAllIcon>
                                                : null
                                                }
                                            </span>
                                        </small>
                                    </div>
                                </div>
                            )
                        })}
                        <ScrollToBottom />
                    </div>
                   
                    </>
                }

                <div className='inputSendBtn'>
                    <div onClick={handleClickOpen} className='inputField'>
                        {selectedChatTempMsg && selectedChatTempMsg != null ? 
                            <p>{selectedChatTempMsg}</p>
                        :
                            <p>Type a message...</p>
                        }
                    </div>
                    <button>
                        <img onClick={() => {postCustomerBookingChat()}} src={require("../Assets/Images/Send-Icon-Customer.png")} />
                    </button>
                </div>

                <Dialog PaperProps={{
                        style: {
                                position: 'absolute',
                                bottom: '0',
                                color: 'black',
                                width: '100%',
                                height: '50%',
                                maxWidth: '100%',
                                padding: "0",
                                borderRadius: "15px 15px 0 0",
                                margin: '0',
                                overflow:"hidden"
                        },
                        }} open={open} onClose={handleClose} TransitionComponent={Transition} >
                    <IconButton
                        edge="start"
                        onClick={handleClose}
                        aria-label="close">
                        <ExpandMoreIcon />
                    </IconButton>
                    <Toolbar>
                        <div className='bookingChatTemplate'>
                            {bookingChatTempList && bookingChatTempList.map((value, index) => {
                                return (
                                    <>
                                        <p onClick={() => {setSelectedChatTempId(value.temp_id); setSelectedChatTempMsg(value.temp); handleClose();}}>{value.temp}</p>
                                        <hr />
                                    </>
                                )
                            })}
                        </div>
                    </Toolbar>
                </Dialog>
            </div>
        </>
    )
}

export default Chatting;