import React, { useEffect, useState } from "react";
import Billrecords from "./Bllrecords";
import Billdetails from "./Billdetails";

const Mainbillrecordspage = ({setBillId}) => {

  const [billdetails, showbilldetails] = useState(false);
  const [billList, setBillList] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [nodata7days, setNodata7days] = useState(false);
  const [nodata7daysbtn, setNodata7daysbtn] = useState(false);
  const [nodatatoday,setnodatatoday] = useState(false);
  const [nodatabtn,setnodatabtn] = useState(false);  
  const [nodatacustom,setnodatacustom]=useState(false);
  const [nodatacustombtn,setNodatacustombtn]=useState(false);
  const [clickToday,setClickToday]=useState(false);
  const [click7Days,setClick7Days]=useState(false);
  // const current = new Date();
  // const startDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`; 
  // const date = moment().subtract(7, "days").calendar();
  // const last7days = moment(date).format("YYYY-MM-D");

  return (
    <>
      {/* {billdetails ? 
        <Billdetails billId={billId} setBillId={setBillId} />
      :  */}
        <Billrecords setClick7Days={setClick7Days} click7Days={click7Days} clickToday={clickToday} setClickToday={setClickToday} setNodatacustombtn={setNodatacustombtn} 
          nodatacustombtn={nodatacustombtn} setnodatacustom={setnodatacustom} nodatacustom={nodatacustom} nodatatoday={nodatatoday} setnodatatoday={setnodatatoday} nodatabtn={nodatabtn} 
          setnodatabtn={setnodatabtn} setNodata7days={setNodata7days} nodata7days={nodata7days} nodata7daysbtn={nodata7daysbtn} setNodata7daysbtn={setNodata7daysbtn} setWeekData={setWeekData} 
          weekData={weekData} setBillId={setBillId} billList={billList} setBillList={setBillList} showbilldetails={showbilldetails} />
      {/* } */}
    </>
  );
};
export default Mainbillrecordspage;
