import React, { useEffect, useState } from "react";
import "./Address.css";
import { Link, useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import MapContainer from "./MapForAddress";
import { useRef } from 'react';

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

const LocationAddress = ({setGetLngToAdd, setGetLatToAdd, setGetAddressToAdd, setGetPincodeToAdd, getAddressToAdd, globalLongitude, globalLatitude}) => {

    const google = window.google;
    const navigate = useNavigate()
    const ref = useRef(null);
    
    const [userAddressLat, setUserAddressLat] = useState(null);
    const [userAddressLng, setUserAddressLng] = useState(null);
    const [addressPinCode, setAddressPinCode] = useState(null);
    const [showStaticAddressMap, setShowStaticAddressMap] = useState(false)

    useEffect(() => {
        if (addressPinCode != null) {
            Geocode.fromAddress(addressPinCode).then(({ results }) => {
                setUserAddressLat(results[0].geometry.location.lat)
                setUserAddressLng(results[0].geometry.location.lng)
            });
        } else {
            // navigator.geolocation.getCurrentPosition(
            //     function (position) {
            //         setUserAddressLat(position.coords.latitude)
            //         setUserAddressLng(position.coords.longitude)
            //     },
            //     function (error) {
            //         setShowStaticAddressMap(true)
            //     }
            // );
            setUserAddressLat(globalLatitude)
            setUserAddressLng(globalLongitude)
        }
    },[addressPinCode])

    useEffect(() => {
        if (userAddressLat && userAddressLat != null && userAddressLng && userAddressLng != null) {
            getReverseGeocodingData(userAddressLat, userAddressLng)
        }
    },[userAddressLat, userAddressLng])
    
    const handleClick = () => {
        ref.current.focus();
    };

    const handleChange = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          setAddressPinCode(event.target.value);
          setShowStaticAddressMap(false)
          window.reload()
        }
    }

    const getReverseGeocodingData = (lat, long) => {
        Geocode.fromLatLng(lat, long).then(
          (response) => {
            const length = response.results[0].address_components.length
            const address = response.results[0].formatted_address;
            setGetPincodeToAdd(response.results[0].address_components[length-1].long_name)
            setGetAddressToAdd(response.results[0].formatted_address);
          },
          (error) => {
            console.error("error : ", error);
          }
        );
    }

    const dataToAddAddress = () => {
        setGetLatToAdd(userAddressLat)
        setGetLngToAdd(userAddressLng)
        navigate('/address/addaddress')
    }

    return(
        <div style={{position:'relative'}}>
            <div className="forSearchAddressLocation">
                <form autoComplete="off" >
                    <div className="backBtnAddressLocation">
                        <button onClick={(e) => {navigate('/address')}} >
                            <img src={require('../Assets/Images/Left 2.png')} />
                        </button>
                    </div>
                    <input ref={ref} onKeyDown={handleChange} className="pincodeAddressLocation" type="search" placeholder="Search by pincode" id="example-search-input" />
                </form>
            </div>

            <div className="iframe-location">
                {!showStaticAddressMap ?
                    <MapContainer setShowStaticAddressMap = {setShowStaticAddressMap} setUserAddressLng = {setUserAddressLng} setUserAddressLat = {setUserAddressLat} showStaticAddressMap={showStaticAddressMap} userAddressLat={userAddressLat} userAddressLng={userAddressLng} ></MapContainer>
                :
                    <MapContainer setShowStaticAddressMap = {setShowStaticAddressMap} setUserAddressLng = {setUserAddressLng} setUserAddressLat = {setUserAddressLat} showStaticAddressMap={showStaticAddressMap} userAddressLat={21.7679} userAddressLng={78.8718} ></MapContainer>
                }
            </div>

            {/* <div className="locateMeBtn">
                <button onClick={getGeocodingData}>
                    <img src={require('../Assets/Images/images.jpeg')} />
                    <p>locate me</p>
                </button>
            </div> */}

            <div className="searchedAddress">
                <div className="searchedAddressHead">
                    <img src={require('../Assets/Images/customer icons_location.svg').default} />
                    <h5 className="cart-locaion-text text-left" style={{fontWeight:'500'}}>Location</h5>
                </div>
                <hr style={{margin:'0 0 5px 0'}} />
                {!showStaticAddressMap ?
                    <>
                        <p>{getAddressToAdd && getAddressToAdd}</p>
                        {getAddressToAdd && getAddressToAdd != null ?
                            <button onClick={() =>{dataToAddAddress()}} className="btnSubmitAddress" >
                                Enter Complete Address
                            </button>
                        :
                            <button onClick={handleClick} className="btnSubmitAddress" >
                                Enter Location
                            </button>
                        }
                    </>
                :
                    <>
                        <p>Please enter location for delivery or pickup items. </p>
                        <button onClick={handleClick} className="btnSubmitAddress" >
                            Enter Location
                        </button>
                    </>
                }
            </div>
        </div>
    )
}

export default LocationAddress