import React from 'react';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import { MarkerF } from '@react-google-maps/api'

const MapContainer = ({ setUserAddressLat, setUserAddressLng, userAddressLat, userAddressLng, showStaticAddressMap, setShowStaticAddressMap }) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ',
  });
  const defaultCenter = {
    lat: userAddressLat, lng: userAddressLng
  }
  
  const marker = {
    location: {
      lat: userAddressLat,
      lng: userAddressLng,
    }
  }

  const onMapClick = (e) => {
    setShowStaticAddressMap(false)
    setUserAddressLat(e.latLng.lat());
    setUserAddressLng(e.latLng.lng());
  }

  const renderMap = () => (
    <GoogleMap mapContainerStyle={{ height: "75vh", width: "100%" }}
      zoom={!showStaticAddressMap ? 13 : 4}
      center={defaultCenter} 
      onClick={(e) => {onMapClick(e)}}
      >  
      {!showStaticAddressMap ? 
        <MarkerF position={marker.location} ></MarkerF> 
      : 
        null
      }
    </GoogleMap>
  )
  return isLoaded ? renderMap() : null;
}

export default MapContainer