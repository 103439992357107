import React, { useState, useEffect } from "react";
import "./Search.css";
import Helper from "../util/Helper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Footer from "../Footer/Footer";
import HistoryIcon from '@mui/icons-material/History';
import ClearIcon from '@mui/icons-material/Clear';

const Search = ({ setKeyName, setividforproductpage, shoprodctpage, lat, lng, setlat, setlng }) => {

        const navigate = useNavigate()
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [showKeyWords, setShowKeyWords] = useState([])
        const [showList, setShowList] = useState(false);
        const [list, setList] = useState([]);
        const [recentKeys, setRecentKeys] = useState([]);
        const [showRecent, setShowRecent] = useState(false);
        const [valueForSearch, setValueForSearch] = useState(null);
        const [clickSearch,setClickSearch]=useState(false);

        useEffect(() => {
                const disablePinchZoom = (e) => {
                        if (e.touches.length > 1) {
                                e.preventDefault()
                        }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                        document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        const globalSearch = () => {
                const getGlobalSearch = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_global_search_keywords/?key=recent&web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getGlobalSearch)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        setRecentKeys(Helper.encypt_decrypt(response.data.data).slice(0, 10))
                                } else if (response.data.code === 401) {
                                        navigate(`/`)
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const globalSearchApi = () => {
                const getGlobalSearch = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_global_search_keywords/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getGlobalSearch)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        setShowKeyWords(Helper.encypt_decrypt(response.data.data).slice(0, 10))
                                } else if (response.data.code === 401) {
                                        navigate(`/`)
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        useEffect(() => {
                if (cookies.skip != 0) {
                        globalSearch()
                        globalSearchApi()
                }
        }, [])

        const globalSearchApiPost = (value) => {
                const getBillListUrl = {
                        method: "POST",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/global_search/?name=${value}&web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                km: 15,
                                lat: lat != null ? lat : "28.6046254",
                                lng: lng != null ? lng : "77.3501306",
                        }
                };
                axios(getBillListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        setShowList(true)
                                        setList(Helper.encypt_decrypt(response.data.data))
                                } else if(response.data.code === 401) {
                                        navigate(`/`)
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                } else {
                                        setList([])
                                        setShowList(true)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const setKeyNameFunc = (merchantId, ivId, shopName, type) => {
                if(cookies.skip != 0) {
                        setKeyword(shopName, type)
                }
                if (type != 0) {
                        setividforproductpage(ivId)
                        shoprodctpage(true)
                        navigate('/shop/product')
                } else {
                        setKeyName(shopName)
                        routeShopFunc(merchantId)
                }
        }

        const setKeyword = (keyword, type) => {
                const request = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/add_popular_search_new/?search=${keyword}&gs_type=${type}&web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(request)
                    .then((response) => {
                        if (response.data.code === 200) {
                                console.log()
                        } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                    navigate('/')
                                    localStorage.setItem("isAuthenticated", false)
                                    localStorage.removeItem("isAuthenticated")
                            }, 1000)
                        }
                    })
                    .catch((error) => {
                            alert(error);
                    })
        }

        const routeShopFunc = (contactId) => {
                localStorage.setItem("contact_id", contactId);
        
                const getBillListUrl = {
                    method: "GET",
                    url: Helper.getWsUrl(cookies.skip) + `/items/get_selected_item_category_new/${contactId}/?web=1`,
                    headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getBillListUrl)
                    .then((response) => {
                        if (response.data.code === 200) {
                            if(Helper.encypt_decrypt(response.data.data).length < 4) {
                                navigate('/shop')
                            } else {
                                navigate('/shop/itemCategory')
                            }
                        } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                    navigate('/')
                                    localStorage.setItem("isAuthenticated", false)
                                    localStorage.removeItem("isAuthenticated")
                            }, 1000)
                        } else {
                            navigate('/shop')
                        }
                    })
                    .catch((error) => {
                            alert(error);
                    })
        }

        useEffect(() => {
                if (valueForSearch != null && valueForSearch != "") {
                        setShowRecent(true);
                        globalSearchApiPost(valueForSearch);
                } else {
                        setShowList(false)
                }
        }, [valueForSearch])

        return (
                <div className="margintop42 ">
                        <div className="row justify-content-between no-gutters pl-2 pr-2">
                                <div className="col-2 d-flex justify-content-start p-0 mr-2 " style={{ maxWidth: "48px" }}>
                                        <button className="borderleftarrow p-2 bg-transparent " onClick={() => { navigate(-1) }} ><img src={require('../Assets/Images/Left 2.png')} /></button>
                                </div>
                                <div className="col">
                                        <form onSubmit={(event) => {event.preventDefault();}} style={{display:"flex", position:"relative", alignItems:"center"}}>
                                                <input style={{ height: "48px", padding:"8px 35px 8px 8px" }} className="serchinp w-100" type="search" value={valueForSearch} placeholder="Search Shop/Product" onChange={(e) => { setValueForSearch(e.target.value); setClickSearch(true); }}  onClick={() => { setShowRecent(true); }} />
                                                <ClearIcon onClick={() => {setValueForSearch("")}} sx={{position:"absolute", right:"0", height:"48px", width:"30px", padding:"5px", display: valueForSearch != null && valueForSearch != "" ? "block" : "none"}} />
                                        </form>
                                </div>
                        </div>

                        {/* product search */}
                        <div className="heiii">
                                {cookies.skip != 0 || clickSearch ? 
                                        <div className="searchAllData">
                                                {!showRecent ?
                                                        <>
                                                                {cookies.skip != 0 && showKeyWords.length > 0 ? 
                                                                        <div className="keywordsCard">
                                                                                <div className="row  w-100 no-gutters ">
                                                                                        <p style={{ fontFamily: "SF Pro Text", fontWeight: "600", fontStyle: "semibold" }} className="popularsearch2  ">Popular Search</p>
                                                                                </div>
                                                                                <div className="row justify-content-start w-100 no-gutters pl-2 pr-2">
                                                                                        {showKeyWords && showKeyWords.map((value) => {
                                                                                                return (
                                                                                                        <div className="searchlinksbtn2">
                                                                                                                <HistoryIcon sx={{color: "#868d96", fontSize: "16px", margin:"0"}}/>
                                                                                                                <button onClick={() => { setValueForSearch(value.keyword);}} > {value.keyword}</button>
                                                                                                        </div>
                                                                                                )
                                                                                        })}
                                                                                </div>
                                                                        </div> 
                                                                : null }
                                                        </>
                                                :
                                                        <>
                                                                {!showList ? 
                                                                        <div className="recentSearchCard" style={{display: recentKeys.length > 0 ? "block" : "none"}}>
                                                                                {recentKeys && recentKeys.map((value) => {
                                                                                        return (
                                                                                                <div className="col d-flex justify-content-start align-items-center p-0" onClick={() => { setValueForSearch(value.keyword);}}>
                                                                                                        <HistoryIcon sx={{color: "#868d96", fontSize: "16px", marginRight:"6px"}}/>
                                                                                                        <p className="lastsearchedshop"> {value.keyword}</p>
                                                                                                </div>
                                                                                        )
                                                                                })}
                                                                        </div>
                                                                :
                                                                        <>
                                                                                {list && list.length > 0 ?
                                                                                        <>
                                                                                                {list && list.map((value) => {
                                                                                                        return (
                                                                                                                <div className="searchCard" onClick={() => {setKeyNameFunc(value.merchant_id, value.iv_id, value.name, value.type);}}>
                                                                                                                        <div className="cardImgLeft">
                                                                                                                                {value.type != 1 ?
                                                                                                                                        <img src={value.m_logo} onError={(e) => {
                                                                                                                                                if(e.target.onerror === null) {
                                                                                                                                                        (e.target.src = require("../Assets/Images/log.png"))
                                                                                                                                                }
                                                                                                                                        }} />
                                                                                                                                : 
                                                                                                                                        <img src={value.image_url != null ? value.image_url : value.image_url2 != null ? value.image_url2 : require("../Assets/Images/log.png")} onError={(e) => {
                                                                                                                                                if(e.target.onerror === null) {
                                                                                                                                                        (e.target.src = require("../Assets/Images/log.png"))
                                                                                                                                                }}} />
                                                                                                                                }
                                                                                                                        </div>
                                                                                                                        <div className="cardDetailRight">
                                                                                                                                {value.type != 1 ? 
                                                                                                                                        <p className="searchShopName">{value.shop_name}</p>
                                                                                                                                :
                                                                                                                                        <>
                                                                                                                                                <p className="itemShopName">{value.shop_name}</p>
                                                                                                                                                <p className="searchShopName">{value.name}</p>
                                                                                                                                        </>
                                                                                                                                }
                                                                                                                                {value.type != 1 ? <p className="searchShopMobile">{value.mobile_number}</p> : null }
                                                                                                                                <div style={{display:"flex"}}>
                                                                                                                                        <p className="searchShopAddress" style={{color: value.type != 1 ? "#7a7a7a" : "#fd7e14"}}>{value.type != 1 ? value.address : `₹ ${value.selling_price}`}</p>
                                                                                                                                        {value.type != 0 ? (value.mrp > value.selling_price) ? <del>₹ {value.mrp}</del> : null : null}
                                                                                                                                </div>
                                                                                                                                {value.type != 0 ? (value.mrp > value.selling_price) ? <small style={{color: "#008c53"}}>{(((value.mrp - value.selling_price)/value.mrp)*100).toFixed(2)}% off</small> : null : null}
                                                                                                                                <small style={{color: "#7a7a7a"}}>{value.type != 1 ? `${parseFloat(value.distance).toFixed(2)} kms away` : `${parseFloat(value.distance).toFixed(2)} kms shop away`}</small>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        )
                                                                                                })}
                                                                                        </>
                                                                                :
                                                                                        <p style={{color:"#c60618"}}>No Result Found!</p>                                                                        
                                                                                }
                                                                        </>
                                                                }
                                                        </>
                                                }
                                        </div> 
                                : null }
                        </div>
                        <Footer />
                </div>
        )
}
export default Search