import React, { useEffect, useState } from 'react'
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import Headerformobile2 from "../Header/Headerformobile2";
import Header from '../Header/Header';
import DoneIcon from '@mui/icons-material/Done';

const Cancelorderpage = ({ bookingId }) => {

        const navigate = useNavigate()
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [itemDetails, setItemDetails] = useState([]);
        const [itemArray, setItemArray] = useState([]);
        const [dateArray, setDateArray] = useState([]);
        const [itemStatus, setItemStatus] = useState(null);
        const [lazyLoading, setLazyLoading] = useState(true);
        const [messageCount, setMessageCount] = useState(0)

        const [getTrackingOrderDetail, setGetTrackingOrderDetail] = useState([])
        // const [orderTrackEl, setOrderTrackEl] = React.useState(null);
        // const [openOrderTrack, setOpenOrderTrack] = React.useState(false);
        // const [placementOrderTrack, setPlacementOrderTrack] = React.useState();

        // const handleClickOrderTrack = (newPlacement) => (event) => {
        //         setOrderTrackEl(event.currentTarget);
        //         setOpenOrderTrack((prev) => placementOrderTrack !== newPlacement || !prev);
        //         setPlacementOrderTrack(newPlacement);
        // };

        useEffect(() => {
                const disablePinchZoom = (e) => {
                  if (e.touches.length > 1) {
                    e.preventDefault()
                  }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                  document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        useEffect(() => {
                if(bookingId && bookingId .length > 0) {
                        billDetails();
                        getBookingChatNotification();
                        getOrderTrackingDetailFunc()
                } else {
                        navigate('/bookingstatus')
                }
        }, [bookingId])

        const billDetails = () => {
                const getBillListUrl = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/booking/get_Customer_booking_details/${bookingId}?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getBillListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        setItemDetails(Helper.encypt_decrypt(response.data.data))
                                        setItemStatus(Helper.encypt_decrypt(response.data.data)[0].online_status)
                                        setLazyLoading(false)
                                } else if (response.data.code === 401) {
                                  removeCookie("logged_in");
                                  setTimeout(() => {
                                    navigate("/");
                                    localStorage.setItem("isAuthenticated", false);
                                    localStorage.removeItem("isAuthenticated");
                                  }, 1000);
                                } else if(response.data.code === 204){
                                        setLazyLoading(false)
                                }
                        })
                        .catch((error) => {
                                console.log(error);
                        })
        }

        useEffect(() => {
                if (itemDetails && itemDetails.length > 0) {
                        {itemDetails && itemDetails.map((value) => {
                                setItemArray((preState) => [...preState, value.Items])
                                setDateArray(value.booking_date)
                        })}
                }
        }, [itemDetails])

        const changeStatus = () => {
                const patchItem = {
                        method: "PATCH",
                        url: Helper.getWsUrl(cookies.skip) + `/booking/bookingStatusChange/${bookingId}?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                status: 3,
                                amount:0,
                        }
                };
                axios(patchItem)
                        .then((response) => {        
                                if (response.data.code === 201) {
                                       navigate('/bookingstatus')           
                                } else if(response.data.code === 401) {
                                  removeCookie("logged_in");
                                  setTimeout(() => {
                                    navigate("/");
                                    localStorage.setItem("isAuthenticated", false);
                                    localStorage.removeItem("isAuthenticated");
                                  }, 1000);
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const getBookingChatNotification = () => {
                const patchItem = {
                    method: "GET",
                    url: Helper.getWsUrl(cookies.skip) + `/booking/get_booking_chat_count_new/${bookingId}/3/?web=1`,
                    headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(patchItem)
                        .then((response) => {        
                                if (response.data.code === 200) {
                                        setMessageCount(Helper.encypt_decrypt(response.data.data).msg_count)
                                } else if(response.data.code === 401) {
                                removeCookie("logged_in");
                                setTimeout(() => {
                                navigate("/");
                                localStorage.setItem("isAuthenticated", false);
                                localStorage.removeItem("isAuthenticated");
                                }, 1000);
                                } else {
                                        setMessageCount(true)
                                }
                        })
                        .catch((error) => {
                                console.log(error);
                        })
        }

        const getOrderTrackingDetailFunc = () => {
                const patchItem = {
                    method: "GET",
                    url: Helper.getWsUrl(cookies.skip) + `/booking/track_order/${bookingId}/?web=1`,
                    headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(patchItem)
                        .then((response) => {        
                                if (response.data.code === 200) {
                                        console.log("detail track oreder : ",Helper.encypt_decrypt(response.data.data))
                                        setGetTrackingOrderDetail(Helper.encypt_decrypt(response.data.data))
                                } else if(response.data.code === 401) {
                                        removeCookie("logged_in");
                                        setTimeout(() => {
                                                navigate("/");
                                                localStorage.setItem("isAuthenticated", false);
                                                localStorage.removeItem("isAuthenticated");
                                        }, 1000);
                                } 
                        })
                        .catch((error) => {
                                console.log(error);
                        })
        }
        console.log("item list ", itemDetails)        

        return (
                <>
                        <Headerformobile2 bookingId = {bookingId} pagename="Booking Status" navigate={navigate} />  
                        <Header /> 
                        <div className='bookingStatusCancelOrder'>
                                <div className='progressContainer' style={{display: getTrackingOrderDetail && getTrackingOrderDetail.length > 0 ? "flex" : "none"}} >
                                        {getTrackingOrderDetail && getTrackingOrderDetail.length === 1 ?
                                                <>
                                                        {getTrackingOrderDetail.find((el) => el.status === 0) ? 
                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                        <div className='progress'></div>
                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                        </div>
                                                                        <div className='firstpointText stageText'>
                                                                                <p>Ordered</p>
                                                                                {/* <small>{getTrackingOrderDetail && getTrackingOrderDetail[0].process_text}</small> */}
                                                                        </div>
                                                                        <div className='secondPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                        <div className='secondpointText stageText'>
                                                                                <p>Confirmed</p>
                                                                        </div>
                                                                        <div className='thirdPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                        <div className='thirdpointText stageText'>
                                                                                <p>Packed</p>
                                                                        </div>
                                                                        <div className='forthPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                        <div className='forthpointText stageText'>
                                                                                <p>Delivered</p>
                                                                        </div>
                                                                </div>
                                                        :
                                                                <div className='progressPath' style={{height:"80px"}}>
                                                                        <div className='progress activeProgress2'></div>
                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                        </div>
                                                                        <div className='firstpointText stageText'>
                                                                                <p>Ordered</p>
                                                                        </div>
                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                        </div>
                                                                        <div className='forthpointText stageText'>
                                                                                <p>Canceled</p>
                                                                        </div>
                                                                </div>
                                                        }
                                                </>
                                        :
                                                <>
                                                        {getTrackingOrderDetail && getTrackingOrderDetail.length === 2 ? 
                                                                <>
                                                                        {getTrackingOrderDetail.find((el) => el.status === 1) ? 
                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                        <div className='progress activeProgress'></div>
                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                        </div>
                                                                                        <div className='firstpointText stageText'>
                                                                                                <p>Ordered</p>
                                                                                        </div>
                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                        </div>
                                                                                        <div className='secondpointText stageText'>
                                                                                                <p>Confirmed</p>
                                                                                        </div>
                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                        <div className='thirdpointText stageText'>
                                                                                                <p>Packed</p>
                                                                                        </div>
                                                                                        <div className='forthPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                        <div className='forthpointText stageText'>
                                                                                                <p>Delivered</p>
                                                                                        </div>
                                                                                </div>
                                                                        :
                                                                                <div className='progressPath' style={{height:"80px"}}>
                                                                                        <div className='progress activeProgress2'></div>
                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                        </div>
                                                                                        <div className='firstpointText stageText'>
                                                                                                <p>Ordered</p>
                                                                                        </div>
                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                        </div>
                                                                                        <div className='forthpointText stageText'>
                                                                                                <p>Canceled</p>
                                                                                        </div>
                                                                                </div>
                                                                        }
                                                                </>
                                                        :
                                                                <>
                                                                        {getTrackingOrderDetail && getTrackingOrderDetail.length === 3 ?
                                                                                <>
                                                                                        {getTrackingOrderDetail.find((el) => el.status === 2) ? 
                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                        <div className='progress activeProgress1'></div>
                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                        </div>
                                                                                                        <div className='firstpointText stageText'>
                                                                                                                <p>Ordered</p>
                                                                                                        </div>
                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                        </div>
                                                                                                        <div className='secondpointText stageText'>
                                                                                                                <p>Confirmed</p>
                                                                                                        </div>
                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                        </div>
                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                <p>Packed</p>
                                                                                                        </div>
                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                                        <div className='forthpointText stageText'>
                                                                                                                <p>Delivered</p>
                                                                                                        </div>
                                                                                                </div>
                                                                                        :
                                                                                                <>
                                                                                                        {getTrackingOrderDetail.find((el) => el.status === 3) ? 
                                                                                                                <div className='progressPath' style={{height:"160px"}}>
                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                <p>Ordered</p>
                                                                                                                        </div>
                                                                                                                        <div className='middlePoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='middlepointText stageText'>
                                                                                                                                <p>Confirmed</p>
                                                                                                                        </div>
                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                <p>Canceled</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        :
                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                        <div className='progress activeProgress'></div>
                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                <p>Ordered</p>
                                                                                                                        </div>
                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                <p>Confirmed</p>
                                                                                                                        </div>
                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                <p>Packed</p>
                                                                                                                        </div>
                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                <p>Delivered</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        }
                                                                                                </>
                                                                                        } 
                                                                                </>
                                                                        :
                                                                                <>
                                                                                        {getTrackingOrderDetail && getTrackingOrderDetail.length === 4 ?
                                                                                                <>
                                                                                                        {getTrackingOrderDetail.find((el) => el.status === 4) ? 
                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                <p>Ordered</p>
                                                                                                                        </div>
                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                <p>Confirmed</p>
                                                                                                                        </div>
                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                <p>Packed</p>
                                                                                                                        </div>
                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                <p>Delivered</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        :
                                                                                                                <>
                                                                                                                        {getTrackingOrderDetail.find((el) => el.status === 3) ? 
                                                                                                                                <>
                                                                                                                                        {getTrackingOrderDetail.find((el) => el.status === 2) ? 
                                                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                                                <p>Ordered</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                                                <p>Confirmed</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                                                <p>Packed</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                                                <p>Canceled</p>
                                                                                                                                                        </div>
                                                                                                                                                </div>
                                                                                                                                        :
                                                                                                                                                <div className='progressPath' style={{height:"160px"}}>
                                                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                                                <p>Ordered</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='middlePoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='middlepointText stageText'>
                                                                                                                                                                <p>Confirmed</p>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                                        </div>
                                                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                                                <p>Canceled</p>
                                                                                                                                                        </div>
                                                                                                                                                </div>
                                                                                                                                        }
                                                                                                                                </>
                                                                                                                        :
                                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                                        <div className='progress activeProgress1'></div>
                                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                        </div>
                                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                                <p>Ordered</p>
                                                                                                                                        </div>
                                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                        </div>
                                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                                <p>Confirmed</p>
                                                                                                                                        </div>
                                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                                        </div>
                                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                                <p>Packed</p>
                                                                                                                                        </div>
                                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "rgb(204, 202, 202)"}}></div>
                                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                                <p>Delivered</p>
                                                                                                                                        </div>
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                </>
                                                                                                        }
                                                                                                </>
                                                                                        :
                                                                                                <>
                                                                                                        {getTrackingOrderDetail.find((el) => el.status === 4) ? 
                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                <p>Ordered</p>
                                                                                                                        </div>
                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                <p>Confirmed</p>
                                                                                                                        </div>
                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                <p>Packed</p>
                                                                                                                        </div>
                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                <p>Delivered</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        :
                                                                                                                <div className='progressPath' style={{height:"270px"}}>
                                                                                                                        <div className='progress activeProgress2'></div>
                                                                                                                        <div className='firstPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='firstpointText stageText'>
                                                                                                                                <p>Ordered</p>
                                                                                                                        </div>
                                                                                                                        {/* <small className='orderDataMsg'>{getTrackingOrderDetail && getTrackingOrderDetail[0].process_text}</small>
                                                                                                                        <small className='orderDataTime'>{getTrackingOrderDetail && getTrackingOrderDetail[0].add_date_time}</small> */}
                                                                                                                        <div className='secondPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='secondpointText stageText'>
                                                                                                                                <p>Confirmed</p>
                                                                                                                        </div>
                                                                                                                        <div className='thirdPoint stage' style={{backgroundColor: "#ee7a37"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='thirdpointText stageText'>
                                                                                                                                <p>Packed</p>
                                                                                                                        </div>
                                                                                                                        <div className='forthPoint stage' style={{backgroundColor: "#dc463d"}}>
                                                                                                                                <DoneIcon sx={{color:"#fff", padding:"3px"}}></DoneIcon>
                                                                                                                        </div>
                                                                                                                        <div className='forthpointText stageText'>
                                                                                                                                <p>Canceled</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        }
                                                                                                </>
                                                                                        }
                                                                                </>
                                                                        }   
                                                                </>
                                                        }    
                                                </>
                                        }
                                </div>
                                
                                <div className='bookingStatusCancelOrderCardContainer'>
                                        <div className='path_direction pt-5' style={{ paddingLeft: "2%", alignItems: "center", paddingTop: "20px" }} id="idforbreadcrumbincancelorder">
                                                <p >
                                                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                                                        &gt;
                                                        <Link to="/profile" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Booking Status</Link>
                                                </p>
                                        </div>

                                        {/* main cart  */}
                                        {lazyLoading ? 
                                                <Skeleton height={"190px"} width={"100%"} count={6} ></Skeleton> 
                                        :
                                                <>
                                                        {itemArray[0] && itemArray[0].map((value) => {
                                                                return (
                                                                        <div className='row p-2 borderforproductofcancelorder ml-1 mr-1 shadow mt-3' id="product1">
                                                                                <div className='col-12 rightpaddingremoval p-0'>
                                                                                        <div className="media">
                                                                                                <img className="mr-lg-3 mr-2 productphotosize rounded" src={value.featured_img_url != null ? value.featured_img_url : require('../Assets/Images/log.png')} alt="modal image" style={{ position: "relative", objectFit: "contain" }}
                                                                                                        onError={(e) => {
                                                                                                                if(e.target.onerror === null){
                                                                                                                        (e.target.src = require('../Assets/Images/log.png'))
                                                                                                                }}
                                                                                                        } />
                                                                                                <div className="media-body">
                                                                                                        <div className='container  '>
                                                                                                                <div className='row'>
                                                                                                                        <div className='col-11  p-0'>
                                                                                                                                <h1 className="productname2 text-left font-weight-bold text-capitalize">{value.item_name}</h1>
                                                                                                                        </div>
                                                                                                                        <div className='col-11  p-0 mt-lg-3 mt-2'>
                                                                                                                                <div className='row justify-content-between'>
                                                                                                                                        <div className='col-4 '>
                                                                                                                                                <h1 className="productname21  text-left">{value.min_qty} {value.miu_name}</h1>
                                                                                                                                        </div>
                                                                                                                                        <div className='col-4 p-0'>
                                                                                                                                                <h1 className="productname22a  text-right">Qty:{value.quantity}</h1>
                                                                                                                                        </div>
                                                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-11  p-0 mt-lg-3 mt-2'>
                                                                                                                                <div className='row justify-content-between'>
                                                                                                                                        <div className='col-8 pr-0'>
                                                                                                                                                <h1 className="productname22b m-0 text-left">{dateArray}</h1>
                                                                                                                                        </div>
                                                                                                                                        <div className='col-4 p-0'>
                                                                                                                                                <h1 className="productname22c m-0 text-right font-weight-bold">₹{value.final_amount}</h1>
                                                                                                                                        </div>
                                                                                                                                </div>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                )
                                                        })}
                                                </>
                                        }

                                        <div className='cancelMobileBtn'>
                                                {itemStatus && itemStatus === 3 || itemStatus && itemStatus === 4 ? 
                                                        null
                                                        :
                                                        <>
                                                                <button data-toggle="modal" data-target="#cancelOrderMobileModal">Cancel Order</button>
                                                                <div>
                                                                        <img onClick={() => navigate("/chatting")} src={require("../Assets/Images/Customer-Chat-Icon.png")} />
                                                                        <p style={{display: messageCount && messageCount > 0 ? "block" : "none" }}>{messageCount && messageCount}</p>
                                                                </div>
                                                        </>
                                                }

                                        </div>
                                </div>

                                <div className='row d-lg-block d-none'>
                                        <div className="col-12 mt-5">
                                                {itemStatus && itemStatus === 3 || itemStatus && itemStatus === 4 ? 
                                                        null 
                                                : 
                                                        <button type="button" className="cancelOrderBtn text-white" data-toggle="modal" data-target="#cancelOrderMobileModal">Cancel Order</button>
                                                }
                                        </div>
                                </div>

                                <div class="modal fade" id="cancelOrderMobileModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                                                <div class="modal-content">
                                                        <div class="modal-body">
                                                                Are you sure, you want to cancel order?
                                                        </div>
                                                        <div class="modal-footer">
                                                                <button type="button" data-dismiss="modal" style={{border:'none', background:'none', fontSize:'16px', fontWeight:'500', color:'#4e4e4e'}} >No</button>
                                                                <button onClick={() => { changeStatus() }} type="button" data-dismiss="modal" style={{border:'none', background:'none', fontSize:'16px', fontWeight:'500', color:'#EF7B37'}} >Yes</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}

export default Cancelorderpage;