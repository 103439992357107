import React from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import { useCookies } from "react-cookie";

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();

// watchPosition
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

const Mobileheader = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const navigate = useNavigate()

  return (
    <>

      <div className="navbarmobile">
        <div className="container-fluid heightforheader" style={{ backgroundColor: "white", zIndex: "1", position: "fixed", top:"0", height: "97px" }}>
          <div className="row  firsthw">
            <div className="col-10 d-flex flex-column  ">
              <Link to="/location">
                <div className="position-relative">
                  <span className="locationmobile text-left position-absolute"> Location</span>
                  <img src={require('../Assets/Images/customer icdsdons_location (1).png')} className="locationimage" />
                </div>
              </Link>

              <span className="text-left sanavbarmobileheader position-absolute w-100 text-truncate">{localStorage.getItem("Address")}</span>
            </div>

            <div className="col-2 position-relative">
              {cookies.skip != 0 ?
                <Link to="/profile">
                  <img src={require('../Assets/Images/sdasdasrqa.png')} className="icon2ofnavbarmobile"></img>
                </Link>
                :
                <div onClick={()=>{navigate("/")}} >
                  <img src={require('../Assets/Images/sdasdasrqa.png')} className="icon2ofnavbarmobile"></img>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Mobileheader;