import React, { useEffect, useState } from "react";
import Udhaar from "./Udhaar";
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Headerformobile2 from "../Header/Headerformobile2";
import 'react-loading-skeleton/dist/skeleton.css'
import Header from "../Header/Header";

const Mainudhaarfile = ({setUdhaarBillId}) => {

        const navigate = useNavigate()
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [udhaarshopdetail, showUdhaarShopDetail] = useState(false);
        const [udhaarPaiddata, setUdhaarPaidData] = useState([]);
        const [udhaarOverDuedata, setUdhaarOverDueData] = useState([]);
        const [totalPaid, setTotalPaid] = useState(null);
        const [totalCredit, setTotalCredit] = useState(null);
        const [udhaarDueData, setUdhaarDueData] = useState([]);
        const [totalDueUdhaar, setTotalDueUdhaar] = useState([]);
        const [overdue_length, setoverdue_length] = useState(null)
        const [overpaid_length, setoverpaid_length] = useState(null)
        const [duer_length, setdue_length] = useState(null)
        const [lazyLoading, setLazyLoading] = useState(true);
        const [apiKey, setApiKey] = useState("over_due");
        const [apiBtn, setApiBtn] = useState("over_due");
        const [emptyState,setEmptyState]=useState(false);
        const [pageNo, setPageNo] = useState(1)
        const [getIsTrusted, setGetIsTrusted] = useState(false)
        const [scrollUdhaarList,setScrollUdhaarList]=useState([]);

        useEffect(() => {
                window.addEventListener('scroll', (event) => {
                    setGetIsTrusted(event.isTrusted)
                })
        }, [])
        
        useEffect(() => {
                if (getIsTrusted) {
                        setPageNo(pageNo + 1)
                        UdhaarDuelistApi()            
                }
        }, [getIsTrusted])

        const UdhaarDuelistApi = () => {
                const getBillListUrl = {
                        method: "POST",                                                                                                              
                        url: Helper.getWsUrl(cookies.skip) + `${apiKey == null ? `/vouchers/customer_shop_credit_list/?page=${pageNo}&no_of_record=10&web=1` : `/vouchers/customer_shop_credit_list/?key=${apiKey}&page=${pageNo}&no_of_record=10&web=1`}`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getBillListUrl)
                        .then((response) => {         
                                if (response.data.code === 200) {
                                        setScrollUdhaarList(Helper.encypt_decrypt(response.data.data).credit_list)
                                        setdue_length(Helper.encypt_decrypt(response.data.data).credit_list.length)
                                        console.log("udharr detail : ",Helper.encypt_decrypt(response.data.data))
                                        setGetIsTrusted(false)
                                        // if (apiKey === "paid") {
                                        //         setTotalDueUdhaar(Helper.encypt_decrypt(response.data.data).total_paid)
                                        // } else {
                                                setTotalDueUdhaar(Helper.encypt_decrypt(response.data.data).total_credit)
                                        // }
                                        setApiBtn(Helper.encypt_decrypt(response.data.data).credit_list[0].type)
                                        setLazyLoading(false)      
                                } else if (response.data.code === 204) {
                                        setTotalDueUdhaar(0)
                                        setdue_length(0)
                                        setLazyLoading(false)
                                } else if (response.data.code === 401) {
                                        navigate(`/`)
                                        removeCookie("logged_in")
                                        removeCookie("token")                                    
                                }                              
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }  

        useEffect(() => {              
                UdhaarDuelistApi()
        }, [apiKey])

        return (
                <>
                        <Headerformobile2 pagename="Udhaar" navigate={navigate} />
                        <Header />
                        {/* {showUdhaarDetail ? 
                                <UdhaarBillDetail setBillId={setBillId} billId={billId} /> 
                        :
                                <> */}
                                        {/* {udhaarshopdetail ?
                                                <Udharshop setScrollUdhaarList={setScrollUdhaarList} setShowUdhaarDetail={setShowUdhaarDetail} billId={billId} setBillId={setBillId} setUdhaarBillId={setUdhaarBillId} udhaarBillId={udhaarBillId} />
                                        : */}
                                                <Udhaar scrollUdhaarList={scrollUdhaarList} pageNo={pageNo} setPageNo={setPageNo} getIsTrusted={getIsTrusted} setGetIsTrusted={setGetIsTrusted}
                                                        setEmptyState={setEmptyState} emptyState={emptyState} setApiBtn={setApiBtn} apiBtn={apiBtn} setApiKey={setApiKey} apiKey={apiKey} 
                                                        lazyLoading={lazyLoading} setLazyLoading={setLazyLoading} udhaarDueData={udhaarDueData} setTotalDueUdhaar={setTotalDueUdhaar} 
                                                        totalDueUdhaar={totalDueUdhaar} setUdhaarDueData={setUdhaarDueData} udhaarPaiddata={udhaarPaiddata} totalPaid={totalPaid} 
                                                        setUdhaarPaidData={setUdhaarPaidData} setUdhaarBillId={setUdhaarBillId} totalCredit={totalCredit} 
                                                        setTotalCredit={setTotalCredit} udhaarshopdetail={udhaarshopdetail} showUdhaarShopDetail={showUdhaarShopDetail} setTotalPaid={setTotalPaid} 
                                                        udhaarOverDuedata={udhaarOverDuedata} setUdhaarOverDueData={setUdhaarOverDueData} overdue_length={overdue_length} 
                                                        setoverdue_length={setoverdue_length} overpaid_length={overpaid_length} setoverpaid_length={setoverpaid_length} setdue_length={setdue_length} 
                                                        duer_length={duer_length}>
                                                </Udhaar>
                                        {/* } */}
                                {/* </>
                        } */}
                </>
        )
}
export default Mainudhaarfile;