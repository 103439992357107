import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import "./Newcss.css"
import { Link, useNavigate } from 'react-router-dom';
import Headerformobile2 from '../Header/Headerformobile2';
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from '../Header/Header';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Productpage({ ividforproductpage, logo_shop, keyName,kmData,setKmData }) {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    
    const [counter, setCounter] = useState(1)
    const [counter23, setCounter23] = useState(null);
    const [shocountericndcn, setshocountericndcn] = useState(true);
    const [productdetails23, setproductdetails23] = useState([])
    const [itemid, setitemid] = useState('')
    const [ivid, setivid] = useState('')
    const [lazyLoading, setLazyLoading] = useState(true);
    const [bbIdItem, setBbIdItem] = useState(null);
    const [minHeight, setMinHeight] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openBooking, setOpenBooking] = React.useState(false);

    const handleClickOpenBooking = () => {
        setOpenBooking(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenBooking(false);
    };

    useEffect(() => {
        if(ividforproductpage && ividforproductpage != null) {
            itemDetailFunc()
        } else {
            navigate('/shop')
        }
    }, [ividforproductpage])

    useEffect(() => {
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
            document.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    const itemDetailFunc = () => {
        const productpageapidata = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/contact/get_particular_cataloge_item_details/${ividforproductpage}/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(productpageapidata)
            .then((response) => {
                setCounter23(Helper.encypt_decrypt(response.data.data).itemDetails[0].quantity)
                setitemid(Helper.encypt_decrypt(response.data.data).itemDetails[0].item_id)
                setivid(Helper.encypt_decrypt(response.data.data).itemDetails[0].iv_id)
                setproductdetails23(Helper.encypt_decrypt(response.data.data).itemDetails[0])
                setBbIdItem(Helper.encypt_decrypt(response.data.data).itemDetails[0].bb_id)
                setLazyLoading(false)
                window.location.replace()
                if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (counter23 && counter23 > 0) {
            setshocountericndcn(false)
        } else {
            setshocountericndcn(true)
        }
    }, [counter23])

    function addfavshop3(item, iv, shop) {
        const fav123 = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `/contact/add_favorite_shop/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                item_id: item,
                iv_id: iv,
                shop_id: shop,
                code: 0
            }
        };
        axios(fav123)
            .then((response) => {
                if (response.data.code === 201) {
                    itemDetailFunc()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function removefavshop2(item1, iv1, shop1) {
        const requestOptions528 = {
            method: "DELETE",
            url: Helper.getWsUrl(cookies.skip) + '/contact/remove_favorite_shop/?web=1',
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                item_id: item1,
                iv_id: iv1,
                shop_id: shop1,
                code: 0
            }
        };
        axios(requestOptions528)
            .then((response) => {
                if (response.data.code === 202) {
                    itemDetailFunc()
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function addtocartapi(itemId, ivid) {
        const addtocartapiconst = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `/booking/customer_add_booking_bag/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                contact_id: localStorage.getItem("contact_id"),
                iv_id: ivid,
                quantity: 1.0,
            }
        }
        axios(addtocartapiconst)
            .then((response) => {
                if (response.data.code === 201) {
                    setBbIdItem(Helper.encypt_decrypt(response.data.data).bb_id)
                    setshocountericndcn(false);
                    itemDetailFunc()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    function changeStatusOfItems(bbId) {
        const addtocartapiconst = {
            method: "PATCH",
            url: Helper.getWsUrl(cookies.skip) + `/booking/quantity_status_change/${bbId}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                quantity: counter23
            }
        }
        axios(addtocartapiconst)
            .then((response) => {
                if (response.data.code === 200) {
                    // console.log("status ", Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function deletetocartapi(bbId) {
        const dlttocartapiconst = {
            method: "DELETE",
            url: Helper.getWsUrl(cookies.skip) + `/booking/delete_bag_list_items/${bbId}?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        }
        axios(dlttocartapiconst)
            .then((response) => {
                if (response.data.code === 202) {
                    setshocountericndcn(true)
                    itemDetailFunc()
                } else if(response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (window.innerHeight > 700) {
                 setMinHeight(true)}
    }, [])

    return (
        <>
            <Dialog PaperProps={{
                    style: {
                        position: 'absolute',
                        bottom: '0',
                        color: 'black',
                        width: '100%',
                        height: `${minHeight ? "30%" : "41%"}`,
                        maxWidth: '100%',
                        padding: "3%"
                    },
                }} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <Toolbar>
                    <div className='mainLoginBox'>
                        <div className='LoginBoxTitleWrapper'>
                            <p className='LoginBoxTitle' >Login</p>
                        </div>
                        <div className='LoginBoxParaWrapper'>
                            <p className='loginBoxPara'>To add items to your favourites you need to login.</p>
                        </div>
                        <div className='loginBoxButtonWrapper'>
                            <button className='loginBoxButton' onClick={() => { navigate("/") }}>
                                Continue to login
                            </button>
                        </div>
                    </div>
                </Toolbar>
            </Dialog>

            <Dialog PaperProps={{
                    style: {
                        position: 'absolute',
                        bottom: '0',
                        color: 'black',
                        width: '100%',
                        height: `${minHeight ? "30%" : "41%"}`,
                        maxWidth: '100%',
                        padding: "3%"
                    },
                }} open={openBooking} onClose={handleClose} TransitionComponent={Transition}>
                <Toolbar>
                    <div className='mainLoginBox'>
                        <div className='LoginBoxTitleWrapper'>
                            <p className='LoginBoxTitle' >Login</p>
                        </div>
                        <div className='LoginBoxParaWrapper'>
                            <p className='loginBoxPara'>To book items you need to login.</p>
                        </div>
                        <div className='loginBoxButtonWrapper'>
                            <button className='loginBoxButton' onClick={() => { navigate("/") }}>
                                Continue to login
                            </button>
                        </div>
                    </div>
                </Toolbar>
            </Dialog>

            <div class="modal fade" id="AddToBag" tabindex="-1" aria-labelledby="AddToBagLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered spcl-modal">
                    <div class="modal-content">
                        <div class="modal-body">
                            <p style={{ fontSize: "20px" }} >Log in or sign to add items in your bag!</p>
                            <button data-bs-dismiss="modal" onClick={() => { navigate("/") }} style={{ border: "none", padding: "10px 90px", backgroundColor: "#EE7A37", color: "white", fontWeight: "900" }}  >Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="Fav" tabindex="-1" aria-labelledby="FavLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered spcl-modal">
                    <div class="modal-content">
                        <div class="modal-body">
                            <p style={{ fontSize: "20px" }} >Log in or sign to add items to your favourites!</p>
                            <button data-bs-dismiss="modal" onClick={() => { navigate("/") }} style={{ border: "none", padding: "10px 90px", backgroundColor: "#EE7A37", color: "white", fontWeight: "900" }}  >Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <Headerformobile2 pagename="Product" navigate={navigate} />  
            <Header />
            <div className='container containerforebview '>
                {lazyLoading ?
                    <Skeleton height={"125px"} style={{ marginRight: "0px" }} width={"100%"} count={10} ></Skeleton>
                :
                    <>
                        <div className='row  mt-5 mb-3'>
                            <div className="col ">
                                <div className='path_direction dashbbdbooking  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                                    <p>
                                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                                        &gt;
                                        <Link to="#" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }} >Product</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-1 '>
                                <div className='row mt-3'>
                                    <div className='col-12  marginnw mt-3 mb-4'>
                                        <button className=' bg-transparent border-0 hovbtn ' onClick={() => { document.getElementById("theImage").src = productdetails23.image_1_url }}>
                                            <img src={productdetails23.image_1_url} className='hw50' onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))}></img>
                                        </button>
                                    </div>
                                    <div className='col-12  marginnw mt-3 mb-4'>
                                        <button className=' bg-transparent border-0 hovbtn' onClick={() => { document.getElementById("theImage").src = productdetails23.image_2_url }}>
                                            <img src={productdetails23.image_2_url} className='hw50' onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))}></img>
                                        </button>
                                    </div>
                                    <div className='col-12 marginnw mt-3 mb-4'>
                                        <button className=' bg-transparent border-0 hovbtn' onClick={() => { document.getElementById("theImage").src = "productdetails23.image_3_url" }}>
                                            <img src={productdetails23.image_3_url} className='hw50' onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))}></img>
                                        </button>
                                    </div>
                                    <div className='col-12 marginnw mt-3 mb-4'>
                                        <button className=' bg-transparent border-0 hovbtn' onClick={() => { document.getElementById("theImage").src = "productdetails23.image_4_url" }}>
                                            <img src={productdetails23.image_4_url} className='hw50' onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))}></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-8 mt-md-2 mt-lg-0'>
                                <div className='row justify-content-between'>
                                    <div className='col-3 ml-3 mt-4 marginallsideofimage'>
                                        <img src={productdetails23.featured_img_url} className='hwimage' id="theImage"
                                            onError={(e) =>
                                                (e.target.onerror = null)(
                                                    (e.target.src = require('../Assets/Images/log.png'))
                                                )} ></img>
                                    </div>
                                    <div className='col-5 '>
                                        <div className="row">
                                            <div className='col-12'>
                                                <h1 className='productnamefs text-left'>{productdetails23.itemName}</h1>
                                            </div>
                                            <div className='col-12 text-left mt-3' style={{ whiteSpace: "nowrap" }}>
                                                {(productdetails23.discount > 0) ?
                                                    <>
                                                        <span className=' fs261 ml-0 '>₹ {productdetails23.mrp}<span className='fsdisc1'><del>₹{productdetails23.rate}</del></span></span>
                                                        {(productdetails23.mrp == productdetails23.rate) ? 
                                                            null
                                                        : 
                                                            <span className=' fs271 ml-lg-5 pl-0'>{parseFloat(productdetails23.discount).toFixed(2)}%off</span>
                                                        }
                                                    </>
                                                :
                                                    <span className=' fs261 ml-0 '>₹ {productdetails23.mrp}</span>
                                                }
                                            </div>
                                            <div className='col-12'>
                                                <h1 className='text-left fsrest1'>Units: {productdetails23.min_qty === 0 ? '1' : productdetails23.min_qty} {productdetails23.miu_name!=null && productdetails23.miu_name!="" ? `(${productdetails23.miu_name})` : null} </h1>
                                            </div>
                                            {/* <div className='col-12'>
                                                <h1 className='text-left fsrest m-1'>Item Details </h1>
                                            </div> */}
                                            <div className='col-12'>
                                                {(productdetails23.size != null && productdetails23.size != "") ? 
                                                    <h1 className='text-left fsrest m-1'>Size: {productdetails23.size} </h1>
                                                : 
                                                    null
                                                }
                                            </div>
                                            <div className='col-12'>
                                                {(productdetails23.color != null && productdetails23.color != "") ? 
                                                    <h1 className='text-left fsrest m-1'>Color: {productdetails23.color} </h1>
                                                : 
                                                    null
                                                }
                                            </div>
                                            <div className='col-12'>
                                                {(productdetails23.model != null && productdetails23.model != "") ? 
                                                    <h1 className='text-left fsrest m-1'>Model: {productdetails23.model} </h1>
                                                : 
                                                    null
                                                }
                                            </div>
                                            {/* <div className='col-12'>
                                                {(productdetails23.ref == null) ? 
                                                    <h1 className='text-left fsrest m-1'>Reference:N/A </h1> 
                                                : 
                                                    <h1 className='text-left fsrest m-1'>Reference:{productdetails23.ref} </h1>
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3  p-0'>
                                <div className="heart ">
                                    <div className='imagdivwhiteorange position-relative'>
                                        {(productdetails23.is_favorite == 0) ?
                                            <img src={require('../Assets/Images/Property 1=inactive.jpg')} className='' id="img12" style={{ position: "absolute", top: "10px", right: "10px" }} onClick={() => { addfavshop3(productdetails23.item_id, productdetails23.iv_id, productdetails23.merchant_id) }}></img>
                                        :
                                            <img src={require('../Assets/Images/Property 1=Default.jpg')} style={{ position: "absolute", top: "10px", right: "10px" }} onClick={() => { removefavshop2(productdetails23.item_id, productdetails23.iv_id, productdetails23.merchant_id) }} ></img>
                                        }
                                    </div>
                                </div>
                                <div className="heart position-absolute" style={{ bottom: "36px" }}>
                                    {shocountericndcn ?
                                        <>
                                            {cookies.skip != 0 ? <> <button className='hwbtn1 position-relative ' onClick={() => { addtocartapi(itemid, ivid) }}>Add to Bag</button></> : <button className='hwbtn1 position-relative ' onClick={handleClickOpenBooking}>Add to Bag</button>}
                                        </>
                                    :
                                        <div className="inputboxdiv border" style={{ height: "40px" }}>
                                            {cookies.skip != 0 ? 
                                                <> 
                                                    <button className="incbutton" onClick={() => {
                                                        if (counter23 && counter23 > 1) {
                                                            changeStatusOfItems(bbIdItem);
                                                        } else {
                                                            deletetocartapi(bbIdItem)
                                                        }
                                                        setCounter23(counter23 - 1)
                                                    }}>
                                                        -
                                                    </button>
                                                    <p style={{ display: "inline" }} className="border border-top-0 border-bottom-0 indeccounter234">{counter23 && counter23}</p>
                                                    <button className="incbutton" onClick={() => { changeStatusOfItems(bbIdItem); setCounter23(counter23 + 1) }}>
                                                        +
                                                    </button>
                                                </> 
                                            : 
                                                <>
                                                    <button className="incbutton" onClick={handleClickOpenBooking}>
                                                        -
                                                    </button>
                                                    <p style={{ display: "inline" }} className="border border-top-0 border-bottom-0 indeccounter234">{counter23 && counter23}</p>
                                                    <button className="incbutton" onClick={handleClickOpenBooking}>
                                                        +
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {productdetails23.short_description != null && productdetails23.short_description != "" ? 
                            <div className='row ml-2 mt-5 position-relative' >
                                <div className='col-8 offset-1  '>
                                    <h4 className='text-left fspd' style={{fontWeight:"600"}}>Product Description</h4>
                                    <p className='text-left fsd text-capitalize ml-2'>
                                        {productdetails23.discription}
                                    </p>
                                </div>
                            </div>
                        : null }
                    </>
                }
            </div>
            
            {/* mobile veiew */}
            <div className="mobileconatinerdorproductdetails" >
                {lazyLoading ?
                    <>
                        <Skeleton style={{marginBottom:"15px"}}  height={"325px"} width={"100%"} count={1} ></Skeleton>
                        <Skeleton height={"125px"} width={"100%"} count={4} ></Skeleton>
                    </>
                :
                    <div className='scrollproducvtpage'>
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner imgcaroseul">
                                <div className="carousel-item active" data-interval="2000">
                                    <img className="d-block w-100" src={productdetails23.image_1_url} style={{ borderRadius: "15px" }} onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))} />
                                </div>
                                <div className="carousel-item" data-interval="2000">
                                    <img className="d-block w-100" src={productdetails23.image_2_url} style={{ borderRadius: "15px" }} onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))} />
                                </div>
                                <div className="carousel-item" data-interval="2000"> 
                                    <img className="d-block w-100" src={productdetails23.image_3_url} style={{ borderRadius: "15px" }} onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))} />
                                </div>
                                <div className="carousel-item" data-interval="2000">
                                    <img src={productdetails23.image_4_url} className="d-block w-100" onError={(e) => (e.target.onerror = null)((e.target.src = require('../Assets/Images/log.png')))}></img>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h1 className='productnamefs1 text-left'>{productdetails23.itemName}</h1>
                            </div>
                            <div className='col-8 text-left mt-2'>
                                <span className=' fs2611 ml-0 '>
                                    ₹{productdetails23.mrp}{productdetails23.rate > productdetails23.mrp ? 
                                        <span className='fsdisc12'>
                                            <del>
                                                ₹{productdetails23.rate}
                                            </del>
                                        </span> 
                                    : 
                                        null
                                    }
                                </span>
                                {(productdetails23.mrp == productdetails23.rate) ? 
                                    null
                                : 
                                    <span className=' fs2711 ml-2 pl-0'>{parseFloat(productdetails23.discount).toFixed(2)}%off</span>
                                }
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-6 align-self-center'>
                                        <h1 className='text-left fsrest113'>Units : {productdetails23.min_qty != 0 && productdetails23.min_qty != null ? productdetails23.min_qty : 1} {productdetails23.miu_name!=null && productdetails23.miu_name!="" ? `(${productdetails23.miu_name})` : null} </h1>
                                    </div>
                                    <div className='col-6'>
                                        {shocountericndcn ?
                                            <>
                                                {cookies.skip != 0 ? 
                                                    <>
                                                        <button className='hwbtn131 position-relative ' onClick={() => { addtocartapi(itemid, ivid) }}>Add to Bag</button>
                                                    </> 
                                                : 
                                                    <button className='hwbtn131 position-relative ' onClick={handleClickOpenBooking}>Add to Bag</button>
                                                }
                                            </>
                                        :
                                            <div className='hwbtn1312  incrementdiv d-flex justify-content-center' style={{ top: "1px", left: "26px" }}>
                                                <div style={{ width: "161px", height: "42px", backgroundColor: "rgb(118 118 128 / 0%);", borderRadius: "5px" }} className="inputboxdiv border">
                                                    {cookies.skip != 0 ?
                                                        <>
                                                            <button className="incbutton" onClick={() => {
                                                                if (counter23 && counter23 > 1) {
                                                                    changeStatusOfItems(bbIdItem);
                                                                } else {
                                                                    deletetocartapi(bbIdItem)
                                                                }
                                                                setCounter23(counter23 - 1) }}>
                                                                -
                                                            </button>
                                                            <p style={{ display: "inline-block", width: "36px", borderColor: "#80808033 !important", border: "1px solid #80808033" }} className=" border-top-0 border-bottom-0 indeccounter23">{counter23 && counter23}</p>
                                                            <button className="incbutton" onClick={() => { changeStatusOfItems(bbIdItem); setCounter23(counter23 + 1) }}>
                                                                +
                                                            </button>
                                                        </>
                                                    :
                                                        <>
                                                            <button className="incbutton" onClick={handleClickOpenBooking}>
                                                                -
                                                            </button>
                                                            <p style={{ display: "inline-block", width: "36px", borderColor: "#80808033 !important", border: "1px solid #80808033" }} className=" border-top-0 border-bottom-0 indeccounter23">{counter23 && counter23}</p>
                                                            <button className="incbutton" onClick={handleClickOpenBooking} >
                                                                +
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {(productdetails23 && productdetails23.size != null && productdetails23.size != "" && productdetails23.color != null && productdetails23.color != "" && productdetails23.model != null && productdetails23.model != "") ? 
                                <div className='itemdetailsborder shadow-sm shadow-lg-none mt-3 ml-2 mr-2' >
                                    {/* <div className='col-12 pl-2'>
                                        {(productdetails23.short_description == null) ? 
                                            <h1 className='text-left fsrest121 '>Item Details</h1> 
                                        : 
                                            <h1 className='text-left fsrest121 '>Item Details: </h1>
                                        }
                                    </div> */}
                                    <div className='col-12 pl-2'>
                                        {(productdetails23.size != null && productdetails23.size != "") ? 
                                            <h1 className='text-left fsrest121 '>Size: {productdetails23.size} </h1>
                                        : 
                                            null 
                                        }
                                    </div>
                                    <div className='col-12 pl-2'>
                                        {(productdetails23.color != null && productdetails23.color != "") ? 
                                            <h1 className='text-left fsrest121 '>Color: {productdetails23.color} </h1>
                                        : 
                                            null
                                        }
                                    </div>
                                    <div className='col-12 pl-2'>
                                        {(productdetails23.model != null && productdetails23.model != "") ? 
                                            <h1 className='text-left fsrest121 '>Model: {productdetails23.model} </h1>
                                        :
                                            null
                                        }
                                    </div>
                                    {/* <div className='col-12 pl-2'>
                                        {(productdetails23.ref == null) ? 
                                            <h1 className='text-left fsrest121 '>Reference : N/A </h1> 
                                        : 
                                            <h1 className='text-left fsrest121 '>Reference:{productdetails23.ref} </h1>
                                        }
                                    </div> */}
                                </div>
                            : null }
                        </div>
                        
                        {productdetails23.short_description != null && productdetails23.short_description != "" ? 
                            <div className='row mt-3 ml-0 mr-0' >
                                <div className='col-12  productdecritiondataformobile shadow-sm p-2 bg-white'>
                                    <h4 className='text-left fspd1 mb-0 font-weight-bold'>Product Description</h4>
                                    <p className='text-left fsd1 text-capitalize'>
                                        {productdetails23.short_description}
                                    </p>
                                </div>
                            </div>
                        : null }
                    </div>
                }
                <div className='container positioningbooknowformobile1  '>
                    <div className='row booknowbuttonformobile1 justify-content-between '>
                        <div className='col-7 p-0'>
                            <p className='m-0 p-1 text-white text-left pl-3'>{counter} items | ₹ {counter * 50}</p>
                        </div>
                        <div className='col-5 p-0'>
                            <p className='m-0 p-1 text-white'>Book Now</p>
                        </div>
                    </div>
                </div>
            </div>          
        </>
    )
}