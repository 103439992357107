import React, { useState } from "react";
import Bookingstatus from "./Bookingstatus";
import Headerformobile2 from "../Header/Headerformobile2";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

export default function Mainbookingstatus({setBookingId}) {
    
    const navigate =useNavigate()

    const[cnacelorderpafe,showcancelorderpage]=useState(false);
    const[cancelnodata,setCancelnodata]=useState(false);
    const[bookingnodata,setBookingnodata]=useState(false);
    const[confirmednodata,setConfirmednodata]=useState(false);
    const[deliverednodata,setDeliverednodata]=useState(false);
    
    return(
        <>
            <Headerformobile2 pagename="Booking Status" navigate={navigate} />
            <Header />
            <div className="bookigstatusmobile">
                {/* {cnacelorderpafe ? 
                    <Cancelorderpage setBookingId={setBookingId} bookingId={bookingId}/>
                : */}
                    <Bookingstatus setDeliverednodata={setDeliverednodata} deliverednodata={deliverednodata} setConfirmednodata={setConfirmednodata} confirmednodata={confirmednodata} bookingnodata={bookingnodata} setBookingnodata={setBookingnodata} cancelnodata={cancelnodata} setCancelnodata={setCancelnodata} setBookingId={setBookingId} cnacelorderpafe={cnacelorderpafe} showcancelorderpage={showcancelorderpage}/>
                {/* } */}
            </div>
        </>
    )
}