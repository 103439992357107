import React, { PureComponent, useEffect, useState } from 'react';
import "./Headerformobile2.css"
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function Headerformobile2({pagename, navigate, setSearchItem, bookingId}) {    
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [searchValue, setSearchValue] = useState(null)

    const handleClick = (newPlacement) => (event) => {
        setSearchItem(null)
        setSearchValue(null)
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleChange = (event) => {
        event.preventDefault();
        setSearchItem(searchValue)
    }

    return(
        <div className='displayheadermobile' style={{position:"fixed",width:"100%",zIndex:"1",top:"0px"}}>
            <div className='headerformobile2height position-relative'>
                <div onClick={()=>{window.location.reload(); navigate(-1);}} className='flexhead'>
                    <button className='bg-transparent border-0  text-left backbtutt' style={{top:"8px"}} >
                        <img src={require('../Assets/Images/Left 2.png')} className='leftimg' />
                    </button> 
                    <div className='nameforheader position-absolute text-center'>{pagename}</div>                        
                </div>
                <Box className='searchHeaderBtn' sx={{ width: 70, position: 'absolute', right:'0', zIndex:'99', display: pagename != 'Shop' ? 'none' : 'Block' }}>
                    <Popper sx={{zIndex:'99', width: 270}} open={open} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                            <Typography sx={{ p: 1 }}>
                            <form onSubmit={handleChange}>
                                <input id='searchItemInput' autoComplete='off' onChange={(e) => {setSearchValue(e.target.value)}} placeholder="Search Item..."  />
                            </form>
                            </Typography>
                            </Paper>
                        </Fade>
                        )}
                    </Popper>
                    <Grid item xs={6}>
                        <Button onClick={handleClick("left")}>
                            {searchValue != null ? 
                                <ClearIcon sx={{color:'rgba(239, 123, 55, 1)'}} />
                            :
                                <SearchIcon sx={{color:'rgba(239, 123, 55, 1)'}} />
                            }
                        </Button>
                    </Grid>
                </Box>                    
            </div>
        </div>
    )
}