import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import Headerformobile2 from "../Header/Headerformobile2";
import Helper from "../util/Helper";
import './Address.css'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from "../Header/Header";

const Address = ({setKeyName, setGetAddressId}) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [emptystate, setemptystate] = useState(false)
    const [lazyLoading, setLazyLoading] = useState(true)
    const [addressList, setAddressList] = useState([])

    useEffect(() => {
        const disablePinchZoom = (e) => {
                if (e.touches.length > 1) {
                        e.preventDefault()
                }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
                document.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    useEffect(() => {
        setKeyName(null)
        setGetAddressId(null)
        getAddressList()
    },[])

    const getAddressList = () => {
        const getAddressListUrl = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/booking/get_customer_added_address_new/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getAddressListUrl)
            .then((response) => {
                    if (response.data.code === 200) {
                            setAddressList(Helper.encypt_decrypt(response.data.data))
                            setemptystate(false)
                            setLazyLoading(false)
                    } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                    navigate('/')
                                    localStorage.setItem("isAuthenticated", false)
                                    localStorage.removeItem("isAuthenticated")
                            }, 1000)
                    }  else if (response.data.code === 204) {
                            setemptystate(true)
                            setLazyLoading(false)
                    }
            })
            .catch((error) => {
                    alert(error);
            })
    }

    const deleteAddress = (address_id) => {
        const getDeleteUrl = {
            method: "DELETE",
            url: Helper.getWsUrl(cookies.skip) + `/booking/delete_address_new/${address_id}/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getDeleteUrl)
            .then((response) => {
                    if (response.data.code === 200) {
                        getAddressList()
                    } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                    navigate('/')
                                    localStorage.setItem("isAuthenticated", false)
                                    localStorage.removeItem("isAuthenticated")
                            }, 1000)
                    }
            })
            .catch((error) => {
                    alert(error);
            })
    }

    return (
        <>
            <Headerformobile2  pagename="Address" navigate={navigate} />
            <Header />
            <div className='row no-gutters mtp100' >
                <div className='path_direction dashbbdbooking' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "15px" }}>
                    <p >
                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                        &gt;
                        <Link to="/address" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Address</Link>
                    </p>
                </div>
            </div>

            <div className='btn_newAddress_div'>
                <button onClick={() => {navigate('/address/location')}} className='btn_newAddress'>
                    <img src={require('../Assets/Images/images.png')} />
                    <p>Add New Address</p>
                </button>
            </div>

            {!emptystate ?
                <div className="addressCardContainer">
                    {lazyLoading ? 
                        <Skeleton height={"125px"} width={"100%"} count={6} ></Skeleton>
                    :
                        <>
                            {addressList && addressList.map((value, index) => {
                                return(
                                    <div className='addressCard'>
                                        <h6>{value.reciver_name} <span>{value.type != 2 ? value.type != 1 ? 'Home' : 'Work' : 'Other'}</span></h6>
                                        <p>{value.near_by},&nbsp;{value.address}</p>
                                        <p>Mobile Number : <small>{value.reciver_mobile_number}</small></p>
                                        <div className="addressCardBtn">
                                            <button onClick={() => {setGetAddressId(value.address_id); navigate('/address/addaddress')}}>EDIT</button>
                                            <button onClick={() => {deleteAddress(value.address_id)}} >DELETE</button>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            :
                <div className="addressEmptyState">
                    <img style={{width:'150px', height:'150px'}} src={require('../Assets/Images/customer icons_location.svg').default} className='imhgemptycart' />
                    <p className='paraforempty2 mt-3'>Add Address for delivery or pickup items.</p>
                </div>
            }
        </>
    )
}
export default Address