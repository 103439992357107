import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Favorite.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Favorites({ setividforproductpage }) {

  const navigate = useNavigate()
  const [cookies, setCookies, removeCookies] = useCookies(["token"])

  const [favitems, setfavitems] = useState([])
  const [fav_prodlen, setfav_prodlen] = useState(null)
  const [lazyLoading, setLazyLoading] = useState(true);

  // shoprodctpage(true)

  useEffect(() => {
    loadshops()
  }, [])

  function loadshops() {
    const resquestfavoriteproducts = {
      method: "GET",
      url: Helper.getWsUrl(cookies.skip) + `/contact/get_favorite_items/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
    }
    axios(resquestfavoriteproducts)
      .then((response) => {
        if (response.data.code === 200) {
          setfavitems(Helper.encypt_decrypt(response.data.data).item_details)
          setfav_prodlen(1)
          setLazyLoading(false)
        } else if (response.data.code === 204) {
          setfav_prodlen(0)
          setLazyLoading(false)
        } else if (response.data.code === 401) {
          removeCookies("logged_in")
          setTimeout(() => {
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function removefavitem(itmid, iv, shp) {
    const removefavproducts = {
      method: "Delete",
      url: Helper.getWsUrl(cookies.skip) + `/contact/remove_favorite_shop/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        "item_id": itmid,
        "iv_id": iv,
        "shop_id": shp,
      }
    }
    axios(removefavproducts)
      .then((response) => {
        if (response.data.code === 202) {
          loadshops()
        } else if (response.data.code === 401) {
          removeCookies("logged_in")
          // removeCookies("token")
          setTimeout(() => {
            // navigate('/')
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      {lazyLoading ? 
        <Skeleton height={"120px"} style={{ marginRight: "250px" }} width={"100%"} count={4} ></Skeleton> 
      :
        <>
          {(fav_prodlen == 0 || fav_prodlen == null) ?
            <div className=' mobileviewforudhaar1  pl-2 pr-2'>
              <div className='emptystateset41 d-flex justify-content-center align-items-center flex-column h-100'>
                <img src={require('../Assets/Images/product.svg').default} className='imhgemptycart' />
                <p className='paraforempty2 mt-3'>Add items to favorites from shops.</p>
              </div>
            </div>
          :
            <div className="row main-row1 heightweb no-gutters" >
              {favitems && favitems.map((val10213) => {
                return (
                  <div className="col-lg-3 card-main mb-lg-3 mt-lg-3 mb-2 ">
                    <div onClick={() => {setividforproductpage(val10213.iv_id);}} className="main-card border p-lg-2 p-0 ">
                      <div className="card-image " style={{ position: "relative" }}>
                        <Link className="text-decoration-none ">
                          <img style={{ borderRadius: "15px" }} src={val10213.featured_img_url} className="card-imageimg"
                              onError={(e) =>
                                (e.target.onerror = null)(
                                  (e.target.src = require('../Assets/Images/log.png'))
                                )}/>
                        </Link>
                        <div className="maindivforheart3dashboard23">
                          <img src={require('../Assets/Images/orangeheart.png')} className="maindivforheart3dashboard23img " onClick={() => { removefavitem(val10213.item_id, val10213.iv_id, val10213.contact_id); }} />
                        </div>
                      </div>

                      <div className="card-text mt-0 mt-lg-2 ml-2 ml-lg-0" onClick={() => { navigate('/shop/product')}}>
                        <div className="product-card">
                          <h6 className="fw-bold shopnameprductcard text-dark pt-2 font-weight-bold mb-1 text-capitalize">{val10213.itemName} </h6>
                        </div>
                        <div className="unit-text unitaddshop text-truncate mb-2 text-capitalize mt-0 mt-lg-3">Unit: {val10213.min_qty} {val10213.miu_name}</div>
                        <div className='row  no-gutters flex-column'>
                          {(val10213.amount == val10213.mrp) ?
                            <div className='col-12 fs26'>₹ {val10213.amount}</div>
                          :
                            <div className='col-12 fs26'>₹ {val10213.amount}<span className='fsdisc'><del>₹{val10213.mrp}</del></span></div>
                          }
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </>
      }
    </>
  );
}
