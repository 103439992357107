import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Bookingstatus.css';
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Bookingstatus = ({ setBookingnodata, setBookingId, showcancelorderpage }) => {
        
        const [cookies, setCookie, removeCookie] = useCookies(['token']);
        const navigate = useNavigate();
        
        const [crossbt1, setcrossbt1] = useState(false);
        const [crossbt2, setcrossbt2] = useState(false);
        const [crossbt3, setcrossbt3] = useState(false);
        const [crossbt4, setcrossbt4] = useState(false);
        const [name1, setname1] = useState("All")
        const [numbers, setnumbers] = useState(null)
        const [codeValue, setCodeValue] = useState(null)
        const [lazyLoading, setLazyLoading] = useState(true);
        const [pageNo, setPageNo] = useState(1)
        const [getIsTrusted, setGetIsTrusted] = useState(false)
        const [lets_set_booking_data, set_booking_data] = useState([])
        const [lets_booking_legth, set_booking_legth] = useState(false);
        const [forScrollData, setForScrollData] = useState([]);
        const [cardNo, setCardNo] = useState(0)
        const [filter, setFilter] = useState(false)

        useEffect(() => {
                window.addEventListener('scroll', (event) => {
                        setGetIsTrusted(event.isTrusted)
                })
        }, [])

        useEffect(() => {
                const disablePinchZoom = (e) => {
                        if (e.touches.length > 1) {
                                e.preventDefault()
                        }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                        document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        useEffect(() => {
                if (getIsTrusted) {
                        setPageNo(pageNo + 1)
                        mainapi()
                }
        }, [getIsTrusted])

        useEffect(() => {
                mainapi()
        }, [cardNo, numbers, filter])

        useEffect(() => {
                if (forScrollData && forScrollData.length > 0) {
                        forScrollData.map((value, index) => {
                                if (!lets_set_booking_data.find((element) => value.booking_id === element.booking_id)) {
                                        set_booking_data((prevState) =>
                                                [...prevState, forScrollData[index]]
                                        )
                                }
                        })
                }
        }, [forScrollData])

        useEffect(() => {
                if(codeValue && codeValue != null ){
                        if (lets_set_booking_data && lets_set_booking_data.length > 0) {
                                set_booking_legth(false)
                        } else {
                                if (numbers === null) {                                       
                                        set_booking_legth(true)
                                }
                        }
                }
        }, [lets_set_booking_data])


        function mainapi() {
                const final_url = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `${!filter ? `/booking/get_Customer_booking/?page=${pageNo}&no_of_record=10&web=1` : `/booking/get_Customer_booking/?page=${pageNo}&no_of_record=10&value1=${numbers}&web=1`}`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(final_url)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        setForScrollData(Helper.encypt_decrypt(response.data.data))
                                        set_booking_legth(false);
                                        setBookingnodata(false)
                                        setLazyLoading(false)
                                        setGetIsTrusted(false)
                                        setCodeValue(response.data.code)
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                } else if (response.data.code === 204) {
                                        setCodeValue(response.data.code)
                                        setLazyLoading(false);
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const mobilecard = () => {
                return (
                        <>
                                {lets_set_booking_data && lets_set_booking_data.map((value, index) => {
                                        return (
                                                <div className='row ml-1 mr-1 mt-3 mb-3 ' onClick={() => { setBookingId(value.booking_id); navigate('/bookingstatus/detail'); showcancelorderpage(true); }}>
                                                        <div className='border w-100 p-2 setborderrdius shadow-sm' >
                                                                <div className='col-12 p-0'>
                                                                        <p className='snform1'>{value.shop_name}</p>
                                                                </div>
                                                                <div className='col-12 p-0'>
                                                                        <p className='snform2'>Booking No. :- {value.booking_number}</p>
                                                                </div>
                                                                <div className='col-12 p-0'>
                                                                        <p className='snform3'>Booking Group No. :- {(value.bg_no == null) ? "N/A" : value.bg_no}</p>
                                                                </div>
                                                        </div>

                                                        <div className='col-12 pl-0 pr-0 mt-n0'>
                                                                <div className='row no-gutters pl-2 pr-2 justify-content-between'>
                                                                        <div className='col-5  hwbooked1 pl-2  ' onClick={() => { { setBookingId(value.booking_id) } }}>
                                                                                <div>
                                                                                        <p className='snform32'>Booked</p>
                                                                                </div>
                                                                                <div>
                                                                                        <p className='snform321'>{value.date_time === null ? "" : value.date_time}</p>
                                                                                </div>
                                                                        </div>
                                                                        {(value.booking_status === 0) ?
                                                                                ""
                                                                        :
                                                                                (value.booking_status == 1) ?
                                                                                        <div className='col-5  hwbooked12  pl-2' onClick={() => { { setBookingId(value.booking_id) } }} >
                                                                                                <div>
                                                                                                        <p className='snform32'> Confirmed  </p>
                                                                                                </div>
                                                                                                <div className='snform321'>
                                                                                                        <p>{value.confirmed_date_time === null ? "" : value.confirmed_date_time}</p>
                                                                                                </div>
                                                                                        </div>
                                                                                :
                                                                                        (value.booking_status == 4) ?
                                                                                                <div className='col-5  hwbooked121  pl-2 ' onClick={() => { { setBookingId(value.booking_id) } }} >
                                                                                                        <div >
                                                                                                                <p className='snform32'>Delivered</p>
                                                                                                        </div>
                                                                                                        <div className='snform321'>
                                                                                                                <p>{value.delivered_date_time === null ? "" : value.delivered_date_time}</p>
                                                                                                        </div>
                                                                                                </div>
                                                                                        :
                                                                                                (value.booking_status == 3) ?
                                                                                                        <div className='col-5  hwbooked1212  pl-2 ' onClick={() => { { setBookingId(value.booking_id) } }}>
                                                                                                                <div >
                                                                                                                        <p className='snform32'>Cancelled</p>
                                                                                                                </div>
                                                                                                                <div className='snform321'>
                                                                                                                        <p>{value.cancel_date_time === null ? "" : value.cancel_date_time}</p>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                :
                                                                                                        (value.booking_status == 2) ?
                                                                                                        <div className='col-5  hwbooked12  pl-2 ' onClick={() => { { setBookingId(value.booking_id) } }}>
                                                                                                                <div >
                                                                                                                        <p className='snform32'>Packed Items</p>
                                                                                                                </div>
                                                                                                                <div className='snform321'>
                                                                                                                        <p>{value.confirmed_date_time === null ? "" : value.confirmed_date_time}</p>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                                null
                                                                        }
                                                                </div>
                                                        </div>
                                                </div>
                                        )
                                })}
                        </>
                )

        }

        const showA12 = () => {
                setname1("All")
        }
        const showA1 = () => {
                setname1("Booked")
        }
        const showB1 = () => {
                setname1("Confirmed")
        }
        const showC1 = () => {
                setname1("Delivered")
        }
        const showCancelled = () => {
                setname1("Cancelled")
        }

        const nullcards = () => {
                return (
                        <>
                                {lets_set_booking_data && lets_set_booking_data.map((value, index) => {
                                        return (
                                                <div className='row justify-content-around'>
                                                        <div className='col-lg-8  p-0     '>
                                                                <div className='row bill_box marg12 p-2' >
                                                                        <div className='col-12 marg1'>
                                                                                <div className='row '>
                                                                                        <div className='col-5 pl-0'>
                                                                                                <p className='text-left bill_shopnamebooking text-capitalize text-truncate'>{value.shop_name}</p>
                                                                                        </div>
                                                                                        <div className='col-3'>
                                                                                                <p className=' bill_Bookedbooking text-left'>Booked</p>
                                                                                        </div>
                                                                                        <div className='col-4 pr-3'>
                                                                                                {(value.booking_status == 0) ?
                                                                                                        <p className='text-left'></p>
                                                                                                :
                                                                                                        (value.booking_status == 1) ?
                                                                                                                <p className='confi22'>Confirmed</p>
                                                                                                        :
                                                                                                                (value.booking_status == 4) ?
                                                                                                                        <p className='canbook'>Delivered</p>
                                                                                                                :
                                                                                                                        (value.booking_status == 3) ?
                                                                                                                                <p className='confi22'> Cancelled</p>
                                                                                                                        :
                                                                                                                                null
                                                                                                }
                                                                                        </div>
                                                                                </div>
                                                                        </div>

                                                                        <div className='col-12 marg2'>
                                                                                <div className='row '>
                                                                                        <div className='col-5 pl-0'>
                                                                                                <p className='text-left bill_bnbooking'>{value.booking_number}</p>
                                                                                                <p className='text-left bgn1'>{(value.bg_no == null || value.bg_no == "") ? "N/A" : value.bg_no}</p>
                                                                                        </div>
                                                                                        <div className='col-3'>
                                                                                                <p className='dtbooking' style={{ width: "max-content" }}>{value.data_time === null ? "" : value.date_time.split("", 13)}</p>
                                                                                                <p className='dtbooking' style={{ width: "max-content" }}>{value.date_time === null ? "" : value.date_time.slice(13, 21)}</p>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>

                                                        <div className='col-lg-3 btnbox ml-n4 pl-0 d-flex align-items-center justify-content-center ' onClick={() => {setBookingId(value.booking_id); navigate('/bookingstatus/detail'); showcancelorderpage(true);}}>
                                                                <button className='bill_viewbtnbooking center '>
                                                                        View <img src={require('../Assets/Images/Right.png')} style={{ position: "absolute", top: "12px", right: "14px", height: "15px", width: "15px" }} />
                                                                </button>
                                                        </div>
                                                </div>

                                        )
                                })}
                        </>
                )

        }

        return (
                <>
                        {/* Breadcrumb */}
                        <div className='margtop22'>
                                <div className='path_direction dashbbdbooking  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                                        <p style={{ color: "#7A7A7A" }}>
                                                <Link to="/dashboard" className='text-decoration-none text-dark m-1'>Home</Link>
                                                &gt;
                                                <Link to="/bookingstatus" className='text-decoration-none text-dark m-1'>Booking Status</Link>
                                        </p>
                                </div>
                                {lets_booking_legth ?
                                        <div className='emptystateset2 d-flex justify-content-center align-items-center flex-column h-100 '>
                                                <img src={require('../Assets/Images/booking.svg').default} className='imhgemptycart' />
                                                <p className='paraforempty2 mt-3'>Book items from same or different shops.</p>
                                        </div> 
                                : 
                                        <div className="bill_main_box66 row webview justify-content-center mt-0 d-flex">
                                                <div className="bill_filter_box33 col-3 webview">
                                                        {crossbt1 ?
                                                                <button className='clickfilter_btnbooking center' onClick={() => { setcrossbt1(false)}}> Booked </button> 
                                                        :
                                                                <button className='filter_btnbooking center' onClick={() => { if(numbers != 0) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(1); setcrossbt1(true); setcrossbt2(false); setcrossbt3(false); setcrossbt4(false); setnumbers(0)} }}>Booked</button>
                                                        }

                                                        {crossbt2 ?
                                                                <button className='clickfilter_btnbooking center' onClick={() => { setcrossbt2(false) }}> Confirmed </button> 
                                                        :
                                                                <button className='filter_btnbooking center' onClick={() => { if(numbers != 1) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(2); setcrossbt2(true); setcrossbt1(false); setcrossbt3(false); setcrossbt4(false); setnumbers(1)} }}>Confirmed</button>
                                                        }

                                                        {crossbt3 ?
                                                                <button className='clickfilter_btnbooking center' onClick={() => setcrossbt3(false)}> Delivered</button> 
                                                        :
                                                                <button className='filter_btnbooking center' onClick={() => { if(numbers != 4) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(3); setcrossbt3(true); setcrossbt2(false); setcrossbt1(false); setcrossbt4(false); setnumbers(4)} }}>Delivered</button>
                                                        }

                                                        {crossbt4 ?
                                                                <button className='clickfilter_btnbooking center' onClick={() => setcrossbt4(false)}> Cancelled</button> 
                                                        :
                                                                <button className='filter_btnbooking center' onClick={() => { if(numbers != 3) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(4); setcrossbt4(true); setcrossbt2(false); setcrossbt3(false); setcrossbt1(false); setnumbers(3)} }}>Cancelled</button>
                                                        }
                                                </div>

                                                <div className='bill_list_boxbooking col-8  webview'>
                                                        {lazyLoading ?
                                                                <Skeleton height={"125px"} width={"100%"} count={10} ></Skeleton>
                                                        :
                                                                <>
                                                                        {lets_set_booking_data.length > 0 ?
                                                                                nullcards()
                                                                        :
                                                                                <p>no data found</p>
                                                                        }
                                                                </>
                                                        }
                                                </div>

                                                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                        <div className="modal-body">
                                                                                <p>From</p>
                                                                                <input autoComplete="off" type="date" className="input_data cal2" />
                                                                                <p className='date_box_to'>To</p>
                                                                                <input style={{ marginBottom: "20px" }} autoComplete="off" type="date" className="input_data cal2" />
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                }
                        </div>

                        {/* Breadcrumb */}
                        {/* mobile view */}
                        <div className='  ml-2 mr-2 bookingdivmobile' style={{ backgroundColor: "whitesmoke" }} >
                                {lets_booking_legth ?
                                        <div className='emptystateset d-flex justify-content-center align-items-center flex-column '>
                                                <img src={require('../Assets/Images/booking.svg').default} className='imhgemptycart' />
                                                <p className='paraforempty2 mt-3'>Book items from same or different shops.</p>
                                        </div> 
                                :
                                        <>
                                                <div className='row no-gutters'>
                                                        <div className='col-12 '>
                                                                <div className="dropdown  ">
                                                                        <button className=" d-block w-100  bg-white text-left" type="button" id="dropdownMenuButton514" data-toggle="dropdown" onClick={() => { document.getElementById('dhh121').classList.toggle("dwnimgwh1") }}>
                                                                                <span className=''>{name1}</span> 
                                                                                <span>
                                                                                        <img src={require('../Assets/Images/Down.jpg')} className='dwnimgwh' id="dhh121" />
                                                                                </span>
                                                                        </button>
                                                                        <div className="dropdown-menu w-100 border-0 " aria-labelledby="dropdownMenuButton">
                                                                                <a className="dropdown-item dropthbth1" href="#" onClick={() => { if(filter) {set_booking_data([]); setFilter(false); setPageNo(1); setCardNo(5); showA12(); setnumbers(null)} }}>All</a>
                                                                                <a className="dropdown-item dropthbth" href="#" onClick={() => { if(numbers != 0) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(1); showA1(); setnumbers(0)} }}>Booked</a>
                                                                                <a className="dropdown-item dropthbth" href="#" onClick={() => { if(numbers != 1) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(2); showB1(); setnumbers(1)} }}>Confirmed</a>
                                                                                <a className="dropdown-item dropthbth" href="#" onClick={() => { if(numbers != 4) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(3); showC1(); setnumbers(4)} }}>Delivered</a>
                                                                                <a className="dropdown-item dropthbth" href="#" onClick={() => { if(numbers != 3) {set_booking_data([]); setFilter(true); setPageNo(1); setCardNo(4); showCancelled(); setnumbers(3)} }}>Cancelled</a>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>

                                                {/* empty state */}
                                                {lazyLoading ?
                                                        <Skeleton height={"125px"} count={6} ></Skeleton> 
                                                :
                                                        <div className='row m-auto'>
                                                                <div className=' col-12 ml-lg-5 pl-0 pr-0  scroolbooking' onScroll={(event) => { setGetIsTrusted(event.isTrusted) }}>
                                                                        {lets_set_booking_data.length > 0 ?
                                                                                mobilecard()
                                                                        :
                                                                                <p>No data found </p>
                                                                        }
                                                                </div>
                                                        </div>
                                                }

                                        </>
                                }
                        </div>
                </>
        )
}
export default Bookingstatus;


