import React,{useState} from 'react'
import Headerformobile2 from '../../Header/Headerformobile2'
import "./Emptypage.css"


export default function Emptypage()
{
     
 

    return(
                <>
                 <Headerformobile2 pagename="Bag"/>

                    <div className='row borderforfullrow border'>
                            <div className="col justify-content-center align-items-center d-flex">
                                <div>
                                <img src={require('../../Assets/Images/cartempty.png')} className='imhgemptycart'/>
                                    <p className='paraforempty mt-3'>Add items from same or different shops.</p>
                                </div>
                                   
                            </div>
                    </div>

                
                </>      
    )
}