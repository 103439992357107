import './Gethelp.css';
import axios from 'axios';
import Helper from '../util/Helper';
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie'
import { useNavigate } from "react-router-dom";
import Headerformobile2 from "../Header/Headerformobile2";
import React, { useState, useEffect, useCallback } from "react";
import {  CAccordion, CAccordionBody, CAccordionItem, CAccordionHeader } from "@coreui/react";
import Header from '../Header/Header';

const GetHelp = () => {

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [faqQuestions, setFaqQuestions] = useState([]);
    const [getyoutube, setGetyoutube] = useState("");
    const [forVideoUrl, setForVideoUrl] = useState(null);
    const [getsupportticket, setGetsupportticket] = useState([]);
    const [postdescdata, setPostdescdata] = useState(null);
    const [queryid, setQueryId] = useState("");
    const [querydata, setQueryData] = useState([]);
    const [querydate,setQueryDate]=useState("");
    const [queryreply,setQueryReply]=useState([]);
    const [con, setCon] = useState(false);

    useEffect(() => {
        if (postdescdata && postdescdata != null) {
            setCon(true);
        }
    }, [postdescdata]);

    useEffect(() => {
        const disablePinchZoom = (e) => {
                if (e.touches.length > 1) {
                        e.preventDefault()
                }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
                document.removeEventListener("touchmove", disablePinchZoom)
        }
}, [])

    const QueryList=(id)=>{
        const Queries = {
          method: "GET",
          url: Helper.getWsUrl(cookies.skip) + `/support/ticket_details/${id}?web=1`,
          headers: { "Content-Type": "application/json", token: cookies.token },
        };
        axios(Queries)
          .then((response) => {
            if (response.data.code === 200) {
            //   console.log("datatatatatatat : ", Helper.encypt_decrypt(response.data.data))
              setQueryData(Helper.encypt_decrypt(response.data.data).all_reply)
              setQueryDate(Helper.encypt_decrypt(response.data.data).ticket_details)
            } else if (response.data.code === 401) {
              removeCookie("logged_in")
              removeCookie("token")
            //   setTimeout(() => {
            //   navigate('/')
            //   localStorage.setItem("isAuthenticated", false)
            //   localStorage.removeItem("isAuthenticated")
            //   }, 1000)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } 

    const getpostdataFunc = useCallback((event) => {
        const { name, value } = event.target;
        setPostdescdata({ ...postdescdata, [name]: value });
    });

    const postSupportHelpApi = () => {
        const requestgethelp = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `/support/AddQuery/?web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
            data: {
                description: postdescdata && postdescdata.discription,
                tittle: postdescdata && postdescdata.title,
                user_type: 0,
            },
        };
        axios(requestgethelp)
            .then((response) => {
                //   setCookie("token", response.headers.token);
                if (response.data.code === 201) {
                    // alert("data add successfully");
                    window.location.reload()
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const getTicketList = () => {
        const requestOptions = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/support/ticket_list/?web=1`,
            headers: { "Content-Type": "application/json", token: cookies.token },
        };
        axios(requestOptions)
            .then((response) => {
                if (response.data.code === 200) {
                    // console.log("ticketlist", Helper.encypt_decrypt(response.data.data));
                    setGetsupportticket(Helper.encypt_decrypt(response.data.data));
                }
                else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    //   setTimeout(() => {
                    //       navigate('/')
                    //       localStorage.setItem("isAuthenticated", false)
                    //       localStorage.removeItem("isAuthenticated")
                    //   }, 1000)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getHelpFaqApi = () => {

        const getBillListUrl = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/settings/get_faqs_customer/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
            .then((response) => {
                // setCookie("token", response.headers.token);            
                if (response.data.code === 200) {
                    console.log("faq:", response.data.data)
                    setFaqQuestions(response.data.data)
                    // setInventoryEmptyState(false)
                    // setSkeletonLoading(false)             
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    // setTimeout(() => {
                    //     navigate('/')
                    //     localStorage.setItem("isAuthenticated", false)
                    //     localStorage.removeItem("isAuthenticated")
                    // }, 1000)
                }
                //      else if(response.data.code === 204) {
                //         setInventoryEmptyState(true)
                //         setSkeletonLoading(false)
                //     }
            })
            .catch((error) => {
                alert(error);
            })
    }
    
    const getreply = (e) => {
        setQueryReply(e.target.value);  
      };

    const getVidepApi = () => {

        const getBillListUrl = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/promotions/get_youtubevideo/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
            .then((response) => {
                // setCookie("token", response.headers.token);            
                if (response.data.code === 200) {
                    // console.log("video:", Helper.encypt_decrypt(response.data.data))
                    setGetyoutube(Helper.encypt_decrypt(response.data.data))

                    // setInventoryEmptyState(false)
                    // setSkeletonLoading(false)             
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    // setTimeout(() => {
                    //     navigate('/')
                    //     localStorage.setItem("isAuthenticated", false)
                    //     localStorage.removeItem("isAuthenticated")
                    // }, 1000)
                }
                //      else if(response.data.code === 204) {
                //         setInventoryEmptyState(true)
                //         setSkeletonLoading(false)
                //     }
            })
            .catch((error) => {
                alert(error);
            })
    }
    const postQueryReply = () => {
        const postQuery = {
          method: "POST",
          url: Helper.getWsUrl(cookies.skip) + `/support/AddReply/${queryid}?web=1`,
          headers: { "Content-Type": "application/json", token: cookies.token },
          data: {
                  reply:queryreply
                },
        };
        axios(postQuery)
          .then((response) => {
          // setCookie("token", response.headers.token);
          if (response.data.code === 200) {
            window.location.reload()
          } else if (response.data.code === 401) {
            removeCookie("logged_in")
            removeCookie("token")
            // setTimeout(() => {
            // navigate('/')
            // localStorage.setItem("isAuthenticated", false)
            // localStorage.removeItem("isAuthenticated")
            // }, 1000)
          }
        })
        .catch((error) => {
          alert(error);
        });
      };
   
    useEffect(() => {
        getHelpFaqApi();
        getVidepApi();
        getTicketList();
    }, [])


    return (
        <div>
            <div className="web-gethelp">
                {/* WEB SCREEN ONLY */}
                <Header />

                <div className="p-5 " style={{ marginTop: "-15px" }}>
                    <div
                        className="d-flex"
                        style={{ color: "#7A7A7A", marginTop: "-20px",marginBottom:"29px" }}
                    >
                        <div className="p-1"><Link to="/dashboard" className="text-decoration-none linkfirpagegethelp" >Home</Link></div>
                        <div className="p-1 linkfirpagegethelp">{">"}</div>

                        <div className="p-1"><Link to="/gethelp" className="text-decoration-none linkfirpagegethelp">Get Help</Link></div>
                    </div>
                    <div className="mt-4 d-flex justify-content-between" style={{}}>
                        <div className="gethelpsupp">Get Help & Support</div>
                        <div className="">
                            <button
                                className=" raisquerybtn text-white"
                                data-toggle="modal" data-target="#exampleModalFIRST"

                            >
                                Raise a Query
                            </button>

                            {/* new modal */}
                            <div class="modal fade" id="exampleModalFIRST" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <input style={{border:"none"}} name="title" onChange={getpostdataFunc} className="inp" placeholder="Add Title..." />
                                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div class="modal-body " style={{ overflow: "hidden" }}>
                                            <textarea maxLength={150} id="getHelpTextArea" placeholder="Write Here.." onChange={getpostdataFunc} name="discription" style={{ width: "100%", resize: "none", border: "none" }} />
                                        </div>
                                        {con ? (
                                            <div style={{ width: "100%", overflow: "hidden", textAlign: "right", padding: "0 20px 20px 0" }}>
                                                <button onClick={postSupportHelpApi} style={{ color: "#fff", background: "#008c53", border: "none", borderRadius: "25px", padding: "5px 25px" }} data-bs-dismiss="modal">
                                                    Done
                                                </button>
                                            </div>
                                        )
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="recenttickets1 text-left">Recent Tickets</div>
                    </div>
                    <div className="p-4">
                        {getsupportticket && getsupportticket.map((value) => {
                            return (
                                <div onClick={() => {setQueryId(value.id); QueryList(value.id);}} data-bs-toggle="modal" data-bs-target="#replyModal">
                                    <ul className="ulborder shadow"  >
                                        <li className="headline1 text-left">{value.title}</li>
                                        <div className="text-left headlinetext">
                                            {value.description}
                                        </div>
                                    </ul>

                                </div>
                            )
                        })}
                         <div class="modal fade" id="replyModal" tabindex="-1" role="dialog" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dilaog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-body  ">
                  <div style={{width:"100%", textAlign:"right"}}>
                    <p style={{width:"100%", fontWeight:"500"}}>{querydate && querydate.add_date_time}</p>
                  </div>
                  <div>
                    <p style={{margin:"0", padding:"0px", color:"#008c53", fontWeight:"600", fontSize:"18px",textAlign:"left"}}  className="text-truncate">{querydate && querydate.title}</p>
                    <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"400",textAlign:"left"}}  className="text-truncate">{querydate && querydate.description}</p>
                  </div>
                  <hr></hr>
                  {querydata && querydata.map((value)=>{
                    return (
                      <>
                        <div style={{display:"flex"}} className="justify-content-between">
                          <div className="w-50">
                            <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"500",textAlign:"left"}} className="overflow-scroll w-50">{value.reply_by}</p>
                            <p style={{margin:"0", padding:"0px", fontSize:"14px", fontWeight:"400",textAlign:"left"}} className=" w-100 text-break ">{value.reply}</p>
                          </div>
                          <div style={{textAlign:"right", width:"30%"}}>
                            <p style={{color:"#008c53", fontSize:"14px"}}>{value.add_date_time}</p>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  })}
                  <div style={{marginTop:"50px"}}>
                    <input style={{ width: "80%", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgba(0,140,83)" }} maxLength="150" placeholder="Type here..." onChange={getreply} />
                    <button onClick={() => {postQueryReply()}} style={{ backgroundColor: "rgba(0,140,83)", color: "white", border: "none", padding: "5px 10px", marginLeft: "10px" }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

                    </div>



                    <div className="mb-3" >
                        <div className="text-left knowlwdgebase">Knowledge base</div>
                    </div>

                    <div className="container">
                        <div className="row">
                            {getyoutube && getyoutube.map((value) => {
                                return (
                                    <div className="col-6">
                                        <div>
                                            <img
                                                onClick={() => { setForVideoUrl(value.y_id); }}
                                                src={value.thumbnail}
                                                width={"100%"}
                                                height={"100%"}
                                                 style={{borderRadius:"10px"}}
                                                data-toggle="modal" data-target="#exampleModal"
                                            />
                                            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">

                                                        <div className="modal-body ">
                                                            <div class="modal-dialog">
                                                                <ReactPlayer width={"100%"} url={`https://www.youtube.com/watch?v=${forVideoUrl}`} playing />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="text-center mt-2"> {value.title}</div>
                                    </div>

                                )
                            })}


                        </div>
                    </div>

                    <div className="mt-4 mb-3">
                        <div className="faq text-left">Frequently Asked Questions (FAQ’S)</div>
                    </div>

                    <div>
                        {/* accordion or collapse */}





                        <div id="main">
                            <div className="container">
                                <div className="accordion" id="faq">
                                    {/* {faqQuestions && faqQuestions.map((value,index) => {
                                        return (
                                            <>
                                                <div className="card">
                                                    <div className="card-header" id="faqhead1">
                                                        <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target={`#faq1${index}`}
                                                            aria-expanded="true" aria-controls="faq1">{value.question}</a>
                                                    </div>

                                                    <div id={`#faq1${index}`} className="collapse " aria-labelledby="faqhead1" data-parent="#faq">
                                                        <div className="card-body">
                                                            {value.answer}
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    })} */}
                                    {faqQuestions && faqQuestions.map((value, index) => {
                                        return (
                                            <div class="card " style={{border:"1px solid #80808012"}}>
                                                <h2 class="card-header" id={`flush-heading${index}`}>
                                                    <button className="btn btn-header-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                                        {value.question}
                                                    </button>
                                                </h2>
                                                <div id={`flush-collapse${index}`} className="collapse " aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                                                    <div class="card-body">{value.answer}</div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>




                    </div>
                </div>

            </div>

            {/* MOBILE VIEW SCREEN */}

            <div>
            <Headerformobile2 pagename="Help & Support" navigate={navigate} />
                <div className="mob-gethelp">
                    {/* <div className="d-flex mt-3 justify-content-center btn-switch">
            <div
              className={show ? "selectbtn" : "selectbtn1"}
              onClick={() => {
                setShow(true);
                setShow1(false);
                setShow2(false);
              }}
            >
              Recent Tickets
            </div>
            <div
              className={show1 ? "selectbtn" : "selectbtn1"}
              onClick={() => {
                setShow(false);
                setShow1(true);
                setShow2(false);
              }}
            >
              Knowledge Base
            </div>
            <div
              className={show2 ? "selectbtn" : "selectbtn1"}
              onClick={() => {
                setShow(false);
                setShow1(false);
                setShow2(true);
              }}
            >
              Faq's
            </div>
          </div> */}

                    <div className="row row-btn">
                        <div className="col p-0">
                            <div
                                className={show ? "selectbtn" : "selectbtn1"}
                                onClick={() => {
                                    setShow(true);
                                    setShow1(false);
                                    setShow2(false);
                                }}
                            >
                                RecentTickets
                            </div>
                        </div>
                        <div className="col p-0">
                            <div
                                className={show1 ? "selectbtn" : "selectbtn1"}
                                onClick={() => {
                                    setShow(false);
                                    setShow1(true);
                                    setShow2(false);
                                }}
                            >
                                Knowledge Base
                            </div>
                        </div>
                        <div className="col p-0">
                            <div
                                className={show2 ? "selectbtn" : "selectbtn1"}
                                onClick={() => {
                                    setShow(false);
                                    setShow1(false);
                                    setShow2(true);
                                }}
                            >
                                Faq’s
                            </div>
                        </div>
                    </div>
                    {show ? (
                        <>
                          <div style={{display:"flex",justifyContent:"end",padding:"3%"}} >
                             <button  data-toggle="modal" data-target="#exampleModalFIRSTmb" style={{marginTop:"1%",border:"2px solid #EE7A37",backgroundColor:"white",padding:"2px 20px",borderRadius:"20px",fontWeight:"600"}} className="text-dark raiseuwey">Raise a Query</button>
                             <div class="modal fade" id="exampleModalFIRSTmb" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <input style={{border:"none"}} name="title" onChange={getpostdataFunc} className="inp w-100"  placeholder="Add Title..." />
                                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div class="modal-body" style={{ overflow: "hidden" }}>
                                            <textarea maxLength={150} id="getHelpTextArea" placeholder="Write Here.." onChange={getpostdataFunc} name="discription" style={{ width: "100%", resize: "none", border: "none" }} />
                                        </div>
                                        {con ? (
                                            <div style={{ width: "100%", overflow: "hidden", textAlign: "right", padding: "0 20px 20px 0" }}>
                                                <button onClick={postSupportHelpApi} style={{ color: "#fff", background: "#008c53", border: "none", borderRadius: "25px", padding: "5px 25px" }} data-bs-dismiss="modal">
                                                    Done
                                                </button>
                                            </div>
                                        )
                                            : null}
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div className="scrollheandp">
                            {getsupportticket && getsupportticket.map((value) => {
                                return (
                                    <>
                                        <div  className="p-3">
                                            <div className="card shadow border-0" onClick={() => {setQueryId(value.id); QueryList(value.id);}} data-bs-toggle="modal" data-bs-target="#replyModalmb">
                                                <ul className="gethelpul ">
                                                    <li className="help-text1 text-left">{value.title}</li>
                                                    <div className="text-left textfit">
                                                        {value.description}
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                            </div>
                             <div class="modal fade" id="replyModalmb" tabindex="-1" role="dialog" aria-labelledby="replyModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered " role="document">
              <div class="modal-content">
                <div class="modal-body pb-5" >
                  <div style={{width:"100%", textAlign:"center"}}>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                    </button>
                    <p style={{ fontWeight:"500"}}>{querydate && querydate.add_date_time}</p>
                    
                  </div>
                  <div>
                    <p style={{margin:"0", padding:"0px", color:"#008c53", fontWeight:"600", fontSize:"18px",textAlign:"left"}} className="text-truncate">{querydate && querydate.title}</p>
                    <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"400",textAlign:"left"}} className="text-truncate">{querydate && querydate.description}</p>
                  </div>
                  <hr></hr>
                  {querydata && querydata.map((value)=>{
                    return (
                      <>
                        <div style={{display:"flex"}} className="">
                          <div style={{width:"70%"}}>
                            <p style={{margin:"0", padding:"0px", fontSize:"16px", fontWeight:"500",textAlign:"left"}} className="text-truncate">{value.reply_by}</p>
                            <p style={{margin:"0", padding:"0px", fontSize:"14px", fontWeight:"400",textAlign:"left"}} className="text-truncate">{value.reply}</p>
                          </div>
                          <div style={{textAlign:"right", width:"30%"}}>
                            <p style={{color:"#008c53", fontSize:"14px"}}>{value.add_date_time}</p>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  })}
                  <div style={{marginTop:"15px"}}>
                    <input style={{ width: "80%", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgba(0,140,83)" }} maxLength="150" placeholder="Type here..." onChange={getreply} />
                    <button className="mt-2" onClick={() => {postQueryReply()}} style={{ backgroundColor: "rgba(0,140,83)", color: "white", border: "none", padding: "5px 10px", marginLeft: "10px" }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
                        </>


                    ) : show1 ? (
                        <div>
                            {/* Videosss */}

                            <div className="container">
                                <div className="row mt-3">
                                    {getyoutube && getyoutube.map((value) => {
                                        return (
                                            <>
                                                <div className="col-6">
                                                    {/* <div> */}
                                                        <img
                                                            onClick={() => { setForVideoUrl(value.y_id); }}
                                                            src={value.thumbnail}
                                                            width={"100%"}
                                                            height={"100%"}
                                                            style={{borderRadius:"10px"}}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalLong4"
                                                        />
                                                        <div
                                                            className="modal fade"
                                                            id="exampleModalLong4"
                                                            tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLongTitle"
                                                            aria-hidden="true"
                                                        >
                                                            <div
                                                                className="modal-dialog-centered modal-dialog"
                                                                role="document"
                                                            >
                                                                <div className="modal-content">
                                                                <div className="modal-body ">
                                                            <div class="modal-dialog" style={{height:"200px",boxSizing:"border-box"}}>
                                                                <ReactPlayer width={"100%"} height={"100%"} url={`https://www.youtube.com/watch?v=${forVideoUrl}`} playing />
                                                            </div>
                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    {/* </div> */}
                                                    <div className="text-center mt-2">Add QR code</div>
                                                </div>

                                            </>
                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="mt-3 ml-2 mr-2">
                                {/* accordion or collapse */}
                                {faqQuestions && faqQuestions.map((value) => {
                                    return (
                                        <>
                                            <CAccordion activeItemKey={0}>
                                                <CAccordionItem itemKey={1} className="shadow-sm">
                                                    <CAccordionHeader>{value.question}</CAccordionHeader>
                                                    <CAccordionBody style={{ padding: "5%" }}>
                                                        {value.answer}
                                                    </CAccordionBody>
                                                </CAccordionItem>





                                            </CAccordion>

                                        </>
                                    )
                                })}


                            </div>
                        </div>
                    )}
                    {/* <div>
            {show2 ? (
             <div>a</div>
            ) : (
              <div>
                {show1 ? (
                 <div>b</div>
                ) : (
                  <div>c</div>
                )}
              </div>
            )}
          </div> */}
                </div>
            </div>
        </div>
    );
}
export default GetHelp