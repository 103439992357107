import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Headerformobile2 from "../Header/Headerformobile2";
import "./Billdetails.css";
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { Document, Page, pdfjs } from "react-pdf";
import Header from "../Header/Header";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Billdetails = ({ billId }) => {

        const navigate = useNavigate();
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [billDetail, setBillDetail] = useState(null);
        const [billItem, setBillItem] = useState([]);
        const [cash, setCash] = useState(false);
        const [card, setCard] = useState(false);
        const [online, setOnline] = useState(false);
        const [cheque, setCheque] = useState(false);
        const [udhaar, setUdhaar] = useState(false);
        const [paymentMode, setPaymentMode] = useState([]);
        const [paymentModeAmt, setPaymentModeAmt] = useState(null);
        const [cashAmount, setCashAmount] = useState(null);
        const [cardAmount, setCardAmount] = useState(null);
        const [onlineAmount, setOnlineAmount] = useState(null);
        const [chequeAmount, setChequeAmount] = useState(null);
        const [getPdf, setGetPdf] = useState(null);

        useEffect(() => {
                if(billId && billId != null) {
                        BillingDetailsApi();
                } else {
                        navigate(-1)
                }
        }, [billId])

        const BillingDetailsApi = () => {
                const getBillListUrl = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_bill_details/${billId}?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getBillListUrl)
                        .then((response) => {            
                                if (response.data.code === 200) {
                                        setGetPdf(Helper.encypt_decrypt(response.data.data).bill_pdf) 
                                        setBillDetail(Helper.encypt_decrypt(response.data.data))   
                                        setBillItem(Helper.encypt_decrypt(response.data.data).billItems)
                                        setPaymentMode(Helper.encypt_decrypt(response.data.data).paymentMode)
                                        setPaymentModeAmt(Helper.encypt_decrypt(response.data.data).pay_mode_amount)
                                } else if (response.data.code === 401) {
                                  removeCookie("logged_in");
                                  // removeCookies("token")
                                  setTimeout(() => {
                                    navigate("/");
                                    localStorage.setItem("isAuthenticated", false);
                                    localStorage.removeItem("isAuthenticated");
                                  }, 1000);
                                }
                                
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        useEffect(() => {
                const disablePinchZoom = (e) => {
                        if (e.touches.length > 1) {
                                e.preventDefault()
                        }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                        document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        useEffect(() => {
                paymentMode && paymentMode.map((value) => {
                        if (value === 1) {
                                setCash(true)
                        } else if (value === 2) {
                                setUdhaar(true)
                        } else if (value === 3) {
                                setCard(true)
                        } else if (value === 4) {
                                setOnline(true)
                        } else if (value === 5) {
                                setCheque(true)
                        }
                })
        }, [paymentMode])

        // payment mode amount
        useEffect(() => {
                paymentModeAmt && paymentModeAmt.map((value, index) => {
                        if(paymentMode[index] === 1) {
                                setCashAmount(value)
                        } else if(paymentMode[index] === 3) {
                                setCardAmount(value)
                        } else if(paymentMode[index] === 4) {
                                setOnlineAmount(value)
                        } else if(paymentMode[index] === 5) {
                                setChequeAmount(value)
                        }
                })
        }, [paymentModeAmt])

        return (
                <>
                        <Headerformobile2 pagename="Bill Details" navigate={navigate} />
                        <Header />
                        <div className="mainfulldiv" >
                                <div className="row no-gutters  fullheight p-1">
                                        <div className="col-12 mt-3">
                                                <img className="rcorners1 m-auto" src={billDetail && billDetail.c_logo_url}
                                                        onError={(e) =>
                                                            (e.target.onerror = null)(
                                                                (e.target.src = require('../Assets/Images/log.png'))
                                                            )}
                                                            
                                                            />
                                        </div>
                                        <div className="col-12  mt-2">
                                                <p className="namae1bill text-center  m-0 p-0">{billDetail && billDetail.shopName}</p>
                                        </div>
                                        <div className="col-12  mt-1">
                                                <p className="namae1bill text-center  m-0 p-0">{billDetail && billDetail.shopMobNumber}</p>
                                        </div>
                                        <div className="col-12 mt-1">
                                                <p className="namae1bill text-center  m-0 p-0">{billDetail && billDetail.address}</p>
                                        </div>
                                        <div className="col-12 mt-2">
                                                <div className="row no-gutters justify-content-between borderfoedate border-left-0 border-right-0 p-1 ">
                                                        <div className="col-5">
                                                                <p className="namae1bill m-0 p-0 text-left">{billDetail && billDetail.dateTime}</p>
                                                        </div>
                                                        <div className="col-5">
                                                                <p className="namae1bill m-0 p-0 text-right">{billDetail && billDetail.dateTime}</p>
                                                        </div>
                                                </div>
                                        </div>
                                        
                                        <div className="col-12 p-0 m-0">
                                                <div className="row no-gutters justify-content-around borderfoedate border-top-0 border-left-0 border-right-0 pt-2 pb-2" style={{ padding: "5%" }}>
                                                        <div className="col-1 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">SR</h4>
                                                        </div>
                                                        <div className="col-3 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">Name</h4>
                                                        </div>
                                                        <div className="col-2 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">Qty</h4>
                                                        </div>
                                                        <div className="col-3 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">Price</h4>
                                                        </div>
                                                        <div className="col-2 m-0 p-0 ">
                                                                <div className="row no-gutters d-flex justify-content-between flex-column">
                                                                        <h4 className="namae1billh m-0 p-0">Amount</h4>
                                                                </div>
                                                        </div>
                                                </div>
                                                {billItem && billItem.map((value, index) => {
                                                        return (
                                                                <div className="row no-gutters justify-content-around borderfoedate border-top-0 border-left-0 border-right-0 pt-2 pb-2" style={{ padding: "5%" }}>
                                                                        <div className="col-1 m-0 p-0 ">
                                                                                <p className="namae1bill m-0 p-0">{index + 1}</p>
                                                                        </div>
                                                                        <div className="col-3 m-0 p-0 ">
                                                                                <p className="namae1bill m-0 p-0">{value.itemName}</p>
                                                                        </div>
                                                                        <div className="col-2 m-0 p-0 ">
                                                                                <p className="namae1bill m-0 p-0">{value.itemQty}</p>
                                                                        </div>
                                                                        <div className="col-3 m-0 p-0 ">
                                                                                <p className="namae1bill m-0 p-0">Dis ({parseFloat(value.itemDiscount).toFixed(2)})<br/>Gst ({value.gst}%)<br></br>Amt (₹{parseFloat(value.rate).toFixed(2)})</p>
                                                                        </div>
                                                                        <div className="col-2 m-0 p-0 ">
                                                                                <div className="row no-gutters d-flex justify-content-between flex-column">
                                                                                        <p className="namae1bill m-0 p-0 font-weight-bold"> ₹{parseFloat(value.itemQty * value.rate).toFixed(2)}</p>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        )
                                                })}
                                        </div>

                                        <div className="col-12 pt-1 m-0">
                                                <div className="row no-gutters justify-content-around borderfoedate border-top-0 border-left-0 border-right-0">
                                                        <div className="col-1 m-0 p-0 ">
                                                        </div>
                                                        <div className="col-3 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">Total</h4>
                                                        </div>
                                                        <div className="col-3 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">{billDetail && billDetail.taxableAmount}%<br></br>Tax Details</h4>
                                                        </div>
                                                        <div className="col-2 m-0 p-0 ">
                                                                <h4 className="namae1billh m-0 p-0">₹{billDetail && billDetail.totalInvoice}</h4>
                                                        </div>
                                                        <div className="col-12 m-0 p-1 mt-5">
                                                                <div className="row m-0 p-0 justify-content-between">
                                                                        <div className="col m-0 p-0">
                                                                                <h4 className="namae1bill m-0 p-0 text-left">Refund Amount: </h4>
                                                                        </div>
                                                                        <div className="col m-0 p-0">
                                                                                <h4 className="namae1bill m-0 p-0 text-right">₹{billDetail && parseFloat(billDetail.refundAmount).toFixed(2)}</h4>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="col-12">
                                                <div className="row no-gutters justify-content-between borderfoedate  mt-2 brad " style={{ paddingRight: "10px" }}>
                                                        <div className="col-12">
                                                                <p className="namae1bill m-0 p-0 text-left pl-1 m-2">Payment Mode</p>
                                                        </div>
                                                        <div className="col-2">
                                                                <input id="radio-2" type="checkbox" className="d-block m-auto" checked={cash ? "check" : ""} />
                                                                <label for="radio-2" class="radio-label namae1bill" >CASH</label>
                                                                <p>{cashAmount && cashAmount != null ? "₹" : null} {cashAmount && cashAmount}</p>
                                                        </div>
                                                        <div className="col-2">
                                                                <input id="radio-2" type="checkbox" className="d-block m-auto" checked={card ? "check" : ""} />
                                                                <label for="radio-2" class="radio-label namae1bill">CARD</label>
                                                                <p>{cardAmount && cardAmount != null ? "₹" : null} {cardAmount && cardAmount}</p>
                                                        </div>
                                                        <div className="col-2">
                                                                <input id="radio-3" type="checkbox" className="d-block m-auto" checked={online ? "check" : ""} />
                                                                <label for="radio-3" class="radio-label namae1bill">ONLINE</label>
                                                                <p>{onlineAmount && onlineAmount != null ? "₹" : null} {onlineAmount && onlineAmount}</p>
                                                        </div>
                                                        <div className="col-2">
                                                                <input id="radio-4" type="checkbox" className="d-block m-auto" checked={udhaar ? "check" : ""} />
                                                                <label for="radio-4" class="radio-label namae1bill">UDHAAR</label>
                                                                <p>{billDetail && billDetail.udhar_amount != 0 ? "₹" : null} {billDetail && billDetail.udhar_amount != 0 ? billDetail.udhar_amount : null}</p>
                                                        </div>
                                                        <div className="col-2">
                                                                <input id="radio-4" type="checkbox" className="d-block m-auto" checked={cheque ? "check" : ""} />
                                                                <label for="radio-4" class="radio-label namae1bill">CHEQUE</label>
                                                                <p>{chequeAmount && chequeAmount != null ? "₹" : null} {chequeAmount && chequeAmount}</p>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="col-12 mt-2 pl-1">
                                                <p className="namae1bill text-left  m-0 p-0">Mobile No. :{billDetail && billDetail.customerMobile}</p>
                                        </div>
                                        <div className="col-12 pl-1">
                                                <p className="namae1bill text-left  m-0 p-0">To : {billDetail && billDetail.customerName}</p>
                                        </div>
                                        <div className="col-12 pl-1">
                                                <p className="namae1bill text-left  m-0 p-0">From :{billDetail && billDetail.shopName}</p>
                                        </div>
                                        <div className="col-12 pl-1">
                                                <p className="namae1bill text-left  m-0 p-0">Bill No. :{billDetail && billDetail.billNo}</p>
                                        </div>
                                        <div className="col-12 mb2355 pl-1">
                                                <p className="namae1bill text-left  m-0 p-0 ">Transaction id :{billDetail && billDetail.payment_number}</p>
                                        </div>
                                </div>
                        </div>
                </>
        )
}

export default Billdetails