import React, { useState, useEffect } from 'react';
import Headerformobile2 from '../Header/Headerformobile2';
import { Link, useNavigate } from 'react-router-dom';
import './Udharshop.css';
import Helper from '../util/Helper';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from '../Header/Header';

const Udharshop = ({ setBillId, udhaarBillId }) => {
        
        const navigate = useNavigate()
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [udhaarShopDetail, setUdhaarShopDetail] = useState([]);
        const [udhaarBillDetail, setUdhaarBillDetail] = useState([]);
        const [lazyLoading, setLazyLoading] = useState(true);

        useEffect(() => {
                if(udhaarBillId && udhaarBillId != null) {
                        udhaarShopdetailApi()
                } else {
                        navigate('/udhaar')
                }
        }, [udhaarBillId])

        const udhaarShopdetailApi = () => {
                const getUdhaarShopDetail = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_shop_credit/${udhaarBillId}/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getUdhaarShopDetail)
                        .then((response) => {      
                                if (response.data.code === 200) {
                                        setUdhaarShopDetail(Helper.encypt_decrypt(response.data.data))
                                        setUdhaarBillDetail(Helper.encypt_decrypt(response.data.data).sales)
                                        setLazyLoading(false)          
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        navigate(`/`)
                                } else if(response.data.code === 204) {
                                        setLazyLoading(false)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        useEffect(() => {
                const disablePinchZoom = (e) => {
                        if (e.touches.length > 1) {
                                e.preventDefault()
                        }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                        document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        return (
                <>
                        <Headerformobile2 pagename="Udhaar" navigate={navigate} />
                        <Header />
                        <div className='maindivudharshop   m-2'>
                                <div className='container mtbus'>
                                        <div className='row '>
                                                <div className='col-8 '>
                                                        <div className="breacrumbmainclass123 d-none d-lg-block">
                                                                <ol className="breadcrumb bg-white p-0 m-0">
                                                                        <li className="breadcrumb-item "><Link to="/dashboard" className=' textcolor7a112 text-decoration-none ' >Home</Link></li>
                                                                        <li className="breadcrumb-item "><Link to="/udhaar" className=' textcolor7a112 text-decoration-none ' >Udhaar</Link></li>
                                                                </ol>
                                                        </div>
                                                </div>
                                                <div className='col-4 '>
                                                        <div className='row widthoftherow1  p-3 justify-content-between'>
                                                                <div className='col-6 p-0'>
                                                                        <p className='totaludhaar text-left'>Total Udhaar :</p>
                                                                </div>
                                                                <div className='col-6 amtotalofudhar p-0 text-right'>
                                                                        <p style={{whiteSpace:"nowrap"}}>₹ <span className=''>{udhaarShopDetail && parseFloat(udhaarShopDetail.Total_amount).toFixed(2)}</span></p>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        {/* SHOPE IMAGE ROW */}
                                        <div className='row mt-3'>
                                                <div className='col-lg-7 pr-0'>
                                                        <div className='row bill_box marg121 justify-content-around shadow'>
                                                                <div className='col-2  p-0 d-flex justify-content-center align-items-center'>
                                                                        <img src={udhaarShopDetail && udhaarShopDetail.c_logo_url} className='hwimageofudhaar13 w-100 h-100' alt="image not found" onError={(e) =>
                                                                                (e.target.onerror = null)(
                                                                                        (e.target.src = require('../Assets/Images/log.png'))
                                                                                )
                                                                        }></img>
                                                                </div>
                                                                <div className='col-9 paddingweb'>
                                                                        <div className='row  '>
                                                                                <div className='col-12 marg1'>
                                                                                        <div className='row justify-content-between'>
                                                                                                <div className=' p-0'>
                                                                                                        <p className='text-left bill_shopnameudhhar'>{udhaarShopDetail && udhaarShopDetail.shop_name}</p>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                                <div className='col-12 marg2'>
                                                                                        <div className='row justify-content-between'>
                                                                                                <div className='col-5 p-0'>
                                                                                                        <p className='text-left sadr1 text-truncate text-capitalize'>
                                                                                                                {(udhaarShopDetail.address=="" || udhaarShopDetail.address==null) ?
                                                                                                                        "Address: N/A"
                                                                                                                :
                                                                                                                        udhaarShopDetail.address
                                                                                                                }                                                                                                                
                                                                                                        </p>
                                                                                                </div>
                                                                                                <div className='col-5 p-0'>
                                                                                                        <p className='text-right amts1'>Amt: ₹ 
                                                                                                                <span className='am'>{udhaarShopDetail && parseFloat(udhaarShopDetail.Total_amount).toFixed(2)}</span>
                                                                                                        </p>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        {/* SHOPE IMAGE ROW */}
                                        {/* newxcards */}
                                        {lazyLoading ? 
                                                <Skeleton height={"125px"} style={{ marginRight: "250px" }} width={"70%"} count={4} ></Skeleton> 
                                        :
                                                <>
                                                        {udhaarBillDetail && udhaarBillDetail.map((value) => {
                                                                return (
                                                                        <>
                                                                                <div className='row p-3  mt-2 '>
                                                                                        <div className='col-7 bordershadow123 p-2 shadow'>
                                                                                                <div className='row'>
                                                                                                        <div className='col-11 ml-2'>
                                                                                                                <p className='billn text-left'>{value.bill_no}</p>
                                                                                                        </div>
                                                                                                        <div className='col-11 ml-2'>
                                                                                                                <div className='row justify-content-between'>
                                                                                                                        <div className='col-9 text-left  billtd'>
                                                                                                                                <p>{value.date_time}</p>
                                                                                                                        </div>
                                                                                                                        <div className='col-2 text-right  biilltot p-0'>
                                                                                                                                <p>₹ <span className=''>{parseFloat(value.amount).toFixed(2)}</span></p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                        <div className='col-1 btnbox ' >
                                                                                                <div>
                                                                                                        <button onClick={() => { setBillId(value.bill_id); navigate('/billdetail'); }} className='bill_viewbtn98 center font-weight-bold text-white' >View
                                                                                                                <img src={require('../Assets/Images/Right.png')} style={{ position: "absolute", top: "12px", right: "14px", height: "15px", width: "15px" }} />
                                                                                                        </button>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>

                                                                        </>
                                                                )
                                                        })}
                                                </>
                                        }
                                </div>
                        </div>

                        {/* mobilwviewof udhaar */}
                        <div className='mobileudhaarshopconainer pl-2 pr-2'>
                                <div className=' '>
                                        <div className='row  mt-3 mb-3 ml-1 mr-1'>
                                                <div className='col-12 '>
                                                        <div className='row   firstcardconainer p-2'>
                                                                <div className='col-3  p-0 text-start d-flex'>
                                                                        <img src={udhaarShopDetail && udhaarShopDetail.c_logo_url} className='shopimagemobilehw w-100'
                                                                                onError={(e) =>
                                                                                        (e.target.onerror = null)(
                                                                                                (e.target.src = require('../Assets/Images/log.png'))
                                                                                        )
                                                                                }></img>
                                                                </div>
                                                                <div className='col-9  p-1'>
                                                                        <div className='row'>
                                                                                <div className="col-12 ">
                                                                                        <p className='text-left bill_shopnameudhhar18 text-truncate text-capitalize pl-1'>{(udhaarShopDetail && udhaarShopDetail.shop_name === "" ? "Shop Name" : udhaarShopDetail && udhaarShopDetail.shop_name)}</p>
                                                                                </div>
                                                                                <div className="col-12 ">
                                                                                        <p className='text-left bill_shopnameudhhar182 text-capitalize pl-1'>
                                                                                                {(udhaarShopDetail.address=="" || udhaarShopDetail.address=="") ?
                                                                                                        "Address : N/A"
                                                                                                :
                                                                                                        udhaarShopDetail.address
                                                                                                }
                                                                                        </p>
                                                                                </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                                <div className="col-12 ">
                                                                                        <p className='text-right bill_shopnameudhhar184'>
                                                                                                Total Udhaar : ₹ <span className=''>{udhaarShopDetail && parseFloat(udhaarShopDetail.Total_amount).toFixed(2)}</span>
                                                                                        </p>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        {/* firstbill */}
                                        {lazyLoading ? 
                                                <Skeleton height={"125px"} style={{ marginRight: "250px" }} width={"100%"} count={4} ></Skeleton> 
                                        :
                                                <>
                                                        {udhaarBillDetail && udhaarBillDetail.map((value) => {
                                                                return (
                                                                        <>
                                                                                <div className='row no-gutters mt-2  position-relative margintopofcardatudhaarshop' style={{ zIndex: "1px" }}>
                                                                                        <div className='border w-100  divinnerusdhaarmobile p-2 ' style={{ zIndex: "1" }}>
                                                                                                <div className='row'>
                                                                                                        <div className='col-12'>
                                                                                                                <p className='billnum'>Bill No. :- {value.bill_no}</p>
                                                                                                        </div>
                                                                                                        <div className='col-12'>
                                                                                                                <div className='row justify-content-between'>
                                                                                                                        <div className='col-6 pr-0'>
                                                                                                                                <p className='tanddatmobilesho'>{value.date_time}</p>
                                                                                                                        </div>
                                                                                                                        <div className='col-6 '>
                                                                                                                                <p className='tanddatmobilesho2'>Amt: ₹{parseFloat(value.amount).toFixed(2)}</p>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                        <button onClick={() => { setBillId(value.bill_id); navigate('/billdetail'); }} className='border w-100 pt-3  position-absolute greenbutton' style={{ top: "45px", zIndex: "0" }}>
                                                                                                <div className='row justify-content-between'>
                                                                                                        <div className='col-4'>
                                                                                                                <p className='viewbillmobile33 text-left pl-2'>View Bill</p>
                                                                                                        </div>
                                                                                                        <div className='col-3 circlealign'>
                                                                                                                <img src={require('../Assets/Images/Rightcircle.png')} className='rightcircle'></img>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </button>
                                                                                </div>
                                                                        </>
                                                                )
                                                        })}
                                                </>
                                        }
                                </div>
                        </div>
                </>
        )
}
export default Udharshop;