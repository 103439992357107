import React, { Component, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import "./Dashboard.css";
import Header from '../Header/Header';
import Mobileheader from '../Header/Mobileheader';
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Geocode from "react-geocode";

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();

// watchPosition
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dashboard = ({setlogo_shop, setRunEffect, runEffect, setDenyLocation, denyLocation, specificLongitude, specificLatitude, shoprodctpage, setKeyName, keyName, setlng, setlat, setividforproductpage, lat, lng, globalLongitude, globalLatitude, setIcidFrmIcToShop }) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token'],['km'],['category']);

    const [forb2b, setb2b] = useState(false);
    const [b2cstate, serb2cstate] = useState(true);
    const [bannerimage, setbannerimage] = useState('')
    const [userlatitude, setuserlatitude] = useState(null);
    const [userlongitude, setuserlongitude] = useState(null);
    const [shoplist, setshoplist] = useState([])
    const [shoplist2, setshoplist2] = useState([])
    const [dash_shoplength, setdash_shoplength] = useState(null)
    const [shopaddlist, setshopaddlist] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [getIsTrusted, setGetIsTrusted] = useState(false)
    const [lazyLoading, setLazyLoading] = useState(true);
    const [callDashboardFunc, setCallDashboardFunc] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [minHeight, setMinHeight] = useState(false);
    
    const google = window.google;
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setIcidFrmIcToShop(null)

        const requestOptions3 = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/settings/get_customer_banner_value/`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
        };
        axios(requestOptions3)
            .then((response) => {
                setbannerimage(response.data.data.image)
                if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (globalLatitude && globalLatitude != null && globalLongitude && globalLongitude != null) {
            setuserlatitude(globalLatitude)
            setuserlongitude(globalLongitude)
            setlat(globalLatitude)
            setlng(globalLongitude)
            setCallDashboardFunc(true)
        } else {
            if (specificLongitude && specificLongitude != null && specificLatitude && specificLatitude != null) {
                setuserlatitude(specificLatitude)
                setuserlongitude(specificLongitude)
                setlat(specificLatitude)
                setlng(specificLongitude)
                setCallDashboardFunc(true)
            } else {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setuserlatitude(position.coords.latitude)
                    setuserlongitude(position.coords.longitude)
                    setlat(position.coords.latitude)
                    setlng(position.coords.longitude)

                });
                setCallDashboardFunc(true)
            }
        }
    }, [])

    useEffect(() => {
        if (userlatitude != null) {
            setDenyLocation(true)
        } else {
            setDenyLocation(false)
        }
    }, [userlatitude])

    useEffect(() => {
        if (keyName && keyName != null) {
            setshoplist([])
        }
        if (callDashboardFunc) {
            dashboarddataload(userlatitude, userlongitude);
        }
    }, [keyName, userlatitude, userlongitude, callDashboardFunc])

    const getReverseGeocodingData = (lat, long) => {
        Geocode.fromLatLng(lat, long).then(
            (response) => {
                const address = response.results[0].formatted_address;
                localStorage.setItem("Address", address)
            },
            (error) => {
                console.error("error : ", error);
            }
        );
    }

    useEffect(() => {
        getReverseGeocodingData(userlatitude, userlongitude)
    }, [userlatitude, userlongitude])

    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            setGetIsTrusted(event.isTrusted)
        })
    }, [])

    useEffect(() => {
        if (getIsTrusted) {
            setPageNo(pageNo + 1)
            dashboarddataload(userlatitude, userlongitude)
        }
    }, [getIsTrusted])

    useEffect(() => {
        if (shopaddlist && shopaddlist.length > 0) {
            setGetIsTrusted(false)
            shopaddlist.map((value, index) => {
                if (!shoplist.find((element) => value.contact_id === element.contact_id)) {
                    setshoplist((prevState) => [
                        ...prevState, {
                            address: value.address,
                            alert: value.alert,
                            b2b: value.b2b,
                            city_text: value.city_text,
                            contact_id: value.contact_id,
                            name: value.name,
                            have_offer: value.have_offer,
                            is_favorite: value.is_favorite,
                            distance_difference: value.distance_difference,
                            km: value.km,
                            lat: value.lat,
                            logo: value.logo,
                            long: value.long,
                            master_category: value.master_category,
                            mobile_number: value.mobile_number,
                            p_id: value.p_id,
                            picode_text: value.picode_text,
                            shop_logo: value.shop_logo,
                            state_text: value.state_text,
                            favShop: value.is_favorite === 0 ? false : true
                        }
                    ])
                }
            })
        }
    }, [shopaddlist])

    function dashboarddataload(userlatitude, userlongitude) {
        const requestOptions5 = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `${keyName && keyName != null ? `/contact/search_shop/?name=${keyName}&page=1&no_of_record=20&web=1` : `/contact/search_shop/?page=${pageNo}&no_of_record=20&web=1`}`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                km: 15.0,
                lat: userlatitude != null ? userlatitude && userlatitude : "28.6046254",
                lng: userlongitude != null ? userlongitude && userlongitude : "77.3501306",
            }
        };
        axios(requestOptions5)
            .then((response) => {
                if (response.data.code === 200) {
                    // console.log("dashboard detail",Helper.encypt_decrypt(response.data.data));
                    setshopaddlist(Helper.encypt_decrypt(response.data.data));
                    setdash_shoplength(Helper.encypt_decrypt(response.data.data).length)
                    shoprodctpage(false);
                    setLazyLoading(false)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                } else if (response.data.code === 204) {
                    setLazyLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })

        // const requestOptions52 = {
        //     method: "POST",
        //     url: Helper.getWsUrl(cookies.skip) + `/contact/search_shop/?page=${pageNo}&no_of_record=10&b2b=1&web=1`,
        //     headers: { "Content-Type": "application/json", "token": cookies.token },
        //     data: {
        //         km: 15.0,
        //         lat: userlatitude != null ? userlatitude : "28.6046254",
        //         lng: userlongitude != null ? userlongitude : "77.3501306",
        //     }
        // };
        // axios(requestOptions52)
        //     .then((response) => {
        //         if (response.data.code === 200) {
        //             setshoplist2(Helper.encypt_decrypt(response.data.data));
        //         } else if (response.data.code === 401) {
        //             removeCookie("logged_in");
        //             setTimeout(() => {
        //                 navigate("/");
        //                 localStorage.setItem("isAuthenticated", false);
        //                 localStorage.removeItem("isAuthenticated");
        //             }, 1000);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
    }

    function addfavshop(shpid, index) {
        const requestOptions528 = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `/contact/add_favorite_shop/?web=1`,
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                "shop_id": shpid,
            }
        };
        axios(requestOptions528)
            .then((response) => {
                if (response.data.code == 201) {
                    let filterTableArray = [...shoplist]
                    let indexOfEditQty = index
                    let indexOfArray
                    let filtrTblArrElement = filterTableArray.filter((item, index) => {
                        if (index == indexOfEditQty) {
                            indexOfArray = index
                            return item
                        }
                    })
                    filtrTblArrElement[0].favShop = true
                    filterTableArray[indexOfArray] = filtrTblArrElement[0]
                    setshoplist(filterTableArray)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function removefavshop(shpid, index) {
        const requestOptions528 = {
            method: "DELETE",
            url: Helper.getWsUrl(cookies.skip) + '/contact/remove_favorite_shop/?web=1',
            headers: { "Content-Type": "application/json", "token": cookies.token },
            data: {
                "shop_id": shpid,
            }
        };
        axios(requestOptions528)
            .then((response) => {
                if (response.data.code === 202) {
                    let filterTableArray = [...shoplist]
                    let indexOfEditQty = index
                    let indexOfArray
                    let filtrTblArrElement = filterTableArray.filter((item, index) => {
                        if (index == indexOfEditQty) {
                            indexOfArray = index
                            return item
                        }
                    })
                    filtrTblArrElement[0].favShop = false
                    filterTableArray[indexOfArray] = filtrTblArrElement[0]
                    setshoplist(filterTableArray)
                } else if (response.data.code === 401) {
                    removeCookie("logged_in");
                    setTimeout(() => {
                        navigate("/");
                        localStorage.setItem("isAuthenticated", false);
                        localStorage.removeItem("isAuthenticated");
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    
    useEffect(() => {
        if (window.innerHeight > 700) {
            setMinHeight(true)
        }
    }, [])

    const routeShopFunc = (contactId) => {
        setKeyName(null)
        localStorage.setItem("contact_id", contactId);

        const getBillListUrl = {
            method: "GET",
            url: Helper.getWsUrl(cookies.skip) + `/items/get_selected_item_category_new/${contactId}/?web=1`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
            .then((response) => {
                if (response.data.code === 200) {
                    if(Helper.encypt_decrypt(response.data.data).length < 4) {
                        navigate('/shop')
                    } else {
                        navigate('/shop/itemCategory')
                    }
                } else if (response.data.code === 401) {
                    removeCookie("logged_in")
                    removeCookie("token")
                    setTimeout(() => {
                            navigate('/')
                            localStorage.setItem("isAuthenticated", false)
                            localStorage.removeItem("isAuthenticated")
                    }, 1000)
                } else {
                    navigate('/shop')
                }
            })
            .catch((error) => {
                    alert(error);
            })
    }
    
    return (
        <>
            <Dialog PaperProps={{
                    style: {
                        position: 'absolute',
                        bottom: '0',
                        color: 'black',
                        width: '100%',
                        height: `${minHeight ? "25%" : "41%"}`,
                        maxWidth: '100%',
                        padding: "3%"
                    },
                }}
                open={open} onClose={handleClose} TransitionComponent={Transition} >
                <Toolbar>
                    <div className='mainLoginBox'>
                        <div className='LoginBoxTitleWrapper'>
                            <p className='LoginBoxTitle m-0 p-0' >Login</p>
                        </div>
                        <div className='LoginBoxParaWrapper'>
                            <p className='loginBoxPara m-0 p-0'>To add items to your favourites you need to login.</p>
                        </div>
                        <div className='loginBoxButtonWrapper'>
                            <button className='loginBoxButton' onClick={() => { navigate("/") }}>
                                Continue to login
                            </button>
                        </div>
                    </div>

                </Toolbar>
            </Dialog>
            
            <Mobileheader setRunEffect={setRunEffect} runEffect={runEffect} denyLocation={denyLocation} setDenyLocation={setDenyLocation} shoprodctpage={shoprodctpage} setividforproductpage={setividforproductpage} keyName={keyName} setKeyName={setKeyName} serb2cstate={serb2cstate} b2cstate={b2cstate} />
            <Header denyLocation={denyLocation} setDenyLocation={setDenyLocation} lat={lat} lng={lng} shoprodctpage={shoprodctpage} setividforproductpage={setividforproductpage} shoplist={shoplist} setshoplist={setshoplist} keyName={keyName} setKeyName={setKeyName} setb2b={setb2b}></Header>
            
            <div className=" carouselwebonly " >
                <div className="row justify-content-center no-gutters">
                    <div className="col-lg-7 col-md-7 col-sm-8 p-0  ">
                        <Carousel className='cardashboard' autoPlay={true} infiniteLoop={true} interval={3000} >
                            <div>
                                <img src={bannerimage.image1} className='hwimgdashboard' />
                            </div>
                            <div>
                                <img src={bannerimage.image2} className='hwimgdashboard' />
                            </div>
                            <div>
                                <img src={bannerimage.image3} className='hwimgdashboard' />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>

            {/* webcards */}
            <div className='dashboardcardsforwebview'>
                {lazyLoading ?
                    <Skeleton height={"125px"} width={"100%"} count={4} ></Skeleton>
                :
                    <>
                        {(dash_shoplength == 0 || dash_shoplength == null) ?
                            <div className=' mobileviewforudhaar  pl-2 pr-2 d-lg-block d-none'>
                                <div className='emptystateset d-flex justify-content-center align-items-center flex-column h-100'>
                                    <img src={require('../Assets/Images/no results.svg').default} className='imhgemptycart' />
                                    <p className='paraforempty2 mt-3'>Oops! there are no shops in your area.</p>
                                </div>
                            </div>
                        :
                            <div className='webCardContainer' >
                                {forb2b ?
                                    <>
                                        {shoplist2 && shoplist2.map((val22, index) => {
                                            return (
                                                <div className="fullcarddashboardhw shadow  " onClick={()=>{setCookie('km',val22.distance_difference); setCookie('category',val22.master_category.length != 0 ? val22.master_category : "N/A")}} >
                                                    <Link onClick={() => { setKeyName(null); routeShopFunc(val22.contact_id) }} >
                                                        <img className="fullcarddashboardhwimg " src={(val22.shop_logo)} onClick={() => { localStorage.setItem("contact_id", val22.contact_id);}} alt="Card image cap"
                                                            onError={(e) =>
                                                                (e.target.onerror = null)(
                                                                    (e.target.src = require('../Assets/Images/log.png'))
                                                                )} />
                                                    </Link>
                                                    <div className=' maindivforheart3dashboard ' >
                                                        <>
                                                            {cookies.skip != 0 ?
                                                                <>
                                                                    {val22.favShop === false ?
                                                                        <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23dashboard' onClick={() => { addfavshop(val22.contact_id, index); }} />
                                                                    :
                                                                        <img src={require('../Assets/Images/orangeheart.png')} className='whiteimagehw3dashboarddash' alt="no orange heart" onClick={() => { removefavshop(val22.contact_id, index); }} />
                                                                    }
                                                                </>
                                                            :
                                                                <>
                                                                    <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23dashboard' data-bs-toggle="modal" data-bs-target="#Fav" />

                                                                </>
                                                            }
                                                        </>
                                                    </div>
                                                    <div className="card-body ml-n3 ">
                                                        <div className="card-title mb-2">
                                                            <h4 className='fsforyou text-left  text-truncate text-capitalize mb-2'> {val22.name}</h4>
                                                        </div>
                                                        <div className="card-text ml-0">
                                                            <p className='fshopee text-left text-truncate  mb-2 text-capitalize'>{val22.address}</p>
                                                            <p className='fscat text-left text-truncate text-capitalize mb-3'>{val22.master_category.length != 0 ? val22.master_category : "N/A"}</p>
                                                            <p className='distdas text-left mb-2'>{val22.distance_difference} kms away <img src={require('../Assets/Images/dot.png')} className='dotposdas ml-1 mr-1' />{val22.alert === 0 ? "Delivery Available" : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                :
                                    <>
                                        {shoplist && shoplist.map((val2, index) => {
                                            return (
                                                <div className="fullcarddashboardhw  shadow " onClick={()=>{setCookie('km',val2.distance_difference); setCookie('category',val2.master_category.length != 0 ? val2.master_category : "N/A")}}>
                                                    <Link onClick={() => { setKeyName(null); routeShopFunc(val2.contact_id) }}>
                                                        <img className="fullcarddashboardhwimg "
                                                            onClick={() => { setlogo_shop(val2.shop_logo); localStorage.setItem("contact_id", val2.contact_id);}}
                                                            src={val2.shop_logo}
                                                            alt="Card image cap"
                                                            onError={(e) =>
                                                                (e.target.onerror = null)(
                                                                    (e.target.src = require('../Assets/Images/log.png'))
                                                                )}
                                                        />
                                                    </Link>
                                                    <div className=' maindivforheart3dashboard ' >
                                                        <>
                                                            {cookies.skip != 0 ? 
                                                                <> 
                                                                    {val2.favShop === true ?
                                                                        <img src={require('../Assets/Images/orangeheart.png')} className='whiteimagehw3dashboarddash' onClick={() => { removefavshop(val2.contact_id, index); }} />
                                                                    :
                                                                        <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23dashboard' onClick={() => { addfavshop(val2.contact_id, index); }} />
                                                                    }
                                                                </>
                                                            :
                                                                <>
                                                                    <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23dashboard' data-bs-toggle="modal" data-bs-target="#Fav" />
                                                                </>
                                                            }
                                                        </>
                                                    </div>
                                                    <div className="card-body ml-n3   ">
                                                        <div className="card-title mb-2">
                                                            <h4 className='fsforyou text-left text-truncate text-capitalize mb-2'>{val2.name}</h4>
                                                        </div>
                                                        <div className="card-text ml-0 ">
                                                            <p className='fshopee text-left text-truncate mb-2 text-capitalize'>{val2.address}</p>
                                                            <p className='fscat text-left text-truncate '>{val2.master_category.length != 0 ? val2.master_category : "N/A"}</p>
                                                            <p className='distdas text-left mb-2'>{val2.distance_difference} kms away <img src={require('../Assets/Images/dot.png')} className='dotposdas  ml-1 mr-1' />{val2.alert === 0 ? "Delivery Available" : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        }
                    </>
                }
            </div>

            {/* mobile view */}
            <div className=' dasboardcardsformobile '>                
                {lazyLoading ?
                    <>
                        <Skeleton height={"22%"} width={"100%"} style={{marginBottom: "15px"}} count={1} ></Skeleton>
                        <Skeleton height={"20%"} width={"100%"} count={4} ></Skeleton>
                    </>
                :
                    <>
                        {(dash_shoplength == 0 || dash_shoplength == null) ?
                            <div className=' mobileviewforudhaar  pl-2 pr-2'>
                                <div className='emptystateset d-flex justify-content-center align-items-center flex-column h-100'>
                                    <img src={require('../Assets/Images/no results.svg').default} className='imhgemptycart' />
                                    <p className='paraforempty2 mt-3'>Oops! there are no shops in your area.</p>
                                </div>
                            </div>
                        :
                            <div className='mtmdasmob'>
                                <div className="dashboardCarousel"> 
                                    <Carousel className='cardashboard ' autoPlay={true} infiniteLoop={true} interval={3000} >
                                        <div>
                                            <img src={bannerimage.image1} className='hwimgdashboard' />
                                        </div>
                                        <div>
                                            <img src={bannerimage.image2} className='hwimgdashboard' />
                                        </div>
                                        <div>
                                            <img src={bannerimage.image3} className='hwimgdashboard' />
                                        </div>
                                    </Carousel>
                                </div>
                                <div className='cardsContainer' onScroll={(event) => { setGetIsTrusted(event.isTrusted) }}>
                                    {b2cstate ?
                                        <>
                                            {shoplist && shoplist.map((val2345, index) => {
                                                return (
                                                    <div className='fullcarddashboardhw' onClick={()=>{setCookie('km',val2345.distance_difference); setCookie('category',val2345.master_category.length != 0 ? val2345.master_category : "N/A")}}>
                                                        <div className='cardImgDashboard' >
                                                            <Link >
                                                                <img className="fullcarddashboardhwimg" src={val2345.shop_logo} alt="Card image cap"
                                                                    onError={(e) =>
                                                                        (e.target.onerror = null)(
                                                                            (e.target.src = require('../Assets/Images/log.png'))
                                                                        )}
                                                                />
                                                            </Link>
                                                            <div className=' maindivforheart3z1dash ' >
                                                                {cookies.skip != 0 ? 
                                                                    <>
                                                                        {val2345.favShop === false ?
                                                                            <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23z1dash' onClick={() => { addfavshop(val2345.contact_id, index); }} />
                                                                        :
                                                                            <img src={require('../Assets/Images/orangeheart.png')} className='whiteimagehw3z1dash' onClick={() => { removefavshop(val2345.contact_id, index); }} />
                                                                        }
                                                                    </> 
                                                                :
                                                                    <img onClick={handleClickOpen} src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23z1dash' />
                                                                }
                                                            </div>
                                                        </div>
                                                               
                                                        <div className='cardContentDashboard' onClick={() => { routeShopFunc(val2345.contact_id) }}>
                                                            <div className='text-truncate'>
                                                                <h4 className='fsforyou2 text-left text-truncate text-capitalize' >{val2345.name}</h4>
                                                                <h3 style={{fontWeight:'500'}} className='fshopee2 text-left text-truncate mb-0'>+91 {val2345.mobile_number}</h3>
                                                            </div>
                                                            <div className='text-truncate'>
                                                                <p className='fshopee2 text-left text-truncate text-capitalize '> {val2345.address}</p>
                                                            </div>
                                                            <div className=' text-truncate'>
                                                                <p className='fscat2 text-left text-capitalize text-truncate'> {val2345.master_category.length != 0 ? val2345.master_category : "N/A"}</p>
                                                            </div>
                                                            <div className=''>
                                                                <p className='distdas2 text-left'>{val2345.distance_difference} kms away <img src={require('../Assets/Images/dot.png')} className='dotposdas  ml-1 mr-1' />{val2345.alert === 0 ? "Delivery Available" : ""}</p>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                )
                                            })}
                                        </>
                                    :
                                        <>
                                            {shoplist2 && shoplist2.map((val22, index) => {
                                                return (
                                                    <div className='fullcarddashboardhw' onClick={()=>{setCookie('km',val22.distance_difference); setCookie('category',val22.master_category.length != 0 ? val22.master_category : "N/A")}}>
                                                        <div className='cardImgDashboard' >
                                                            <Link >
                                                                <img className="fullcarddashboardhwimg" src={val22.shop_logo} alt="Card image cap"
                                                                    onError={(e) =>
                                                                        (e.target.onerror = null)(
                                                                            (e.target.src = require('../Assets/Images/log.png'))
                                                                        )}
                                                                />
                                                            </Link>
                                                            <div className=' maindivforheart3z1dash ' >
                                                                {cookies.skip != 0 ? 
                                                                    <>
                                                                        {val22.favShop === false ?
                                                                            <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23z1dash' onClick={() => { addfavshop(val22.contact_id, index); }} />
                                                                        :
                                                                            <img src={require('../Assets/Images/orangeheart.png')} className='whiteimagehw3z1dash' onClick={() => { removefavshop(val22.contact_id, index); }} />
                                                                        }
                                                                    </> 
                                                                :
                                                                    <img onClick={handleClickOpen} src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23z1dash' />
                                                                }
                                                            </div>
                                                        </div>
                                                               
                                                        <div className='cardContentDashboard' onClick={() => { routeShopFunc(val22.contact_id) }}>
                                                            <div className='text-truncate'>
                                                                <h4 className='fsforyou2 text-left text-truncate text-capitalize' >{val22.name}</h4>
                                                                <h3 style={{fontWeight:'500'}} className='fshopee2 text-left text-truncate mb-0'>+91 {val22.mobile_number}</h3>
                                                            </div>
                                                            <div className='text-truncate'>
                                                                <p className='fshopee2 fshopee22 text-left text-truncate text-capitalize '> {val22.address}</p>
                                                            </div>
                                                            <div className=' text-truncate'>
                                                                <p className='fscat2 text-left text-capitalize text-truncate'> {val22.master_category.length != 0 ? val22.master_category : "N/A"}</p>
                                                            </div>
                                                            <div className=''>
                                                                <p className='distdas2 text-left'>{val22.distance_difference} kms away <img src={require('../Assets/Images/dot.png')} className='dotposdas  ml-1 mr-1' />{val22.alert === 0 ? "Delivery Available" : ""}</p>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                )
                                            })}
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </>
    );
};
export default Dashboard;