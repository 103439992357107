import CryptoJS from "crypto-js";

class Helper {
    
    static environment = 2;
    static server_base_url = null;
    static console_logger = null;
    static numberValue = null;
    
    /*
    environment = 0 (Debugging)
    environment = 1 (Development)
    environment = 2 (Production)
    */

    static getWsUrl(skip_value) {
        if (Helper.environment === 0) {
            Helper.server_base_url = skip_value != 0 ?  "http://159.89.166.21:8000/v14" : "http://159.89.166.21:8000/vabcd" ;
            Helper.console_logger = 0;
        } else if (Helper.environment === 1) {
            // Helper.server_base_url = skip_value != 0 ? "http://192.168.1.28:8000/v13" : "http://192.168.1.28:8000/vabcd";
            // Helper.server_base_url = skip_value != 0 ? "http://192.168.1.27:8000/v12" : "http://192.168.1.27:8000/vabcd";
            Helper.server_base_url = skip_value != 0 ? "http://192.168.1.34:8001/v14" : "http://192.168.1.34:8001/vabcd";
            Helper.console_logger = 1;
        } else if (Helper.environment === 2) {
            Helper.server_base_url =skip_value != 0 ? "https://enbill.site/v14" : "https://enbill.site/vabcd";
        } else if (Helper.environment === 4) {
            Helper.server_base_url = "http://192.168.1.28:8000/v14";
        } else {
            Helper.server_base_url = "https://enbill.site/v14";
        }
        return Helper.server_base_url;
    }
   
    static consoleLogger = (data) => {
        if (Helper.console_logger === 0) {
        console.log(data);
        } else if (Helper.console_logger === 1) {
        console.log(data);
        } else {
        console.log();
        }
    };

    static encypt_decrypt = (encryptedData) => {
        var key = "f^Wd2qv8z+gQa%$["; //key used in Python
        key = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        mode: CryptoJS.mode.ECB,
        });
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    };

}

export default Helper;
