import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import "./Newcss.css"
import Headerformobile2 from '../Header/Headerformobile2';
import axios from "axios";
import { useCookies } from 'react-cookie'
import { useEffect } from 'react';
import Helper from '../util/Helper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from '../Header/Header';

const ProductCategory = ({setKeyName, setIcidFrmIcToShop}) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [itemCategoryList, setItemCategoryList] = useState([])

    useEffect(() => {
        const disablePinchZoom = (e) => {
                if (e.touches.length > 1) {
                        e.preventDefault()
                }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
                document.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    useEffect(() => {
        setKeyName(null)
        const getBillListUrl = {
                method: "GET",
                url: Helper.getWsUrl(cookies.skip) + `/items/get_selected_item_category_new/${localStorage.getItem("contact_id")}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(getBillListUrl)
                .then((response) => {
                        if (response.data.code === 200) {
                            setItemCategoryList(Helper.encypt_decrypt(response.data.data))
                        } else if (response.data.code === 401) {
                            removeCookie("logged_in")
                            removeCookie("token")
                            setTimeout(() => {
                                    navigate('/')
                                    localStorage.setItem("isAuthenticated", false)
                                    localStorage.removeItem("isAuthenticated")
                            }, 1000)
                        }
                })
                .catch((error) => {
                        alert(error);
                })
    },[])

    return (
        <>
            <Headerformobile2  pagename="Shop Category" navigate={navigate} />
            <Header />
            {/* <div className='row no-gutters mtp100' >
                <div className='path_direction dashbbdbooking  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                    <p >
                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                        &gt;
                        <Link to="/cart" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Cart</Link>
                    </p>
                </div>
            </div> */}
                
            <div className='itemCategoryMainDiv' >
                <h2>Choose anything you want !</h2>
                <div className='itemCategoryContainer'>
                    {itemCategoryList && itemCategoryList.map((value, index) => {
                        return(
                            <div className='itemCategoryCard' onClick={() => {setIcidFrmIcToShop(value.id); navigate('/shop')}}>
                                <img src={value.image_url != null && value.image_url != "N/A" && value.image_url != "" ? value.image_url : require("../Assets/Images/log.png")} 
                                    onError={(e) =>
                                        (e.target.onerror = null)(
                                            (e.target.src = require("../Assets/Images/log.png"))
                                            )} />
                                <h6>{value.name}</h6>
                            </div>
                        )
                    })}
                </div>
                {/* } */}
            </div>
        </>
    )
}

export default ProductCategory;