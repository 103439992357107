import React, { useEffect, useState } from 'react';
import './Udhaar.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Udhaar = ({ scrollUdhaarList, setGetIsTrusted, setPageNo, setEmptyState, emptyState, setApiKey, lazyLoading, udhaarDueData, totalDueUdhaar, setUdhaarDueData, showUdhaarShopDetail, setUdhaarBillId }) => {

    const navigate = useNavigate()
    
    const [crossbt1, setcrossbt1] = useState(false);
    const [crossbt2, setcrossbt2] = useState(true);
    const [crossbt3, setcrossbt3] = useState(false);
    const [btn1, setBtn1] = useState(true);
    const [btn2, setBtn2] = useState(true);
    const [btn3, setBtn3] = useState(false);
    const [btn4, setBtn4] = useState(false);
    const [btn22, setBtn22] = useState(true);
    const [clickOverDue, setClickOverDue] = useState(true)
    const [clickDue, setClickDue] = useState(false)
    const [clickPaid, setClickPaid] = useState(false)

    // for pagination
    useEffect(() => {
        if (scrollUdhaarList && scrollUdhaarList.length > 0) {
            scrollUdhaarList.map((value, index) => {
                if (!udhaarDueData.find((element) => value.p_id === element.p_id)) {
                    setUdhaarDueData((prevState) => [
                        ...prevState, scrollUdhaarList[index]
                    ])
                }
            })
        }
    }, [scrollUdhaarList])

    useEffect(() => {
        if (udhaarDueData && udhaarDueData.length <= 0) {
            setEmptyState(true)
        } else{
            setEmptyState(false)
        }
    }, [udhaarDueData])

    useEffect(() => {
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        return () => {
            document.removeEventListener("touchmove", disablePinchZoom)
        }
    }, [])

    const showA = () => {
        setBtn1(true)
        setBtn2(false)
        setBtn3(false)
        setBtn4(false)
    }
    const showA2 = () => {
        setBtn22(true)
    }

    const Booked2a = () => {
        return (
            <div className='scrolludhaar87 ' onScroll={(event) => { setGetIsTrusted(event.isTrusted) }}>
                {udhaarDueData && udhaarDueData.map((value) => {
                    return (
                        <div className='col-12 mt-3 position-relative p-0  heightwidthofcardudhhar' onClick={() => { showUdhaarShopDetail(true); setUdhaarBillId(value.merchant_id); navigate('/udharshop'); }}>
                            <div className='row no-gutters h-100'>
                                <div className='col-4  col-md-2 borderraduisudhaarmobile1 pt-2 pr-0 pb-2 pl-2 d-flex'>
                                    <img src={value.c_logo_url} className='hwimageofudhaar2  w-100' alt="image not found"
                                        onError={(e) =>
                                            (e.target.onerror = null)(
                                                (e.target.src = require('../Assets/Images/log.png'))
                                            )
                                        }></img>
                                </div>
                                <div className='col-7 col-md-9 borderraduisudhaarmobile12 p-2 '>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p className='text-left udharrshhopename text-truncate text-capitalize'>
                                                {(value.shop_name === "") ? "Shop Name" : value.shop_name}
                                            </p>
                                        </div>
                                        <div className='col-12 mt-1'>
                                            <p className='text-left udharrshhopeaddress text-capitalize'>
                                                {value.address === "" ? "Address" : value.address}
                                            </p>
                                        </div>
                                        <div className='col-12 d-flex align-items-end'>
                                            <p className='m-0 udharrshhopeamt pr-2'>
                                                ₹ {parseFloat(value.amount).toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {udhaarDueData[0].type === 0 ? 
                                    <div className='col-1'>
                                        <div className='lastcard'>Due</div>
                                    </div> 
                                : null }
                                {udhaarDueData[0].type === 1 ? 
                                    <div className='col-1'>
                                        <div className='lastcard1'>Over Due</div>
                                    </div> 
                                : null }
                                {udhaarDueData[0].type === 2 ? 
                                    <div className='col-1'>
                                        <div className='lastcard2'>Paid</div>
                                    </div> 
                                : null }
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const Booked1 = () => {
        return (
            <>
                {udhaarDueData && udhaarDueData.map((value, index) => {
                    return (
                        <div className='row justify-content-between'>
                            <div className='col-lg-8 p-0'>
                                <div className='row bill_boxudhaar marg121 justify-content-around'>
                                    <div className='col-3  pl-0 '>
                                        <img src={value.c_logo_url} className='hwimageofudhaar ' alt="image not found" onError={(e) =>
                                            (e.target.onerror = null)(
                                                (e.target.src = require('../Assets/Images/log.png'))
                                            )
                                        }></img>
                                    </div>
                                    <div className='col-8 pl-0 usharweb'>
                                        <div className='row ml-0'>
                                            <div className='col-12 marg1'>
                                                <div className='row justify-content-between'>
                                                    <div className='col-6 p-0'>
                                                        <p className='text-left bill_shopname text-truncate text-capitalize'>{value.shop_name}</p>
                                                    </div>
                                                    {udhaarDueData[0].type === 0 ?
                                                        <div className='col-5 ml-2 mt-n3 pr-0 d-flex justify-content-end'>
                                                            <button className='dueButton'>Due</button>
                                                        </div> 
                                                    : null}
                                                    {udhaarDueData[0].type === 1 ?
                                                        <div className='col-5 ml-2 mt-n3 pr-0 d-flex justify-content-end'>
                                                            <button className='overdue'>Over Due</button>
                                                        </div> 
                                                    : null}
                                                    {udhaarDueData[0].type === 2 ?
                                                        <div className='col-5 ml-2 mt-n3 pr-0 d-flex justify-content-end'>
                                                            <button className='paid'>Paid</button>
                                                        </div> 
                                                    : null}
                                                </div>
                                            </div>
                                            <div className='col-12 marg2'>
                                                <div className='row'>
                                                    <div className='col-5 p-0'>
                                                        <p className='text-left sadr text-truncate text-capitalize'>{value.address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 marg3'>
                                                <div className='row justify-content-end'>
                                                    <div className='col-12  p-0'>
                                                        <div className='widthofamtsho mr-0'>
                                                            <p className='text-right amts1'>Amt: ₹ <span className='am'>{value.amount}</span></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 btnbox ml-n4 pl-0 d-flex align-items-center justify-content-center ' >
                                <div>
                                    <button className='bill_viewbtnudhaar center font-weight-bold' onClick={() => { showUdhaarShopDetail(true); setUdhaarBillId(value.merchant_id); navigate('/udharshop') }}>View
                                        <img src={require('../Assets/Images/Right.png')} style={{ position: "absolute", top: "12px", right: "14px", height: "15px", width: "15px" }} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <>
            {/* Breadcrumb */}
            <div className='margintbudhaar'>
                <div className=''>
                    <div className='path_direction  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                        <p style={{ color: "#7A7A7A" }}>
                            <Link to="/dashboard" className='text-decoration-none beardcolor m-1'>Home</Link>
                            &gt;
                            <Link to="/udhaar" className='text-decoration-none beardcolor m-1'>Udhaar</Link>
                        </p>
                    </div>
                </div>

                <div className='row justify-content-end  no-gutters mt-lg-n5  mb-lg-5 udhaartop '>
                    <div className='col-3 borderradiusforamount webview mr-2'>
                        <div className=' p-2 d-flex justify-content-between '>
                            <span className='totaludhaar  pr-3'>Total Udhaar</span>
                            <span className='amtotalofudhar '>₹ {totalDueUdhaar && totalDueUdhaar}</span>
                        </div>
                    </div>
                </div>

                <div className="bill_main_box99 row webview justify-content-center d-flex">
                    <div className="bill_filter_boxudhaar col-3 pt-0 pl-0 webview">
                        {crossbt1 ?
                            <button className='clickfilter_btn center'> Due </button> 
                        :
                            <button className='filter_btn center' onClick={() => { setUdhaarDueData([]); setPageNo(1); setApiKey(null); setcrossbt1(true); setcrossbt2(false); setcrossbt3(false); showA() }}>Due</button>
                        }

                        {crossbt2 ?
                            <button className='clickfilter_btn center'> Over Due </button> 
                        :
                            <button className='filter_btn center' onClick={() => { setUdhaarDueData([]); setPageNo(1); setApiKey("over_due"); setcrossbt2(true); setcrossbt1(false); setcrossbt3(false); showA() }}>Over Due</button>
                        }

                        {crossbt3 ?
                            <button className='clickfilter_btn center'>Paid</button> 
                        :
                            <button className='filter_btn center' onClick={() => { setUdhaarDueData([]); setPageNo(1); setApiKey("paid"); setcrossbt3(true); setcrossbt2(false); setcrossbt1(false); showA() }}>Paid</button>
                        }
                    </div>

                    <div className='bill_list_box col-8  webview position-relative'>
                        {lazyLoading ? 
                            <Skeleton height={"125px"} style={{ marginRight: "250px" }} width={"100%"} count={10} ></Skeleton>
                        :
                            <>
                                {emptyState ?
                                    <>
                                        <div className='emptystateset34 d-flex justify-content-center align-items-center flex-column h-100'>
                                            <img src={require('../Assets/Images/udhaar.svg').default} className='imhgemptycart' />
                                            <p className='paraforempty2 mt-3'>Pay with Udhaar to your local merchants.</p>
                                        </div>
                                    </> 
                                :
                                    <>
                                        {btn1 && Booked1()}
                                    </>
                                }
                            </>
                        }
                    </div>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p>From</p>
                                    <input autoComplete="off" type="date" className="input_data cal2" />
                                    <p className='date_box_to'>To</p>
                                    <input style={{ marginBottom: "20px" }} autoComplete="off" type="date" className="input_data cal2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view */}
            <div className=' mobileviewforudhaar  pl-2 pr-2'>
                <div className='row no-gutters'>
                    <div className='col-12 '>
                        <div className="totsludharforphone border d-flex justify-content-between p-2">
                            <div className='mvtotudhaar'>Total Udhaar:</div>
                            <div className='amttotaludhaar'>₹
                                {(totalDueUdhaar && totalDueUdhaar) == "" ? "0.00" :
                                    parseFloat(totalDueUdhaar && totalDueUdhaar).toFixed(2)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <div className='row justify-content-between secondmaindivudhharmobile mt-3 '>
                        <div className='col-4 d-flex justify-content-start  p-0'>
                            {clickOverDue ?
                                <button className="p-2 bg-transparent overdue1" style={{ border: "1px solid #EF7B37", color: "#EF7B37", background: "#fff" }} id="overdue1" >Over Due</button>
                            :
                                <button className="p-2 bg-transparent overdue1" id="overdue1" onClick={() => { setUdhaarDueData([]); showA2(); setPageNo(1); setApiKey("over_due"); setClickOverDue(true); setClickPaid(false); setClickDue(false) }}>Over Due</button>
                            }
                        </div>

                        <div className='col-4  p-0'>
                            {clickDue ?
                                <button style={{ border: "1px solid #EF7B37", color: "#EF7B37", background: "#fff" }} className="pt-2 pb-2 pl-4 pr-4 bg-transparent overdue12"> Due</button>
                            :
                                <button onClick={() => { setUdhaarDueData([]); showA2(); setPageNo(1); setApiKey(null); setClickDue(true); setClickOverDue(false); setClickPaid(false); }} className="pt-2 pb-2 pl-4 pr-4 bg-transparent overdue12"> Due</button>
                            }
                        </div>

                        <div className='col-4 d-flex justify-content-end p-0'>
                            {clickPaid ?
                                <button style={{ border: "1px solid #EF7B37", color: "#EF7B37", background: "#fff" }} className="pt-2 pb-2 pl-4 pr-4 bg-transparent overdue13"> Paid</button>
                            :
                                <button onClick={() => { setUdhaarDueData([]); showA2(); setPageNo(1); setApiKey("paid"); setClickPaid(true); setClickDue(false); setClickOverDue(false); }} className="pt-2 pb-2 pl-4 pr-4 bg-transparent overdue13"> Paid</button>
                            }
                        </div>
                    </div>
                </div>

                {lazyLoading ? 
                    <Skeleton height={"125px"} style={{ marginRight: "250px" }} width={"100%"} count={4} ></Skeleton> 
                :
                    <>
                        {emptyState ? 
                            <div className=' mobileviewforudhaar  pl-2 pr-2'>
                                <div className='emptystateset d-flex justify-content-center align-items-center flex-column h-100'>
                                    <img src={require('../Assets/Images/udhaar.svg').default} className='imhgemptycart' />
                                    <p className='paraforempty2 mt-3'>Pay with Udhaar to your local merchants.</p>
                                </div>
                            </div> 
                        :
                            <>
                                {btn22 && Booked2a()}
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}
export default Udhaar;
