import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Favorite.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Shops({ setdashcardidforshopdetails, lat, lng }) {

  const navigate = useNavigate()
  const [cookies, setCookies, removeCookies] = useCookies(["token"])
  
  const [favshops, setfavshops] = useState([])
  const [favshplen, set_favshplen] = useState(null)
  const [lazyLoading, setLazyLoading] = useState(true);

  function contactidfavshop(cid) {
    setdashcardidforshopdetails(cid)
  }

  useEffect(() => {
    favshopsget()
  }, [])

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])            
        
  function favshopsget() {
    const resquestfavoriteshops = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/contact/get_favorite_shop/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        key: "favorite",
        lat: lat,
        lng: lng
      }
    }
    axios(resquestfavoriteshops)
      .then((response) => {
        if (response.data.code === 200) {
          set_favshplen(Helper.encypt_decrypt(response.data.data).length)
          setfavshops(Helper.encypt_decrypt(response.data.data));
          setLazyLoading(false)
        } else if (response.data.code === 204) {
          set_favshplen(0)
          setLazyLoading(false)
        } else if (response.data.code === 401) {
          removeCookies("logged_in")
          // removeCookies("token")
          setTimeout(() => {
            // navigate('/')
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function removefavitem(favitomid) {
    const resquestfavoriteshops = {
      method: "Delete",
      url: Helper.getWsUrl(cookies.skip) + `/contact/remove_favorite_shop/?web=1`,
      headers: { "Content-Type": "application/json", "token": cookies.token },
      data: {
        "shop_id": favitomid,
        "code": 0
      }
    }
    axios(resquestfavoriteshops)
      .then((response) => {
        if (response.data.code === 202) {
          favshopsget()
        } else if (response.data.code === 401) {
          removeCookies("logged_in")
          // removeCookies("token")
          setTimeout(() => {
            // navigate('/')
            localStorage.setItem("isAuthenticated", false)
            localStorage.removeItem("isAuthenticated")
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      {lazyLoading ? 
        <Skeleton height={"120px"} style={{ marginRight: "250px" }} width={"100%"} count={4} ></Skeleton> 
      :
        <>
          {(favshplen == 0 || favshplen == null) ?
            <div className=' mobileviewforudhaar23  pl-2 pr-2'>
              <div className='emptystateset41 d-flex justify-content-center align-items-center flex-column h-100'>
                <img src={require('../Assets/Images/favcshop.svg').default} className='imhgemptycart' />
                <p className='paraforempty2 mt-3'>Choose your favorites shops.</p>
              </div>
            </div>
          :
            <div className="row main-row1 heightweb no-gutters">           
              {favshops && favshops.map((val1021) => {
                return (
                  <div className="col-lg-3 card-main mb-lg-3 mt-lg-3 mb-2">
                    <div onClick={() => {contactidfavshop(val1021.contact_id); localStorage.setItem("contact_id", val1021.contact_id);}} className="main-card border p-lg-2 p-0 justify-content-start">
                      <div className="card-image " style={{ position: "relative" }}>
                        <Link className="text-decoration-none ">
                          <img src={(val1021.logo == null || val1021.logo == 0) ? "./assets/log.png" : val1021.shop_logo} className="card-imageimg"
                                onError={(e) =>
                                  (e.target.onerror == null || 0)(
                                    (e.target.src = require('../Assets/Images/log.png'))
                                  )
                                }/>
                        </Link>
                        <div className="maindivforheart3dashboard23">
                          <img src={require('../Assets/Images/orangeheart.png')} className="maindivforheart3dashboard23img" onClick={() => {removefavitem(val1021.contact_id); favshopsget();}} />
                        </div>
                      </div>

                      <div className="card-text mt-0 mt-lg-2 ml-lg-0 ml-2 " onClick={() => { navigate('/shop')}}>
                        <div className="product-card">
                          <h6 className="fw-bold shopnameprductcard text-dark pt-2 font-weight-bold text-truncate mb-1 text-capitalize">{val1021.name}</h6>
                        </div>
                        <div className="unit-text unitaddshop text-truncate mb-1 text-capitalize mt-0 mt-lg-3">+91 | {val1021.mobile_number}</div>
                        <div className="unit-text unitaddshop text-truncate mb-2 text-capitalize mt-0 mt-lg-3">{val1021.address}</div>
                        <div className="unit-text unitaddshop mb-1 mt-0 mb-lg-1 text-truncate text-capitalize">{val1021.master_category.length != 0 ? val1021.master_category : "N/A"}</div>
                        <br />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </>
      }
    </>
  );
}

