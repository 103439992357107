import React, { useState } from "react";
import "./Favorite.css"
import Favorites from "./Favorites";
import Headerformobile2 from "../Header/Headerformobile2";
import Shops from "./Shops";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../Header/Header";

export default function Favorite({shoprodctpage,ividforproductpage,setividforproductpage,dashcardidforshopdetails,setdashcardidforshopdetails,lat,lng}) {

  const navigate=useNavigate()

  const [btn,setBtn] = useState(false);
  const [shop,setShop]=useState(true);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  return (
    <>
      <Headerformobile2 pagename="Favourite" navigate={navigate}/>
      <Header />
      <div className="mtpx" style={{width:"100%"}}>    
        <div className='row no-gutters '>
          {/* breadcrumb */}
          <div className='breadcrumprofile p-0 m-0'>
            <nav>
              <ol className="breadcrumb  bg-white p-0 m-0">
                <li className="breadcrumb-item "><Link to="/dashboard" className='text-secondary text-decoration-none' style={{fontSize:"14px"}}>Home</Link></li>                                                                                      
                <li className="breadcrumb-item text-dark"><Link to="/favourite" className='text-secondary text-decoration-none' style={{fontSize:"14px"}}>Favorites</Link></li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="d-flex controls mt-3 margintopforshop">
          <div className="btn-fav">
            <button className={shop? "selectbtn" : "selectbtn1"  } onClick={()=>{setBtn(false); setShop(true)}}>Shops</button>
          </div>
          <div className="btn-fav">
            <button className={shop? "selectbtn1" : "selectbtn"  }    onClick={()=>{setBtn(true); setShop(false)}}>Items</button>
          </div>
        </div>
       
        <div className="p-2 border-line-fav" >
          {btn ? 
            <Favorites setividforproductpage={setividforproductpage} ividforproductpage={ividforproductpage} shoprodctpage={shoprodctpage}/>
          :
            <Shops dashcardidforshopdetails={dashcardidforshopdetails} setdashcardidforshopdetails={setdashcardidforshopdetails} shoprodctpage={shoprodctpage} lat={lat} lng={lng}/>
          }         
        </div>
      </div>
    </>
  );
}
