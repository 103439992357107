import React, { useEffect, useState } from "react";
import "./Locations.css";
import { Link, useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import MapContainer from "./MapForLocation";
import { useRef } from 'react';

Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();
Geocode.setApiKey("AIzaSyDY0fD0X7L5jbJDn8M5L9IrA3XsAKHs_tQ");
Geocode.setLocationType("ROOFTOP");

export default function Locations({ setadress_mobile_dsh, setGlobalLongitude, setGlobalLatitude, globalLongitude, globalLatitude }) {

  const [userlatitude, setuserlatitude] = useState(null);
  const [userlongitude, setuserlongitude] = useState(null);
  const [getPin, setGetPin] = useState(null);
  const [showStaticMap, setShowStaticMap] = useState(false)

  const navigate = useNavigate()
  const google = window.google;

  const handleChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setGetPin(event.target.value);
      setShowStaticMap(false)
      window.reload()
    }
  }

  useEffect(() => {
    if (getPin != null) {
      Geocode.fromAddress(getPin).then(({ results }) => {
        setuserlongitude(results[0].geometry.location.lng)
        setuserlatitude(results[0].geometry.location.lat)
      });
    } else {
      if (globalLatitude && globalLatitude != null && globalLongitude && globalLongitude != null) {
        setuserlatitude(globalLatitude)
        setuserlongitude(globalLongitude)
      } else {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setuserlatitude(position.coords.latitude)
            setuserlongitude(position.coords.longitude)
          },
          function (error) {
            setShowStaticMap(true)
          }
        );
      }
    }
  }, [getPin])

  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };

  const getReverseGeocodingData = (lat, long) => {
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0].formatted_address;
        localStorage.setItem("Address", response.results[0].formatted_address)
      },
      (error) => {
        console.error("error : ", error);
      }
    );
  }

  useEffect(() => {
    if (userlatitude && userlatitude != null && userlongitude && userlongitude != null) {
      getReverseGeocodingData(userlatitude, userlongitude)
    }
  }, [userlatitude, userlongitude])

  const setLatLongFunc = () => {
    setGlobalLongitude(userlongitude)
    setGlobalLatitude(userlatitude)
  }

  return (
    <div className=" location-main mobsc" style={{ width: "100%" }}>
      <div className="main-location">
            <h1 style={{fontSize:"26px"}} className="text-center main-text-location">Location</h1>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div>
            <h3 className="text-location">Current Location</h3>
            <p className="igject-location ">
              {localStorage.getItem("Address")}
            </p>
          </div>
          
          <Link to={'/dashboard'}>
            <button className="d-lg-block d-none" style={{ backgroundColor: "#EE7A37", borderRadius: "23px", color: "white", fontWeight: "700", border:"none", padding:"5px 20px"}} onClick={setLatLongFunc}>
              Confirm & Continue
            </button>
          </Link>
        </div>

        <hr className="hrlocation d-lg-block d-none" />

        <div className="locationSearchMapMargin">
          <form autoComplete="off" className="d-flex" style={{ position: "relative", justifyContent: "center" }} >
            <input onKeyDown={handleChange} style={{ marginLeftL: "0px", backgroundColor: "F9F9F9", width: "100%", marginBottom: "10px", borderRadius: "10px", }} className="form-control me-2 bg-light input-search ml-5 mr-5" type="search" placeholder="Search locality, area" aria-label="Search"  />
            {/* <button className="btn " type="submit" style={{ backgroundColor: "F9F9F9", position: "absolute", right: "70px", }} > */}
              <img src={require('../Assets/Images/icon_search.jpg')} alt={""} className="d-lg-block d-none" style={{ backgroundColor: "F9F9F9", position: "absolute", right: "70px", top:"10px" }} /> 
            {/* </button> */}

            <div className="d-lg-none d-block widthl">
              <div class="row mt-5 pl-2 pr-2 mb-3 foripad">
                <div className="col-1 changecol pr-0">
                  <button className="borderleftarrow43 p-2 bg-transparent " onClick={(e) => { navigate("/dashboard") }} ><img src={require('../Assets/Images/Left 2.png')} style={{marginLeft:"2px",marginTop:"-3px"}}/></button>
                </div>
                <div class="col ">
                  <div class="input-group">
                    <input ref={ref} onKeyDown={handleChange} style={{ width: "70vw" }} class="form-control border-end-0 border pt-3 pb-3 bor" type="search" placeholder="Search/Enter Pincode" id="example-search-input" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* map */}
        <div>
          <div className="iframe-location pl-lg-5 pr-lg-5 lock">
            {!showStaticMap ?
              <MapContainer userlatitude={userlatitude} userlongitude={userlongitude} ></MapContainer>
            :
              <MapContainer showStaticMap={showStaticMap} userlatitude={21.7679} userlongitude={78.8718} ></MapContainer>
            }
          </div>
        </div>

        <div className="location-cart loationfull" style={{ position: "fixed", bottom:'0', width: "100%" }}>
          <div className="card card13" style={{ width: "100%", padding: "11px", marginBottom: "40px", zIndex: "+1px", backgroundColor: "#FFFFFF", boxShadow: "0px 0px 10px", }} >
            {!showStaticMap ?
              <>
                <div className="mb-2 mt-2 cart-locaion-text text-left">Current Location</div>
                <div className="cart-igject-location text-left">
                  {
                    setadress_mobile_dsh(localStorage.getItem("Address"))
                  }
                  {localStorage.getItem("Address")}
                </div>
                <div className="d-flex justify-content-center">
                  <Link to={'/dashboard'}>
                    <button onClick={setLatLongFunc} className="btn" style={{ backgroundColor: "#EE7A37", borderRadius: "10px", color: "white", fontWeight: "700", marginTop: "20px", height: "50px", width: "335px", fontSize: "17px", lineHeight: "22px", }} >
                      Confirm & Continue
                    </button>
                  </Link>
                </div>
              </>
            :
              <div>
                <div className="mb-3 mt-3 cart-locaion-text text-left">Please enter location to search shop</div>
                <button onClick={handleClick} className="btn" style={{ backgroundColor: "#EE7A37", borderRadius: "10px", color: "white", fontWeight: "700", marginTop: "20px", height: "50px", width: "335px", fontSize: "17px", lineHeight: "22px", }} >
                  Enter Location
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}





