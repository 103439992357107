import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Headerformobile2 from "../Header/Headerformobile2";
import Helper from "../util/Helper";
import "./Address.css"

const AddAddress = ({getLngToAdd, getLatToAdd, getPincodeToAdd, getAddressToAdd, setGetAddressToAdd, setGetLngToAdd, setGetLatToAdd, setGetPincodeToAdd, getAddressId}) => {

    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [address2, setAddress2] = useState(null)
    const [getPlace, setGetPlace] = useState(0)
    const [otherName, setOtherName] = useState(null)
    const [otherMobile, setOtherMobile] = useState(null)
    const [showError, setShowError] = useState(false)
    const [showOtherErr, setShowOtherErr] = useState(false)
    const [addressDetail, setAddressDetail] = useState([])

    const setOtherMobileNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.value === '' || re.test(e.target.value)) {
            setOtherMobile (e.target.value);
        }
    }

    useEffect(() => {
        if(getAddressId && getAddressId != null) {
            const getAddressUrl = {
                method: "GET",
                url: Helper.getWsUrl(cookies.skip) + `/booking/get_customer_added_address_new/${getAddressId}/?web=1`,
                headers: { "Content-Type": "application/json", 'token': cookies.token },
            };
            axios(getAddressUrl)
                .then((response) => {
                        if (response.data.code === 200) {
                            setAddressDetail(Helper.encypt_decrypt(response.data.data))
                        } else if (response.data.code === 401) {
                                removeCookie("logged_in")
                                removeCookie("token")
                                setTimeout(() => {
                                        navigate('/')
                                        localStorage.setItem("isAuthenticated", false)
                                        localStorage.removeItem("isAuthenticated")
                                }, 1000)
                        }
                })
                .catch((error) => {
                        alert(error);
                })
        }
    },[getAddressId])

    useEffect(() => {
        if(addressDetail && addressDetail.length > 0) {
            setGetAddressToAdd(addressDetail[0].address)
            setGetLatToAdd(addressDetail[0].lat)
            setGetLngToAdd(addressDetail[0].lng)
            setGetPincodeToAdd(addressDetail[0].pincode)
            setAddress2(addressDetail[0].near_by)
            setGetPlace(addressDetail[0].type)
            setOtherName(addressDetail[0].reciver_name)
            setOtherMobile(addressDetail[0].reciver_mobile_number)
        }
    },[addressDetail])

    const saveAddressData = () => {
        if(getAddressToAdd != null && getPincodeToAdd != null && getLatToAdd != null && getLngToAdd != null && address2 != null) {
            if(getPlace === 2) {
                if(otherMobile != null && otherName != null) {
                    callAPItoSaveAddress()
                } else {
                    setShowOtherErr(true)
                }
            } else {
                callAPItoSaveAddress()
            }
        } else {
            setShowError(true)
        }
    }

    const callAPItoSaveAddress = () => {
        const getAddressListUrl = {
            method: "POST",
            url: Helper.getWsUrl(cookies.skip) + `${ getAddressId != null ? `/booking/add_multiple_address_v11/${getAddressId}/?web=1` : `/booking/add_multiple_address_v11/?web=1`}`,
            headers: { "Content-Type": "application/json", 'token': cookies.token },
            data: {
                action: getPlace && getPlace,
                address_detail: [
                    {
                        near_by: address2 && address2,
                        address:  getAddressToAdd && getAddressToAdd,
                        pincode_id: getPincodeToAdd && getPincodeToAdd,
                        lat: getLatToAdd && getLatToAdd,
                        lng: getLngToAdd && getLngToAdd,
                        reciver_mobile_number: otherMobile && otherMobile,
                        reciver_name: otherName && otherName
                    }
                ]
            }
        };
        axios(getAddressListUrl)
            .then((response) => {
                    if (response.data.code === 201) {
                        setGetAddressToAdd(null)
                        setGetLatToAdd(null)
                        setGetLngToAdd(null)
                        setGetPincodeToAdd(null)
                        setAddress2(null)
                        setGetPlace(0)
                        setOtherName(null)
                        setOtherMobile(null)
                        if(getAddressId && getAddressId != null) {
                            navigate('/address')
                        } else {
                            navigate(-2)
                        }
                    } else if (response.data.code === 401) {
                        removeCookie("logged_in")
                        removeCookie("token")
                        setTimeout(() => {
                                navigate('/')
                                localStorage.setItem("isAuthenticated", false)
                                localStorage.removeItem("isAuthenticated")
                        }, 1000)
                    }
            })
            .catch((error) => {
                    alert(error);
            })
    }

    return (
        <>
            <Headerformobile2  pagename="Add Address" navigate={navigate} />
            <Header />
            <div style={{marginTop:'100px', padding:'0 15px'}} className="searchedAddressHead">
                <img src={require('../Assets/Images/customer icons_location.svg').default} />
                <h5 className="cart-locaion-text text-left" style={{fontWeight:'500'}}>Location</h5>
            </div>
            <p className="confirmedAddress">{getAddressToAdd && getAddressToAdd}</p>
            <hr style={{margin:'0 8px 25px'}} />

            <div className="addressInputs">
                <input autoComplete="off" type='text' value={getAddressToAdd && getAddressToAdd} onChange={(e) => {setGetAddressToAdd(e.target.value)}} />
                <p>House/Building Name/Block No.*</p>
            </div>
            <p style={{display: !showError ? 'none' : getAddressToAdd && getAddressToAdd != null ? 'none' : 'block'}} className="errorMsg" >Please Enter Required Field*</p>

            <div className="addressInputs">
                <input autoComplete="off" type='text' value={address2 && address2} onChange={(e) => {setAddress2(e.target.value)}} />
                <p>Apartment/Landmark/Area*</p>
            </div>
            <p style={{display: !showError ? 'none' : address2 && address2 != null ? 'none' : 'block'}} className="errorMsg" >Please Enter Required Field*</p>

            <div className="addressPlace">
                <h5>Save As</h5>
                <div className="innerAddressPlace">
                    <p onClick={() => {getAddressId && getAddressId != null ? setGetPlace(getPlace) : setGetPlace(0)}} style={{border: getPlace != 0 ? '1px solid #4e4e4e' : '2px solid #EE7B37', fontWeight: getPlace != 0 ? '500' : '600', color: getPlace != 0 ? '#212529' : '#ee7b37'}}>Home</p>
                    <p onClick={() => {getAddressId && getAddressId != null ? setGetPlace(getPlace) : setGetPlace(1)}} style={{border: getPlace != 1 ? '1px solid #4e4e4e' : '2px solid #EE7B37', fontWeight: getPlace != 1 ? '500' : '600', color: getPlace != 1 ? '#212529' : '#ee7b37'}}>Work</p>
                    <p onClick={() => {getAddressId && getAddressId != null ? setGetPlace(getPlace) : setGetPlace(2)}} style={{border: getPlace != 2 ? '1px solid #4e4e4e' : '2px solid #EE7B37', fontWeight: getPlace != 2 ? '500' : '600', color: getPlace != 2 ? '#212529' : '#ee7b37'}}>Other</p>
                </div>
            </div>

            <div style={{display: getPlace != 2 ? 'none' : 'block'}}>
                <div className="addressInputs">
                    <input autoComplete="off" type='text' value={otherName && otherName} onChange={(e) => {setOtherName(e.target.value)}} />
                    <p>Name*</p>
                </div>
                <p style={{display: !showOtherErr ? 'none' : otherName && otherName != null ? 'none' : 'block'}} className="errorMsg" >Please Enter Required Field*</p>

                <div className="addressInputs">
                    <input autoComplete="off" type='text' maxLength={10} value={otherMobile && otherMobile} onChange={(e) => {setOtherMobileNumber(e)}} />
                    <p>Mobile Number*</p>
                </div>
                <p style={{display: !showOtherErr ? 'none' : otherMobile && otherMobile != null ? 'none' : 'block'}} className="errorMsg" >Please Enter Required Field*</p>
            </div>

            <div className="saveAddress">
                <button onClick={() => {saveAddressData()}}>
                    Save Address
                </button>
            </div>
        </>
    )
}

export default AddAddress