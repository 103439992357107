import "./Account.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Helper from "../../util/Helper";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Account({setKeyName}) {

        const [cookies, setCookies, removeCookies] = useCookies(["token"])   
        const [inputfiledtatas, setinputfiledtatas] = useState('data')

        function previewFile() {
                const preview1 = document.querySelector('.img1');                
                const file = document.querySelector('.fil55').files[0];
                const reader = new FileReader();        
                reader.addEventListener("load", () => {
                        preview1.src = reader.result;
                }, false);        
                if (file) {
                        reader.readAsDataURL(file);
                }
        }

        useEffect(() => {
                getprofiledatafunction()
        }, [])

        function getprofiledatafunction() {
                const getprofileimageapistartdata = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_contact/0/?app_type=3&web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                };
                axios(getprofileimageapistartdata)
                        .then((response) => {
                                if(response.data.code === 200) {
                                        setinputfiledtatas(Helper.encypt_decrypt(response.data.data))
                                }                             
                        })
                        .catch((err) => {
                                console.log(err)
                        })
        }
        document.ontouchmove = function(event){
                event.preventDefault();
        }

        useEffect(()=>{
                setKeyName(null)
        },[])
        
        return(
                <div className="maincontainerformobileview  " style={{backgroundColor:"whitesmoke",height:"100vh"}}>
                        <div className="row no-gutters  ">
                                <div className="col-12  m-0 ">
                                        <div className="row bg-white pt-1 pb-2 ml-n3 mr-0 heaightsccount">                                        
                                                <div className="col-9   ">
                                                        <div className="media pl-2 pt-2">
                                                                <img className="mr-3 icon3 img1" src = {require('../../Assets/Images/log.png')} alt="Generic placeholder image" />
                                                                <div className="media-body">
                                                                        <h5 className=" nameh">                                                                
                                                                                {(inputfiledtatas.name =="" || inputfiledtatas.name ==null)?"Name":inputfiledtatas.name}
                                                                        </h5>
                                                                        <p className="numberphone">{inputfiledtatas.mobile_number}</p>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className="col-3 justify-content-end d-flex parofileee  pt-2">
                                                        <div className="firstpic12">
                                                                <div className='icon2'>
                                                                        <div id="xslUpload">
                                                                                <Link to="/profile">
                                                                                        <label htmlFor="file-input53" className="hwtb ">
                                                                                                <button className="btn importar1 p-0" type="button">
                                                                                                        <img src={require('../../Assets/Images/icasaon.png')} className='rounded-circle  imgnew2'/>
                                                                                                </button>
                                                                                        </label>
                                                                                </Link>
                                                                                <input className="file-input fil55" id="file-input53" type="file" onChange={previewFile}  />                                                                
                                                                        </div> 
                                                                </div>
                                                                <div className='icon1'>
                                                                        <div id="xslUpload">                                                                                                
                                                                                <input className="file-input fil55" id="file-input53" type="file" onChange={previewFile}  />                                                                
                                                                        </div> 
                                                                </div>   
                                                        </div>                                                
                                                </div>
                                        </div>

                                        <div className="row ml-lg-0 mr-lg-0 ml-2 mr-2 mt-3 bgcol shadow-sm">
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <Link to="/bookingstatus" className="text-decoration-none">   
                                                                <div className="media modeltransform align-items-center">
                                                                        <img className=" hwicon11"  src = {require('../../Assets/Images/iconbooking.png')} alt="Generic placeholder image"/>                                                
                                                                        <div className="media-body">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1 text-decoration-none ">Booking Status</h5>                                                
                                                                        </div>
                                                                </div>
                                                        </Link>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <Link to="/billrecords" className="text-decoration-none">  
                                                                <div className="media modeltransform align-items-center">
                                                                        <img className=" hwicon11" src={require('../../Assets/Images/icon4332243.png')} alt="Generic placeholder image"/>
                                                                        <div className="media-body">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1 mr-1">Bill Records</h5>                                                        
                                                                        </div>
                                                                </div>
                                                        </Link>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <Link to="/udhaar" className="text-decoration-none"> 
                                                                <div className="media modeltransform align-items-center">
                                                                        <img className="hwicon11" src={require('../../Assets/Images/icon33reerrf.png')} alt="Generic placeholder image"/>
                                                                        <div className="media-body">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Udhaar</h5>
                                                                        </div>
                                                                </div>
                                                        </Link>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <Link to="/favourite" className="text-decoration-none"> 
                                                                <div className="media modeltransform align-items-center">
                                                                        <img className=" hwicon11" src={require('../../Assets/Images/icofdfewrn.png')} alt="Generic placeholder image"/>
                                                                        <div className="media-body">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Favorites</h5>
                                                                        </div>
                                                                </div>
                                                        </Link>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <Link to="/address" className="text-decoration-none"> 
                                                                <div className="media modeltransform align-items-center">
                                                                        <img style={{height:'25px', width:'26px'}} className="hwicon11" src={require('../../Assets/Images/customer icdsdons_location (1).png')} alt="Generic placeholder image"/>
                                                                        <div className="media-body">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Address</h5>
                                                                        </div>
                                                                </div>
                                                        </Link>
                                                </div>
                                        </div>

                                        <div className="row ml-lg-0 mr-lg-0 ml-2 mr-2 mt-3 bgcol shadow-sm">
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <div className="media modeltransform align-items-center">
                                                                <img className="hwicon11" src={require('../../Assets/Images/iconytft.png')} alt="Generic placeholder image"/>
                                                                <div className="media-body">                                                
                                                                        <Link to="/gethelp" className="text-decoration-none">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Help & Support</h5>
                                                                        </Link>                                                        
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <div className="media modeltransform align-items-center">
                                                                <img className=" hwicon11" src={require('../../Assets/Images/dsadrewsicon.png')} alt="Generic placeholder image"/>
                                                                <div className="media-body">
                                                                        <Link to="/termsandconditions" className="text-decoration-none">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Terms and Conditions</h5>
                                                                        </Link>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className="col-12 mt-1 ml-2 mr-1 mb-1  p-0">
                                                        <div className="media modeltransform align-items-center">
                                                                <img className=" hwicon11" src={require('../../Assets/Images/j89uy8icon.png')} alt="Generic placeholder image"/>
                                                                <div className="media-body">
                                                                        <Link to="/privacypolicy" className="text-decoration-none">
                                                                                <h5 className="mt-0 bs11 text-left ml-3 mb-1 mt-1">Privacy Policy</h5>
                                                                        </Link>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>                
                </div>
        )    
}