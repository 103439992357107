import { useEffect, useState } from "react";
import "./Billrecords.css";
import Headerformobile2 from "../Header/Headerformobile2";
import { Link, useNavigate } from "react-router-dom";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import Header from "../Header/Header";

const Billrecords = ({ setBillList, setNodatacustombtn, setnodatacustom, setnodatabtn, setnodatatoday, setNodata7days, setNodata7daysbtn, showbilldetails, billList, setBillId }) => {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [filterbtn, setFilterbtn] = useState(false);
  const [filterbtn2, setFilterbtn2] = useState(false);
  const [filterbtn3, setFilterbtn3] = useState(false);
  const [calinput, setCalInput] = useState(false);
  const [btn2, setBtn2] = useState(true);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [lazyLoading, setLazyLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [getIsTrusted, setGetIsTrusted] = useState(false);
  const [scrollBillList, setScrollBillList] = useState([]);
  const [cardCode, setCardCode] = useState(0);
  const [stopCard, setStopCard] = useState(0);
  const [emptyState, setEmptyState] = useState(false);

  const current = new Date();
  const startDate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
  const date = moment().subtract(7, "days").calendar();
  const last7days = moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      setGetIsTrusted(event.isTrusted);
    });
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  useEffect(() => {
    if (getIsTrusted && cardCode === 0) {
      setPageNo(pageNo + 1);
      BillingGetApiDefault();
    }
  }, [getIsTrusted]);

  useEffect(() => {
    if (cardCode === 1) {
      setPageNo(pageNo + 1);
      BillingGetApi();
    }
  }, [getIsTrusted, cardCode]);

  useEffect(() => {
    if (startDate != null && last7days != null && cardCode === 2) {
      setPageNo(pageNo + 1);
      BillingGetApi7Days();
    }
  }, [getIsTrusted, cardCode]);

  useEffect(() => {
    if (customEndDate != null && customStartDate != null && cardCode === 3) {
      setPageNo(pageNo + 1);
      BillingGetApiCustom(customStartDate);
    }
  }, [getIsTrusted, cardCode, customEndDate, customStartDate]);

  useEffect(() => {
    if (scrollBillList && scrollBillList.length > 0) {
      scrollBillList.map((value, index) => {
        if (!billList.find((element) => value.bill_id === element.bill_id)) {
          setBillList((prevState) => [...prevState, scrollBillList[index]]);
        }
      });
    }
  }, [scrollBillList]);

  const showB = () => {
    setBtn2(true);
  };

  useEffect(() => {
    BillingGetApiDefault();
  }, []);

  const BillingGetApiDefault = () => {
    const getBillListUrl = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_bill_list/?page=${pageNo}&no_of_record=10&web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(getBillListUrl)
      .then((response) => {
        if (response.data.code === 200) {
          setEmptyState(false);
          if (Helper.encypt_decrypt(response.data.data).bills[0] === "No Page Found") {
            setStopCard(true);
          }
          setScrollBillList(Helper.encypt_decrypt(response.data.data).bills);
          setLazyLoading(false);
          setGetIsTrusted(false);
        } else if (response.data.code === 204) {
          setLazyLoading(false);
          setScrollBillList([]);
          setEmptyState(true);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  // for custom
  const BillingGetApiCustom = (customStartDate) => {
    const getBillListUrl = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_bill_list/?page=${pageNo}&no_of_record=10&web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        end_date: customStartDate,
        start_date: customEndDate,
      },
    };
    axios(getBillListUrl)
      .then((response) => {
        if (response.data.code === 200) {
          setScrollBillList(Helper.encypt_decrypt(response.data.data).bills);
          setCustomEndDate(null);
          setCustomStartDate(null);
          setLazyLoading(false);
          setGetIsTrusted(false);
          if (document.getElementById("calenderForm") != null) {
            document.getElementById("calenderForm").reset();
          }
          if (document.getElementById(" calenderFormMOb") != null) {
            document.getElementById(" calenderFormMOb").reset();
          }
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
        } else if (response.data.code === 204) {
          setnodatacustom(true);
          setCustomEndDate(null);
          setCustomStartDate(null);
          setLazyLoading(false);
          setScrollBillList([]);
          if (document.getElementById("calenderForm") != null) {
            document.getElementById("calenderForm").reset();
          }
          if (document.getElementById(" calenderFormMOb") != null) {
            document.getElementById(" calenderFormMOb").reset();
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  // for last 7 days
  const BillingGetApi7Days = () => {
    const getBillListUrl = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_bill_list/?page=${pageNo}&no_of_record=10&web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        end_date: startDate,
        start_date: last7days,
      },
    };
    axios(getBillListUrl)
      .then((response) => {
        if (response.data.code === 200) {
          setGetIsTrusted(false);
          setScrollBillList(Helper.encypt_decrypt(response.data.data).bills);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
        } else if (response.data.code === 204) {
          setNodata7days(true);
          setScrollBillList([]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const BillingGetApi = () => {
    const getBillListUrl = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/vouchers/customer_bill_list/?page=${pageNo}&no_of_record=10&web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        date: startDate,
      },
    };
    axios(getBillListUrl)
      .then((response) => {
        if (response.data.code === 200) {
          setScrollBillList(Helper.encypt_decrypt(response.data.data).bills);
          setGetIsTrusted(false);
          setLazyLoading(false);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          removeCookie("token");
        } else if (response.data.code === 204) {
          setnodatatoday(true);
          setScrollBillList([]);
          setLazyLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const Today = () => {
    return (
      <>
        {billList && billList.map((value, index) => {
          if (value != "No Page Found") {
            return (
              <div className="row justify-content-lg-around justify-content-center mb-3  ">
                <div className="col-lg-8 col-11  ml-0 mr-0 ml-lg-0 mr-lg-0 ">
                  <div className="row bill_box p-2 ">
                    <div className="col-6 pl-0 pl-lg-2 pr-0">
                      <p className="bill_shopnamebill mt-1">
                        {value.merchant_name}
                      </p>
                      <p className="bill_shopaddress mt-n3 mb-0 text-truncate">
                        {value.address == null || value.address == ""
                          ? "Address : N/A"
                          : value.address}
                      </p>
                      <br></br>
                      <p className="bill_billnumber mt-lg-n1 mt-n3 mb-0">
                        Bill Num.: {value.bill_number}
                      </p>
                    </div>
                    <div className="col-6 pr-0 pr-lg-2 pl-0">
                      <p className="bill_time ">{value.bill_date}</p>
                      <br></br> <br></br>
                      <p className="bill_amt mt-3 mb-0">
                        Amt: ₹{parseFloat(value.total_amount).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-11  ml-lg-0 mr-lg-0 d-flex align-items-center justify-content-center p-0">
                  <button className="bill_viewbtn center webbtn12 " onClick={() => { showbilldetails(true); setBillId(value.bill_id); navigate('/billdetail') }} >
                    View Bills
                    <img src={ require("../Assets/Images/arrow-white-1.svg").default } style={{ position: "absolute", top: "5px", right: "4px", height: "80%", }}/>
                  </button>
                  <button className="bill_viewbtn2 mob2btn center" onClick={() => { showbilldetails(true); setBillId(value.bill_id); navigate('/billdetail') }} >
                    View Bills
                    <img src={ require("../Assets/Images/arrow-white-1.svg").default } style={{ position: "absolute", top: "-1px", right: "9px", height: "36px", width: "36px", }}/>
                  </button>
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

  const handleChange2 = (e) => {
    setCustomStartDate(e.target.value);
    showB();
  };

  return (
    <>
      <Headerformobile2 pagename="Billl Records" navigate={navigate} />
      <Header />
      <div className="maindivforbillrecords overflow-hidden p-lg-0 p-1 ">
        <div className="path_direction" style={{ paddingLeft: "2%", alignItems: "center", paddingTop: "20px", }}>
          <p style={{ color: "#7A7A7A" }}>
            <Link to="/dashboard" className="text-decoration-none " style={{ color: "#7A7A7A" }} >
              Home
            </Link>{" "}
            &gt;{" "}
            <Link to="/billrecords" className="text-decoration-none " style={{ color: "#7A7A7A" }} >
              Bill Records
            </Link>
          </p>
        </div>

        <div className="bill_main_box pl-1 pr-1 p-lg-0 row no-gutters ml-lg-2 mr-lg-2 justify-content-center">
          {emptyState ? 
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", }} >
              <img src={require("../Assets/Images/bill records.png")} />
            </div>
          : 
            <div className="bill_filter_box32 col-lg-3 justify-content-between ">
              {filterbtn ? 
                <button className="clickfilter_btn center"> Today </button>
              : 
                <button className="filter_btn center" onClick={() => { setBillList([]); setCardCode(1); setPageNo(1); setnodatabtn(true); setFilterbtn(true); setFilterbtn2(false); setFilterbtn3(false); showB(); setCalInput(false); }}>
                  Today
                </button>
              }

              {filterbtn2 ? 
                <button className="clickfilter_btn center ">
                  {" "}Last 7 Days{" "}
                </button>
              : 
                <button className="filter_btn center " onClick={() => { setBillList([]); setCardCode(2); setPageNo(1); setNodata7daysbtn(true); setFilterbtn2(true); setFilterbtn(false); setFilterbtn3(false); showB(); setCalInput(false); }}>
                  Last 7 Days
                </button>
              }

              {filterbtn3 ?
                <button className="clickfilter_btn center ">
                  {" "}Custom{" "}
                </button>
              :
                <>
                  <button className="filter_btn center for_web" onClick={() => { setBillList([]); setCardCode(3); setPageNo(1); setFilterbtn3(true); setFilterbtn(false); setFilterbtn2(false); setCalInput(true); }}>
                    Custom
                  </button>
                  <button type="button" class="filter_btn for_mobile center " data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setBillList([]); setPageNo(1); setCardCode(3); setFilterbtn3(true); setFilterbtn(false); setFilterbtn2(false); setCalInput(true); }}>
                    Custom
                  </button>
                </>
              }

              {calinput ?
                <div className="date_box">
                  <form id="calenderForm">
                    <p>From</p>
                    <input autoComplete="off" type="date" className="input_data cal2" id="reset3" onChange={(e) => { setCustomEndDate(e.target.value); }}/>
                    <p className="date_box_to">To</p>
                    <input autoComplete="off" type="date" className="input_data cal2" id="reset4" onChange={(e) => handleChange2(e)}/>
                  </form>
                </div>
              : 
                null
              }
            </div>
          }

          {/* filterbutton mobile ends  */}
          <div className="bill_list_box3 mt-lg-2 mt-3 col-lg-8  col-12 ml-lg-2 mr-lg-2 no-gutters" onScroll={(event) => { setGetIsTrusted(event.isTrusted); }}>
            {lazyLoading ? 
              <Skeleton height={"125px"} style={{ marginRight: "250px" }} width={"100%"} count={6}></Skeleton>
            : 
              <>
                {billList.length > 0 ? 
                  <div>{btn2 && Today()}</div>
                : 
                  <p>no data found</p>
                }
              </>
            }
          </div>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <form id="calenderFormMOb">
                    <p style={{ display: "flex", textAlign: "left", marginLeft: "11%", }} >
                      From
                    </p>
                    <input autoComplete="off" type="date" className="input_data cal2 " id="reset1" onChange={(e) => { setCustomEndDate(e.target.value); }}/>
                    <p style={{ display: "flex", textAlign: "left", marginLeft: "11%", }} className="date_box_to">
                      To
                    </p>
                    <input autoComplete="off" type="date" className="input_data cal2" id="reset2" onChange={(e) => handleChange2(e)}/>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" onClick={() => { setNodatacustombtn(true); document.querySelector("#reset1").value = ""; document.querySelector("#reset2").value = ""; }} class="btn btn-success" data-bs-dismiss="modal">
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billrecords;
