import { useState, useEffect } from "react";
import React from "react";
import "./Header.css";
import { useLocation } from "react-router-dom";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = ({ setDenyLocation, denyLocation, setKeyName, setb2b, keyName, shoprodctpage, setividforproductpage, lat, lng }) => {

        const [cookies, setCookie, removeCookie] = useCookies(['token']);
        const navigate = useNavigate();
        const location = useLocation();

        const [b2b1, setb2b1] = useState("B2B")
        const [b2c1, setb2c1] = useState("B2C")
        const [selectmode, setselectmode] = useState("B2C")
        const [showKeyWords, setShowKeyWords] = useState([])
        const [list, setList] = useState([]);
        const [showcrossbtn, setshowcrossbtn] = useState(false)
        const [showError, setShowError] = useState(false)
        const [showList, setShowList] = useState(false);
        const [blankList, setblankList] = useState(false);
        const [inputfiledtatas, setinputfiledtatas] = useState('data')
        const [valueForSearch, setValueForSearch] = useState(null)
        const [recentKeys, setRecentKeys] = useState([]);
        const [accountname, setaccountname] = useState(null)
        const [inputKey, setInputKey] = useState(null);
        const [listError, setListError] = useState(false);

        var latitude = localStorage.getItem("latitude")
        var longitude = localStorage.getItem("longitude")

        function previewFile() {
                const preview2 = document.querySelector('.img2webaccount');
                const file = document.querySelector('.file-input22headerweb').files[0];
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                        preview2.src = reader.result;
                }, false);
                if (file) {
                        reader.readAsDataURL(file);
                }
        }

        const handleChange = (event) => {
                setValueForSearch(event.target.value)
        }

        useEffect(() => {
                if(cookies.skip != 0){
                    getprofiledatafunction()   
                }
        }, [])

        function getprofiledatafunction() {
                const getprofileimageapistartdata = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_contact/0/?app_type=3&web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                };
                axios(getprofileimageapistartdata)
                        .then((response) => {
                                setinputfiledtatas(Helper.encypt_decrypt(response.data.data))
                                {
                                        (Helper.encypt_decrypt(response.data.data).name == null) ?
                                                setaccountname("Name")
                                        :
                                                setaccountname(Helper.encypt_decrypt(response.data.data).name)
                                }
                        })
                        .catch((err) => {
                                console.log(err)
                        })
        }

        const globalSearch = () => {
                const getGlobalSearch = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_global_search_keywords/?key=recent&web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getGlobalSearch)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        console.log("recent keys: ", Helper.encypt_decrypt(response.data.data))
                                        {
                                                if (Helper.encypt_decrypt(response.data.data).length) {
                                                        setRecentKeys(Helper.encypt_decrypt(response.data.data).slice(0, 5))
                                                }
                                        }       
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const globalSearchApi = () => {
                const getGlobalSearch = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_global_search_keywords/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getGlobalSearch)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        console.log("global search dataaa: ", Helper.encypt_decrypt(response.data.data))
                                        {
                                                if (Helper.encypt_decrypt(response.data.data).length > 10) {
                                                        setShowKeyWords(Helper.encypt_decrypt(response.data.data).slice(0, 10))
                                                } else {
                                                        setShowKeyWords(Helper.encypt_decrypt(response.data.data))
                                                }
                                        }          
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                } else if (response.data.code === 400) {
                                        setShowError(true)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        useEffect(() => {
                if (cookies.skip != 0) {
                        globalSearch()
                        globalSearchApi()
                }
        }, [])

        function logoutalldevices() {
                const logoutdevice = {
                        method: "POST",
                        url: Helper.getWsUrl(cookies.skip) + `/otp/logout_from_all/1/?web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                }
                axios(logoutdevice)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        navigate('/')
                                }
                                else if (response.data.code === 401) {
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((err) => {
                                console.log(err)
                        })
        }

        const globalSearchApiPost = (event) => {
                event.preventDefault();
                const getBillListUrl = {
                        method: "POST",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/global_search/?name=${valueForSearch}&web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                km: 15,
                                lat: lat != null ? lat : "28.6046254",
                                lng: lng != null ? lng : "77.3501306",
                        }
                };
                axios(getBillListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        console.log("data nov15: ", Helper.encypt_decrypt(response.data.data))
                                        setShowList(true)
                                        setList(Helper.encypt_decrypt(response.data.data))
                                        localStorage.setItem("search_keyWord", valueForSearch)
                                        navigate('/dashboard')
                                        globalSearch()
                                        globalSearchApi()
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                } else if (response.data.code === 204) {
                                        setblankList(true)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const setKeyNameFunc = (shopName, itemName) => {
                if (itemName != null) {
                        setividforproductpage(itemName)
                        shoprodctpage(true)
                        navigate('/shop/product')
                } else {
                        setKeyName(shopName)
                        navigate('/dashboard')
                }
        }

        return (
                <nav className="navbar navbar-light navbarweb nvhead  p-1" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1) !important" }} >
                        <div className="container p-0 w-100 ">
                                <div className="logo-image1 pl-1">
                                        <img src={require("../Assets/Images/enbill.png")} width={"100%"} />
                                </div>
                                {/* {(location.pathname === "/dashboard") ?
                                        <div className="dropdown dropdwnbtt">
                                                <button className=" bg-transparent border-0 dropdown-toggle p-1 w-100 m-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { setselectmode(" Select mode"); }}>
                                                        {selectmode}
                                                </button>
                                                <ul className="dropdown-menu b2bdropdown m-0" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item pl-2" href="#" onClick={()=>{setselectmode("B2B"); document.querySelector(".dropdwnbtt").style.border="1px solid rgba(0, 0, 0, 0)";document.querySelector(".dropdwnbtt").style.boxShadow=" 0px 4px 8px rgba(0, 0, 0, 0.0)";setb2b(true) }}>{b2b1}</a></li>
                                                        <li><a className="dropdown-item pl-2 pb-0" href="#" onClick={()=>{setselectmode("B2C");document.querySelector(".dropdwnbtt").style.border="1px solid rgba(0, 0, 0, 0)";document.querySelector(".dropdwnbtt").style.boxShadow=" 0px 4px 8px rgba(0, 0, 0, 0.0)"; setb2b(false)}}>{b2c1}</a></li>
                                                </ul>
                                        </div>
                                        : null} */}
                                <div className="d-flex" style={{ position: "relative" }}>
                                        <div className="dropdown ">
                                                <form onSubmit={(event) => { globalSearchApiPost(event); }} >
                                                        <input style={{ marginLeft: "0px", backgroundColor: "F9F9F9", width: "45vw", height: "48px", autoComplete: "off" }} className=" dropdown-toggle me-2 bg-light ddsearch" value={inputKey} type="search" placeholder="Search" autoComplete="off" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" onInput={() => { setshowcrossbtn(true) }} onClick={() => { navigate('/dashboard') }} onChange={(event) => { handleChange(event); setInputKey(event.target.value) }} />
                                                        {showcrossbtn ?
                                                                <button className="bg-transparent border-0   mt-1 btnsearchar" style={{ backgroundColor: "F9F9F9", position: "absolute", right: "5px", top: "7px", }} href="#" role="button" onClick={() => { document.querySelector('#dropdownMenuLink').value = " " }}>
                                                                        <img src={require("../Assets/Images/croess.jpg")} />
                                                                </button>
                                                                :
                                                                <button className="bg-transparent border-0   mt-1 btnsearchar" style={{ backgroundColor: "F9F9F9", position: "absolute", right: "5px", top: "7px", }} href="#" role="button" >
                                                                        <img src={require("../Assets/Images/icon_search.jpg")} />
                                                                </button>
                                                        }
                                                        <ul className="dropdown-menu uldropdown border-top-0 " aria-labelledby="dropdownMenuLink">
                                                                {blankList ?
                                                                        <li>
                                                                                <div className="container d-flex justify-content-center w-100 mt-4 pt-2">
                                                                                        <div className="row  w-100 ">
                                                                                                <div className="col-12 w-100 border d-flex border-0">
                                                                                                        <p className="lastsearchedshop m-0 pt-2 pb-2">oops! no data found</p>
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                                        </li>
                                                                        :
                                                                        <>
                                                                                {showList ?
                                                                                        <li>
                                                                                                <div className="container d-flex ">
                                                                                                        <div className="justify-content-start w-100 no-gutters m-1">
                                                                                                                {list && list.map((value) => {
                                                                                                                        return (
                                                                                                                                <>
                                                                                                                                        <div className="col d-flex justify-content-start p-0">
                                                                                                                                                <button onClick={() => { setKeyNameFunc(value.name, value.item_id) }} style={{ background: "none", border: "none" }} >{value.name}</button>
                                                                                                                                        </div>
                                                                                                                                </>
                                                                                                                        )
                                                                                                                })}
                                                                                                        </div>
                                                                                                </div>
                                                                                        </li>
                                                                                        :
                                                                                        <>
                                                                                                {showError ?
                                                                                                        <li>
                                                                                                                <div className="container d-flex justify-content-center w-100 mt-4 pt-2">
                                                                                                                        <div className="row  w-100 ">
                                                                                                                                <div className="col-12 w-100 border d-flex border-0">
                                                                                                                                        <p className="lastsearchedshop m-0 pt-2 pb-2">oops! no data found</p>
                                                                                                                                </div>
                                                                                                                        </div>

                                                                                                                </div>
                                                                                                        </li>
                                                                                                        :
                                                                                                        <>
                                                                                                                <li>
                                                                                                                        <div className="container d-flex ">
                                                                                                                                <div className="row  w-100 no-gutters ">
                                                                                                                                        <p className="popularsearch mt-2 ">Popular Search</p>
                                                                                                                                </div>
                                                                                                                                <div className="row justify-content-start w-100 no-gutters m-1">
                                                                                                                                        {showKeyWords.map((value) => {
                                                                                                                                                return (
                                                                                                                                                        <>
                                                                                                                                                                <div className="col d-flex justify-content-start p-0">
                                                                                                                                                                        <button onClick={() => { setValueForSearch(value.keyword); setInputKey(value.keyword); setList(true) }} className="searchlinksbtn bg-light">{value.keyword}</button>
                                                                                                                                                                </div>
                                                                                                                                                        </>
                                                                                                                                                )
                                                                                                                                        })}
                                                                                                                                </div>
                                                                                                                        </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                        <div className="container d-flex justify-content-center w-100 mt-4 pt-2">
                                                                                                                                <div className="row  w-100 ">
                                                                                                                                        {recentKeys && recentKeys.map((value) => {
                                                                                                                                                return (
                                                                                                                                                        <>
                                                                                                                                                                <div className="col-12 w-100 border d-flex d border-left-0 border-right-0 border-bottom-0">
                                                                                                                                                                        <button onClick={() => { setValueForSearch(value.keyword); setInputKey(value.keyword); showList(true) }} className="lastsearchedshop m-0 pt-2 pb-2" style={{ cursor: "pointer", background: "none", border: "none" }}>{value.keyword}</button>
                                                                                                                                                                </div>
                                                                                                                                                        </>
                                                                                                                                                )
                                                                                                                                        })}

                                                                                                                                </div>

                                                                                                                        </div>
                                                                                                                </li>
                                                                                                        </>
                                                                                                }
                                                                                        </>}
                                                                        </>


                                                                }



                                                        </ul>

                                                </form>
                                        </div>
                                </div>
                                <div className="center" style={{ height: "49px", width: "49px", backgroundColor: "#F0F0F0", borderRadius: "50%", }} >

                                        {/* {denyLocation ?  */}
                                        <Link to="/location"><img src={require("../Assets/Images/cart.png")} /></Link>

                                                {/* :

                                                <div onClick={() => { alert("you have to allow location for this") }}>
                                                        <img src={require("../Assets/Images/cart.png")} />
                                                </div>

                                        } */}
                                </div>
                                <div className="center" style={{ height: "49px", width: "49px", backgroundColor: "#F0F0F0", borderRadius: "50%", }} >
                                        <Link to="/cart"><img src={require("../Assets/Images/cart1.png")} /></Link>
                                </div>
                                <div className="center" style={{ height: "49px", width: "49px", backgroundColor: "#F0F0F0", borderRadius: "50%", }} >
                                        <Link to="/profile"><img src={require("../Assets/Images/profile.png")} /></Link>
                                </div>
                                {/* nameaccountdiv */}
                                <div style={{ textAlign: "left", paddingTop: "20px", paddingRight: "10px" }}  >
                                        <div style={{ fontWeight: "600", textAlign: "right" }} className="bg-transparent border-0 nameclass">
                                                {(accountname == "" || accountname == null) ? "Name" : accountname}
                                                <br></br>
                                                <div className="btn-group">
                                                        <button type="button" className="font-weight-bold pl-0 border-0 bg-transparent  dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" >
                                                                Account
                                                        </button>
                                                        {/* dropdown */}
                                                        <div className="dropdown-menu dropdownalignent">
                                                                {/* mobile view       */}
                                                                {/* wb view */}
                                                                <div className="webcontainerformobileview1 ml-auto   border">
                                                                        <div className="row no-gutters  ">
                                                                                <div className="col-12 ">
                                                                                        <div className="row no-gutters hwprofilepicweb  pt-3 ">
                                                                                                <div className="col-12   ">
                                                                                                        <div className="media">
                                                                                                                {/* {
                                                                                                                        (inputfiledtatas.c_logo_url == null) ? <img className="mr-3 ml-3 icon3 img2webaccount" src={require('../Assets/Images/log.png')} alt="Generic placeholder image"




                                                                                                                        /> : */}
                                                                                                                <img className="mr-3 ml-3 icon3 img2webaccount" src={require('../Assets/Images/log.png')}
                                                                                                                        // src={inputfiledtatas.c_logo_url} 
                                                                                                                        alt="Generic placeholder image"
                                                                                                                // onError={(e) =>
                                                                                                                //         (e.target.onerror == null)(
                                                                                                                //                 (e.target.src = require('../Assets/Images/log.png'))
                                                                                                                //         )
                                                                                                                // }


                                                                                                                />
                                                                                                                {/* } */}

                                                                                                                <div className="media-body">
                                                                                                                        <h5 className=" nameh">
                                                                                                                                {(inputfiledtatas.name == null || inputfiledtatas.name == "") ? "Name" : inputfiledtatas.name}
                                                                                                                        </h5>
                                                                                                                        <p className="numberphone">{inputfiledtatas.mobile_number}</p>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                {/* <div className="firstpic12accountweb">
                                                                                                        <div className='icon2accountweb'>
                                                                                                                <div id="xslUploadaccountweb">
                                                                                                                        <label htmlFor="file-input24" className="hwtb2headprofileweb ">
                                                                                                                                <button className="btn importar1webaccount p-0" type="button">
                                                                                                                                        <img src={require('../Assets/Images/icon212.jpg')} className='rounded-circle  imgnew2webaccount' />
                                                                                                                                </button>
                                                                                                                        </label>
                                                                                                                        <input className="file-input22headerweb" id="file-input24" type="file" onChange={previewFile} />
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div> */}
                                                                                        </div>
                                                                                        <div className="row ml-1 mr-1 mt-3  ">
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1 ">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/icon.png')} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/profile" className="text-dark text-decoration-none">Profile</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1 ">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/iconagain.svg').default} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/bookingstatus" className="text-dark text-decoration-none">Booking Status</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/icon112.jpg')} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3 mr-1"><Link to="/billrecords" className="text-dark text-decoration-none">Bill Records</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className="hwicon11" src={require('../Assets/Images/icon113.jpg')} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/udhaar" className="text-dark text-decoration-none">Udhaar</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/iconfavv.svg').default} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/favourite" className="text-dark text-decoration-none">Favorites</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/customer icdsdons_location (1).png')} style={{height:"25px", width:"25px"}} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/address" className="text-dark text-decoration-none">Address</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                        <hr className="mt-0 mb-0" />
                                                                                        <div className="row ml-1 mr-1 mt-3 bgcol ">
                                                                                                <div className="col-12 m-1 p-0 ">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className="hwicon11" src={require('../Assets/Images/icon438467.svg').default} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/gethelp" className="text-dark text-decoration-none">Help & Support</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className=" hwicon11" src={require('../Assets/Images/icon543654.svg').default} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/termsandconditions" className="text-dark text-decoration-none">Terms and Conditions</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="col-12 m-1 p-0">
                                                                                                        <div className="media modeltransform1">
                                                                                                                <img className=" hwicon11" src={require("../Assets/Images/icon43456.svg").default} alt="Generic placeholder image" />
                                                                                                                <div className="media-body">
                                                                                                                        <h5 className="mt-0 bs11 text-left ml-3"><Link to="/privacypolicy" className="text-dark text-decoration-none">Privacy Policy</Link></h5>
                                                                                                                </div>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                        <div className="row ml-0 mr-0  hwbutt1  ">
                                                                                                <button className="btn-block bg-transparent nuw pt-3 pb-1" onClick={() => { logoutalldevices() }}>Logout</button>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </nav>
        );
};
export default Header;