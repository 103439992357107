import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import "./Newcss.css"
import Headerformobile2 from '../Header/Headerformobile2';
import axios from "axios";
import { useCookies } from 'react-cookie'
import { useEffect } from 'react';
import Helper from '../util/Helper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from 'moment';
import Header from '../Header/Header';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
});

const Cartcomponent = ({ setKeyName, merchantIdForBookedItems }) => {

        const navigate = useNavigate()
        const [cookies, setCookie, removeCookie] = useCookies(['token']);

        const [cartCard, setCartCard] = useState([]);
        const [totalCartData, setTotalCartData] = useState([]);
        const [totalCartAmount, setTotalCartAmount] = useState(null);
        const [bbId, setBbId] = useState(null);
        const [booknow, setbooknow] = useState(false)
        const [lazyLoading, setLazyLoading] = useState(true);
        const [bookingAddressList, setBookingAddressList] = useState([])
        const [open, setOpen] = useState(false);
        const [selectAddressIndex, setSelectAddressIndex] = useState(0)
        const [dateValue, setDateValue] = useState(null);
        const [timeValue, setTimeValue] = useState(null);
        const [openTimeSlot, setOpenTimeSlot] = useState(false);
        const [showDateTimeSec, setShowDateTimeSec] = useState (false)
        const [getDeliveredOpt, setGetDeliveredOpt] = useState(null)
        const [showErrorDateTime, setShowErrorDateTime] = useState(false)
        const [showDeliveryOption, setShowDeliveryOption] = useState(false)

        const theme = useTheme();
        const [openDrawer, setOpenDrawer] = useState(false);
        const [openWeb, setOpenWeb] = useState(false);
        const [openTimeSlotWeb, setOpenTimeSlotWeb] = useState(false);
    
        const handleDrawerOpen = () => {
            setOpenDrawer(true);
        };
    
        const handleDrawerClose = () => {
            setOpenDrawer(false);
        };

        const DrawerHeader = styled('div')(({ theme }) => ({
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
                justifyContent: 'flex-start',
        }));
        
        
        var totalCartAmtCalculation = 0;

        var date=new Date();
        var tdate=date.getDate();
        var year = date.getFullYear();      
        if(tdate < 10){
          tdate = "0"+ tdate;
        }      
        var month=date.getMonth()+1;      
        if(month < 10){
            month = "0"+ month;
        }      
        var minDate = year + "-"+month+"-"+tdate;

        useEffect(() => {
                const disablePinchZoom = (e) => {
                        if (e.touches.length > 1) {
                                e.preventDefault()
                        }
                }
                document.addEventListener("touchmove", disablePinchZoom, { passive: false })
                return () => {
                        document.removeEventListener("touchmove", disablePinchZoom)
                }
        }, [])

        useEffect(() => {
                if(merchantIdForBookedItems && merchantIdForBookedItems != null) {
                        setKeyName(null)
                        apiCallFunc()
                        getAddressListToSelect()
                } else {
                        navigate('/cart')
                }
        }, [merchantIdForBookedItems])

        const apiCallFunc = () => {
                const getBillListUrl = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/booking/customer_get_booking_bag/${merchantIdForBookedItems}/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getBillListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        console.log("Detail :",Helper.encypt_decrypt(response.data.data))
                                        setTotalCartData(Helper.encypt_decrypt(response.data.data))
                                        setCartCard(Helper.encypt_decrypt(response.data.data).BagDetails)
                                        setbooknow(true)
                                        setLazyLoading(false)
                                } else if (response.data.code === 204) {
                                        setCartCard([])
                                        setTotalCartData([])
                                        setTotalCartAmount(0);
                                        navigate('/cart')
                                        setbooknow(false)
                                        setLazyLoading(false)
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                console.log(error);
                        })
        }

        useEffect(() => {
                if (cartCard && cartCard.length > 0) {
                        cartCard.map((value) => {
                                totalCartAmtCalculation += parseFloat(value.quantity * value.item_amount)
                        })
                        setTotalCartAmount(totalCartAmtCalculation)
                }
        }, [cartCard])

        const postToBooking = () => {
                if(dateValue && dateValue != null && timeValue && timeValue != null) {
                        const getBillListUrl = {
                                method: "POST",
                                url: Helper.getWsUrl(cookies.skip) + `/booking/customer_add_booking/${merchantIdForBookedItems}/?web=1`,
                                headers: { "Content-Type": "application/json", 'token': cookies.token },
                                data: {
                                        address_id: bookingAddressList && bookingAddressList[selectAddressIndex].address_id,
                                        customer_expected_date: `${moment(dateValue).format('yyyy-MM-DD')}(${timeValue})`,
                                        pickup_drop: getDeliveredOpt && getDeliveredOpt,
                                }
                        };
                        axios(getBillListUrl)
                                .then((response) => {
                                        if (response.data.code === 200) {
                                                navigate('/bookingstatus')
                                        } else if (response.data.code === 401) {
                                                removeCookie("logged_in")
                                                removeCookie("token")
                                                setTimeout(() => {
                                                        navigate('/')
                                                        localStorage.setItem("isAuthenticated", false)
                                                        localStorage.removeItem("isAuthenticated")
                                                }, 1000)
                                        }
                                })
                                .catch((error) => {
                                        alert(error);
                                })
                } else {
                        setShowErrorDateTime(true)
                }
        }

        const changeItemQty = (qty, bbId) => {
                const getBillListUrl = {
                        method: "PATCH",
                        url: Helper.getWsUrl(cookies.skip) + `/booking/quantity_status_change/${bbId}?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                quantity: qty
                        }
                };
                axios(getBillListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        apiCallFunc()
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        function deleteItem(bbId) {
                const remveproduct = {
                        method: "DELETE",
                        url: Helper.getWsUrl(cookies.skip) + `/booking/delete_bag_list_items/${bbId}?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(remveproduct)
                        .then((response) => {
                                apiCallFunc()
                                if (response.data.code === 200) {
                                        apiCallFunc()
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })

        }

        function addtofav(itemid, ivid, shpid) {
                const addtofavrite = {
                        method: "post",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/add_favorite_shop/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                "item_id": itemid,
                                "iv_id": ivid,
                                "shop_id": shpid
                        }
                };
                axios(addtofavrite)
                        .then((response) => {
                                if (response.data.code === 201) {
                                        apiCallFunc()
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        function removefavshop(itemid, ivid, shpid) {
                const removefav = {
                        method: "delete",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/remove_favorite_shop/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                "item_id": itemid,
                                "iv_id": ivid,
                                "shop_id": shpid
                        }
                };
                axios(removefav)
                        .then((response) => {
                                if (response.data.code === 202) {
                                        apiCallFunc()
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const getAddressListToSelect = () => {
                const getAddressListUrl = {
                    method: "GET",
                    url: Helper.getWsUrl(cookies.skip) + `/booking/get_customer_added_address_new/?web=1`,
                    headers: { "Content-Type": "application/json", 'token': cookies.token },
                };
                axios(getAddressListUrl)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        // console.log("address list :",Helper.encypt_decrypt(response.data.data))
                                        setBookingAddressList(Helper.encypt_decrypt(response.data.data))
                                        getDeliveryOptionOnDist(Helper.encypt_decrypt(response.data.data)[0].lat, Helper.encypt_decrypt(response.data.data)[0].lng)
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const getDeliveryOptionOnDist = (lat, lng) => {
                const getOptionForBooking = {
                        method: "POST",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_shop_catalogue_header/${merchantIdForBookedItems}/?web=1`,
                        headers: { "Content-Type": "application/json", 'token': cookies.token },
                        data: {
                                lat: lat,
                                lng: lng
                        }
                };
                axios(getOptionForBooking)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        // console.log("pickup  :",Helper.encypt_decrypt(response.data.data))
                                        if(Helper.encypt_decrypt(response.data.data).alert === 0) {
                                                setShowDeliveryOption(true)
                                        } else {
                                                setShowDeliveryOption(false)
                                        }
                                } else if (response.data.code === 401) {
                                        removeCookie("logged_in")
                                        removeCookie("token")
                                        setTimeout(() => {
                                                navigate('/')
                                                localStorage.setItem("isAuthenticated", false)
                                                localStorage.removeItem("isAuthenticated")
                                        }, 1000)
                                } else {
                                        setShowDeliveryOption(false)
                                }
                        })
                        .catch((error) => {
                                alert(error);
                        })
        }

        const handleClickOpen = () => {
                setOpen(true);
        };
        const handleClose = () => {
                setOpen(false);
        };

        const handleClickOpenTime = () => {
                setOpenTimeSlot(true);
        };
        const handleCloseTime = () => {
                setOpenTimeSlot(false);
        };

        const handleClickOpenWeb = () => {
                setOpenWeb(true);
        };
        const handleCloseWeb = () => {
                setOpenWeb(false);
        };

        const handleClickOpenTimeWeb = () => {
                setOpenTimeSlotWeb(true);
        };
        const handleCloseTimeWeb = () => {
                setOpenTimeSlotWeb(false);
        };

        return (
                <>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-sm">
                                        <div class="modal-content" style={{ textAlign: "center", borderRadius: "15px" }}>
                                                <div class="modal-body">
                                                        <p style={{ fontWeight: "500", fontsize: "18px" }}>Do you want to delete this item ?</p>
                                                </div>
                                                <div class="modal-footer" style={{ padding: "0 16px", border: "none", margin: "0" }}>
                                                        <button    type="button" style={{ border: "none", background: "none", color: "#7a7a7a", fontWeight: "600", marginBottom: "20px" }} class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                                        <button onClick={()=>{deleteItem(bbId)}} style={{ border: "none", background: "none", color: "#ea7d3d", fontWeight: "600", marginBottom: "20px" }} type="button" data-bs-dismiss="modal">Yes</button>
                                                </div>
                                        </div>
                                </div>
                        </div>

                        <Headerformobile2 pagename="Bag (Item List)" navigate={navigate} />
                        <Header />
                        <div className='row no-gutters mtp100' >
                                <div className='path_direction dashbbdbooking  ' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                                        <p >
                                                <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                                                &gt;
                                                <Link to="/cart" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Cart</Link>
                                        </p>
                                </div>
                        </div>
                                
                        <div className='full component cartmargintiop45' id='cartItemsCard' >
                                <div className='row widthofmianrow' id='cartItemsCardInside'>
                                        <div className='col-lg-8 col-12 pl-3 pr-3 marginbottomforcartweb'>
                                                {lazyLoading ? 
                                                        <Skeleton height={"125px"} width={"100%"} count={6} ></Skeleton> 
                                                :
                                                        <>
                                                                {cartCard && cartCard.map((value, index) => {
                                                                        return (
                                                                                <div className='row p-lg-3  ' id="product1" key={index} >
                                                                                        <div className='col-12  rightpaddingremovalbag p-0 mb-2 '>
                                                                                                <div className="media bordercard shadow">
                                                                                                        <img className="mr-3 productphotosize rounded " src={value.featured_img_url} style={{ position: "relative" }}
                                                                                                                onError={(e) => {
                                                                                                                        if(e.target.onerror === null) {
                                                                                                                                (e.target.src = require('../Assets/Images/log.png'))
                                                                                                                        }
                                                                                                                }} />
                                                                                                        <div className=' maindivforheart3z1 hideiconinwebpageofcartcomponent' >
                                                                                                                {(value.is_favorite === 0) ?
                                                                                                                        <img src={require('../Assets/Images/whiteheart.png')} className='whiteimagehw23z1' id="whiteheartz1" onClick={() => { addtofav(value.item_id, value.iv_id, value.contact_id) }}></img>
                                                                                                                :
                                                                                                                        <img src={require('../Assets/Images/orangeheart.png')} className='whiteimagehw3z1' id="orangeheartz1" onClick={() => { removefavshop(value.item_id, value.iv_id, value.contact_id) }}></img>
                                                                                                                }
                                                                                                        </div>
                                                                                                        <div className="media-body pt-2 pb-2 pr-2">
                                                                                                                <div className='d-flex justify-content-between '>
                                                                                                                        <p className="productnamebag text-capitalize  m-0">{value.item_name}</p>        
                                                                                                                        <button type="button" className="border bg-transparent border-0 pr-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                                                                <img src={require('../Assets/Images/Trash can.png')} className="trashfontsizemobile " onClick={() => { setBbId(value.bb_id) }} />
                                                                                                                        </button>  
                                                                                                                </div>

                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                        <p className='fontsizeofunit'>Unit : <span>{value.min_qty != 0 ? value.min_qty : 1} &nbsp;&nbsp; {value.miu_name != '--NA--' && value.miu_name != null ? value.miu_name : ''}</span></p>
                                                                                                                        <p>₹ {value.item_amount}</p>
                                                                                                                </div>

                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                        <div style={{ width: "154px", height: "33px" }}>
                                                                                                                                <div style={{ height: "42px", backgroundColor: "rgba(118,118,128,0.12)", borderRadius: "5px" }} className="inputboxdiv6777 border">
                                                                                                                                        <button className="  incbutton445  border-top-0 border-bottom-0 border-left-0 " onClick={() => {
                                                                                                                                                if (value.quantity > 1) {
                                                                                                                                                        changeItemQty(value.quantity - 1, value.bb_id)
                                                                                                                                                } else {
                                                                                                                                                        deleteItem(value.bb_id)
                                                                                                                                                }
                                                                                                                                        }}>-</button>
                                                                                                                                        <p style={{ display: "inline" }} className="border border-top-0 border-bottom-0  indeccounter">{value.quantity}</p>
                                                                                                                                        <button className="  incbutton445 border-top-0 border-right-0 border-bottom-0" onClick={() => { changeItemQty(value.quantity + 1, value.bb_id); }}>+</button>
                                                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        <p className="increasingvalue23">₹ {value.quantity * value.item_amount}</p>
                                                                                                                </div>                                                                                                                
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        )
                                                                })}
                                                        </>
                                                }
                                        </div>

                                        <div className='col-md-4 col-12 d-lg-block d-none mt-4'>
                                                <div className="container">
                                                        <div className="row">
                                                                <div className="col-12 p-0">
                                                                        <div className="container subtotaborder  p-2">
                                                                                <div className="row justify-content-around">
                                                                                        <div className="col-4 text-left p-0">
                                                                                                <span><b>Sub Total </b><span>({totalCartData && totalCartData.Total_Items})</span></span>
                                                                                        </div>
                                                                                        <div className="col-6 text-right">
                                                                                                <span><b>₹{totalCartAmount && totalCartAmount}</b></span>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                        <Link ><button type="button" className="booknowbutton text-white" onClick={() => handleDrawerOpen()} >Book Now </button></Link>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>                                
                        </div>

                        {booknow ?
                                <div className='bookNowBtnForMobile'>
                                        <div onClick={handleClickOpen} className='deliveryAddress'>
                                                {bookingAddressList && bookingAddressList.length > 0 ?
                                                        <p>{bookingAddressList && bookingAddressList[selectAddressIndex].near_by},&nbsp;{bookingAddressList && bookingAddressList[selectAddressIndex].address}</p>
                                                :
                                                        <p>You are in a new location!</p>
                                                }
                                                <img src={require('../Assets/Images/customer icons_location.svg').default} />
                                        </div>
                                        <div style={{display: bookingAddressList && bookingAddressList.length > 0 ? 'none' : 'block'}} className='addAddressBtnForBooking'>
                                                <button onClick={() => {navigate('/address/location')}}>Add Address to Continue</button>
                                        </div>
                                        <div style={{display: !showDateTimeSec ? 'none' : 'flex'}} className='dateForBooking'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker
                                                                label="Date"
                                                                minDate={minDate}
                                                                value={dateValue}
                                                                onChange={(newValue) => {
                                                                        setDateValue(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                        />
                                                </LocalizationProvider>
                                                <TextField style={{marginLeft:'10px'}} value={timeValue} label="Time" onClick={handleClickOpenTime} />
                                        </div>
                                        <small style={{color:"#ea7d3d", width:"100%", textAlign:"left", fontWeight:"500", display: showDeliveryOption && totalCartData && totalCartData.min_booking_amount <= totalCartData.amount2 ? "none" : "block"}}>* Minimum amount required for delivery ₹ {totalCartData && totalCartData.min_booking_amount}</small>
                                        <div style={{display: bookingAddressList && bookingAddressList.length > 0 ? 'flex' : 'none'}} className='pickUpDeliverOpt'>
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                        {!showDeliveryOption ? 
                                                                null 
                                                        : 
                                                                <>
                                                                        {totalCartData && totalCartData.min_booking_amount <= totalCartData.amount2 ? 
                                                                                <FormControlLabel id='deliveryAvail' className='' value="deliver" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setGetDeliveredOpt(0); setShowDateTimeSec(true); }} />} label="Deliver" />
                                                                        : null }
                                                                </>                
                                                        }
                                                        <FormControlLabel value="pickup" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setGetDeliveredOpt(1); setShowDateTimeSec(true); }} />} label="PickUp" />
                                                </RadioGroup>
                                                <button onClick={() => {postToBooking()}}> 
                                                        <small style={{fontWeight:"500"}}>Total : ₹ {totalCartData && totalCartData.amount2}</small> 
                                                        <p style={{margin:"0"}}>Confirm & Order</p>
                                                </button>
                                        </div>
                                        <p style={{display: !showErrorDateTime ? 'none' : dateValue && dateValue != null && timeValue && timeValue != null ? 'none' : 'block', margin:'0 0 0 10px'}} className="errorMsg" >Complete order instructions!</p>
                                </div>
                        :
                                null
                        }

                        <Dialog PaperProps={{
                                style: {
                                        position: 'absolute',
                                        bottom: '0px',
                                        color: 'black',
                                        width: '100%',
                                        height: bookingAddressList && bookingAddressList.length >= 3 ? '58%' : bookingAddressList.length === 2 ? '43%' : bookingAddressList.length === 1 ? '30%' : '20%',
                                        maxWidth: '100%',
                                        padding: "0 3% 3%",
                                        borderRadius: "15px 15px 0 0",
                                        margin: '0'
                                },
                                }} open={open} onClose={handleClose} TransitionComponent={Transition} >
                                <IconButton
                                        edge="start"
                                        onClick={handleClose}
                                        aria-label="close">
                                        <ExpandMoreIcon />
                                </IconButton>
                                <Toolbar>
                                        <div className='selectDeliveryAddress'>
                                                <h5>Select Address</h5>
                                                <div className='addressSelectList'>
                                                        {bookingAddressList && bookingAddressList.map((value, index) => {
                                                                return(
                                                                        <>
                                                                                <div onClick={() => {setSelectAddressIndex(index); getDeliveryOptionOnDist(value.lat, value.lng); handleClose();}} className='addressCard' style={{boxShadow:'none', margin:'1px 0'}}>
                                                                                        <h6>{value.reciver_name} <span>{value.type != 2 ? value.type != 1 ? 'Home' : 'Work' : 'Other'}</span></h6>
                                                                                        <p>{value.near_by},&nbsp;{value.address}</p>
                                                                                        <p>Mobile Number : <small>{value.reciver_mobile_number}</small></p>
                                                                                </div>
                                                                                <hr style={{margin:0}}/>
                                                                        </>
                                                                )                
                                                        })}
                                                </div>
                                                <div className='addressSelectListBtn'>
                                                        <button onClick={() => {navigate('/address/location')}}>Add New Address</button>
                                                </div>
                                        </div>
                                </Toolbar>
                        </Dialog>

                        <Dialog PaperProps={{
                                style: {
                                        position: 'absolute',
                                        bottom: '0px',
                                        color: 'black',
                                        width: '100%',
                                        height: '30%',
                                        maxWidth: '100%',
                                        padding: "0 3% 3%",
                                        borderRadius: "15px 15px 0 0",
                                        margin: '0'
                                },
                                }} open={openTimeSlot} onClose={handleCloseTime} TransitionComponent={Transition} >
                                <IconButton
                                        edge="start"
                                        onClick={handleCloseTime}
                                        aria-label="close">
                                        <ExpandMoreIcon />
                                </IconButton>
                                <Toolbar>
                                        <div className='selectDeliveryAddress'>
                                                <h5>Select an Time Slot (for) </h5>
                                                <div className='timeSlotCard'>
                                                        <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                <FormControlLabel value="9 AM - 12 AM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('9 AM - 12 AM'); handleCloseTime();}} />} label="9 AM - 12 AM" />
                                                                <FormControlLabel value="2 PM - 6 PM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('2 PM - 6 PM'); handleCloseTime();}} />} label="2 PM - 6 PM" />
                                                                <FormControlLabel value="7 PM - 11 PM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('7 PM - 11 PM'); handleCloseTime();}} />} label="7 PM - 11 PM" />
                                                        </RadioGroup>
                                                </div>
                                        </div>
                                </Toolbar>
                        </Dialog>

                        <Drawer sx={{ width: "25%", flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                width: "25%",
                                position: "relative",
                                boxSizing: 'border-box',
                                boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
                                position:"fixed", top:"0", right:"0",
                                },
                                }} variant="persistent" anchor="right" open={openDrawer}>
                                <DrawerHeader>
                                        <IconButton onClick={handleDrawerClose}>
                                                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                        </IconButton>
                                </DrawerHeader>
                                <div className='bookNowBtnForWeb'>
                                        <div onClick={handleClickOpenWeb} className='deliveryAddressForWeb'>
                                                {bookingAddressList && bookingAddressList.length > 0 ?
                                                        <p>{bookingAddressList && bookingAddressList[selectAddressIndex].near_by},&nbsp;{bookingAddressList && bookingAddressList[selectAddressIndex].address}</p>
                                                :
                                                        <p>You are in a new location!</p>
                                                }
                                                <img src={require('../Assets/Images/customer icons_location.svg').default} />
                                        </div>
                                        <div style={{display: bookingAddressList && bookingAddressList.length > 0 ? 'none' : 'block'}} className='addAddressBtnForBookingWeb'>
                                                <button onClick={() => {navigate('/address/location')}}>Add Address to Continue</button>
                                        </div>
                                        <div style={{display: !showDateTimeSec ? 'none' : 'flex'}} className='dateForBookingWeb'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker
                                                                label="Date"
                                                                minDate={minDate}
                                                                value={dateValue}
                                                                onChange={(newValue) => {
                                                                        setDateValue(newValue);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                        />
                                                </LocalizationProvider>
                                                <TextField style={{marginLeft:'10px'}} value={timeValue} label="Time" onClick={handleClickOpenTimeWeb} />
                                        </div>

                                        <div style={{display: bookingAddressList && bookingAddressList.length > 0 ? 'flex' : 'none'}} className='pickUpDeliverOptWeb'>
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                        {!showDeliveryOption ? 
                                                                null 
                                                        : 
                                                                <>
                                                                        {totalCartData && totalCartData.min_booking_amount <= totalCartData.amount2 ? 
                                                                                <FormControlLabel id='deliveryAvail' className='' value="deliver" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setGetDeliveredOpt(0); setShowDateTimeSec(true); }} />} label="Deliver" />
                                                                        : null }
                                                                </>  
                                                        }
                                                        <FormControlLabel value="pickup" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setGetDeliveredOpt(1); setShowDateTimeSec(true); }} />} label="PickUp" />
                                                </RadioGroup>
                                        </div>
                                        <div style={{display: bookingAddressList && bookingAddressList.length > 0 ? 'block' : 'none'}}  className='addAddressBtnForBookingWeb'>
                                                <button onClick={() => {postToBooking()}}>Confirm and Order</button>
                                        </div>
                                        <p style={{display: !showErrorDateTime ? 'none' : dateValue && dateValue != null && timeValue && timeValue != null ? 'none' : 'block', margin:'0 0 0 10px'}} className="errorMsg" >Complete order instructions!</p>
                                

                                        <Dialog PaperProps={{
                                                style: {
                                                        position: 'absolute',
                                                        bottom: '0px',
                                                        right:"0",
                                                        color: 'black',
                                                        width: '25%',
                                                        height: bookingAddressList && bookingAddressList.length >= 3 ? '60%' : bookingAddressList.length === 2 ? '45%' : bookingAddressList.length === 1 ? '32%' : '22%',
                                                        maxWidth: '100%',
                                                        padding: "10px",
                                                        borderRadius: "15px 15px 0 0",
                                                        margin: '0'
                                                },
                                                }} open={openWeb} onClose={handleCloseWeb} TransitionComponent={Transition} >
                                                <IconButton
                                                        edge="start"
                                                        onClick={handleCloseWeb}
                                                        aria-label="close">
                                                        <ExpandMoreIcon />
                                                </IconButton>
                                                <Toolbar>
                                                        <div className='selectDeliveryAddress'>
                                                                <h5>Select Address</h5>
                                                                <div className='addressSelectList'>
                                                                        {bookingAddressList && bookingAddressList.map((value, index) => {
                                                                                return(
                                                                                        <>
                                                                                                <div onClick={() => {setSelectAddressIndex(index); getDeliveryOptionOnDist(value.lat, value.lng); handleCloseWeb();}} className='addressCard' style={{boxShadow:'none', margin:'1px 0'}}>
                                                                                                        <h6>{value.reciver_name} <span>{value.type != 2 ? value.type != 1 ? 'Home' : 'Work' : 'Other'}</span></h6>
                                                                                                        <p>{value.near_by},&nbsp;{value.address}</p>
                                                                                                        <p>Mobile Number : <small>{value.reciver_mobile_number}</small></p>
                                                                                                </div>
                                                                                                <hr style={{margin:0}}/>
                                                                                        </>
                                                                                )                
                                                                        })}
                                                                </div>
                                                                <div className='addressSelectListBtn'>
                                                                        <button onClick={() => {navigate('/address/location')}}>Add New Address</button>
                                                                </div>
                                                        </div>
                                                </Toolbar>
                                        </Dialog>

                                        <Dialog PaperProps={{
                                                style: {
                                                        position: 'absolute',
                                                        bottom: '0px',
                                                        right:"0",
                                                        color: 'black',
                                                        width: '25%',
                                                        height: '30%',
                                                        maxWidth: '100%',
                                                        padding: "10px",
                                                        borderRadius: "15px 15px 0 0",
                                                        margin: '0'
                                                },
                                                }} open={openTimeSlotWeb} onClose={handleCloseTimeWeb} TransitionComponent={Transition} >
                                                <IconButton
                                                        edge="start"
                                                        onClick={handleCloseTimeWeb}
                                                        aria-label="close">
                                                        <ExpandMoreIcon />
                                                </IconButton>
                                                <Toolbar>
                                                        <div className='selectDeliveryAddress'>
                                                                <h5>Select an Time Slot (for) </h5>
                                                                <div className='timeSlotCard'>
                                                                        <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                                <FormControlLabel value="9 AM - 12 AM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('9 AM - 12 AM'); handleCloseTimeWeb();}} />} label="9 AM - 12 AM" />
                                                                                <FormControlLabel value="2 PM - 6 PM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('2 PM - 6 PM'); handleCloseTimeWeb();}} />} label="2 PM - 6 PM" />
                                                                                <FormControlLabel value="7 PM - 11 PM" control={<Radio style={{color:'#ea7d3d'}} onClick={() => {setTimeValue('7 PM - 11 PM'); handleCloseTimeWeb();}} />} label="7 PM - 11 PM" />
                                                                        </RadioGroup>
                                                                </div>
                                                        </div>
                                                </Toolbar>
                                        </Dialog>
                                </div>
                        </Drawer>
                </>
        )
}

export default Cartcomponent;
