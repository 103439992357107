
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Profile.css";
import { Link } from 'react-router-dom';
import { unstable_generateUtilityClasses } from '@mui/utils';
import Footer from '../Footer/Footer';
import Headerformobile2 from '../Header/Headerformobile2';
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import S3 from "react-aws-s3"
import '../Header/Headerformobile2.css';
import Header from '../Header/Header';

window.Buffer = window.Buffer || require("buffer").Buffer

export default function Profile() {

        const navigate = useNavigate();
        const [cookies, setCookies, removeCookies] = useCookies(["token"])

        const [url2, seturl2] = useState(null)
        const [inputfiledtatas, setinputfiledtatas] = useState(null)
        const [MobileNumber, setMobileNumber] = useState()
        const [altMobileNumber, setaltMobileNumber] = useState();
        const [set_profile_image, sset_profile_image] = useState('');
        const [pincode, setpincode] = useState()
        const [stateofuser, setstateofuser] = useState(null)
        const [city, setcity] = useState([])
        const [settingcity, setsettingcity] = useState('')
        const [contactid, setcontactid] = useState('')
        const [editMerchantImg, seteditMerchantImg] = useState('')
        const [name1, setname1] = useState(null);
        const [address, setaddress] = useState(null);
        const [clogo, setclogo] = useState(null);
        const [namecross, setnamecross] = useState(false)
        const [altecrossmob, setaltecrossmob] = useState(false)
        const [pincrossmob, setpincrossmob] = useState(false)
        const [citycross, setcitycross] = useState(false)
        const [addresscross, setaddresscross] = useState(false)
        const [getalldata, setgetalldata] = useState('no image')
        const [contactIdForDeactivate, setContactIdForDeactivate] = useState(null)

        // function previewFile23() {
        //         const preview23 = document.querySelector('.imgnewprofile23');
        //         const file23 = document.querySelector('.flieinp1profile23').files[0];
        //         const reader = new FileReader();
        //         reader.addEventListener("load", () => {
        //                 preview23.src = reader.result;
        //         }, false);

        //         if (file23) {
        //                 reader.readAsDataURL(file23);
        //                 setclogo(1);
        //                 seteditMerchantImg(file23)
        //         }
        // }

        function logoutalldevices23() {
                const logoutdevice3 = {
                        method: "POST",
                        url: Helper.getWsUrl(cookies.skip) + `/otp/logout_from_all/1/?web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                }
                axios(logoutdevice3)
                        .then((response) => {
                                console.log(response)
                                if (response.data.code === 200) {
                                        navigate('/')                                        
                                }
                        })
                        .catch((err) => {
                                console.log(err)
                        })
        }

        useEffect(() => {
                getprofiledatafunction()
        }, [])

        function getprofiledatafunction() {
                const getprofileimageapistartdata = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/contact/get_contact/0/?app_type=3&web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                };
                axios(getprofileimageapistartdata)
                        .then((response) => {
                                if (response.data.code == 200) {
                                        setgetalldata(Helper.encypt_decrypt(response.data.data))
                                        sset_profile_image(Helper.encypt_decrypt(response.data.data));
                                        setaddress(Helper.encypt_decrypt(response.data.data).address)
                                        setinputfiledtatas(Helper.encypt_decrypt(response.data.data))
                                        if (Helper.encypt_decrypt(response.data.data).c_logo != 0) {
                                                seturl2(Helper.encypt_decrypt(response.data.data).c_logo_url)
                                        }
                                        setContactIdForDeactivate(Helper.encypt_decrypt(response.data.data).contact_id)
                                        setname1(Helper.encypt_decrypt(response.data.data).name)
                                        setclogo(Helper.encypt_decrypt(response.data.data).c_logo);
                                        setsettingcity(Helper.encypt_decrypt(response.data.data).city_text)
                                        setcontactid(Helper.encypt_decrypt(response.data.data).p_id)
                                        setstateofuser(Helper.encypt_decrypt(response.data.data).state_text)
                                        setMobileNumber(Helper.encypt_decrypt(response.data.data).mobile_number)
                                        setaltMobileNumber(Helper.encypt_decrypt(response.data.data).alternate_mobile_number)
                                        setpincode(Helper.encypt_decrypt(response.data.data).pincode_text)
                                }
                        })
                        .catch((err) => {
                                console.log(err)
                        })
        }

        function agreemobile31() {
                if (editMerchantImg && editMerchantImg != null) {
                        AWSCredentials(editMerchantImg)
                }
                if (MobileNumber != altMobileNumber) {
                        const agreemobile312 = {
                                method: "PATCH",
                                url: Helper.getWsUrl(cookies.skip) + `/contact/patch_contact/?web=1`,
                                headers: { "Content-Type": "application/json", "token": cookies.token },
                                data: {
                                        "c_logo": clogo,
                                        "is_customer": 1,
                                        "address": address,
                                        "alternate_mobile_number": altMobileNumber,
                                        "city_text": settingcity,
                                        "mobile_number": MobileNumber,
                                        "name": name1,
                                        "pincode_text": pincode,
                                        "state_text": stateofuser
                                }
                        }
                        axios(agreemobile312)
                                .then((response) => {
                                        if (response.data.code === 201) {
                                                getprofiledatafunction()
                                        }
                                        else if (response.data.code === 401) {
                                                removeCookies("logged_in")
                                                setTimeout(() => {
                                                        localStorage.setItem("isAuthenticated", false)
                                                        localStorage.removeItem("isAuthenticated")
                                                }, 1000)
                                        }
                                })
                                .catch((err) => {
                                        console.log(err)
                                })
                } else {
                        alert("Alternate Number Wont be same as Mobile Number")
                }
        }

        function pincodeapifunction(e) {
                if(e.target.value.length === 6) {
                        const pincnstant1 = {
                                method: "GET",
                                url: `https://api.postalpincode.in/pincode/${e.target.value}`,
                        }
                        axios(pincnstant1)
                                .then((response) => {                                
                                        setcity(response.data[0].PostOffice)
                                        setstateofuser(response.data[0].PostOffice[0].State)
                                })
                                .catch((err) => {
                                        console.log(err)
                                })
                }
        }

        const AWSCredentials = (editMerchantImg) => {
                const requestOptions = {
                        method: "GET",
                        url: Helper.getWsUrl(cookies.skip) + `/settings/get_aws_credientials/?web=1`,
                        headers: { "Content-Type": "application/json", "token": cookies.token },
                };
                axios(requestOptions)
                        .then((response) => {
                                if (response.data.code === 200) {
                                        const imgFileKey = '_original.png'
                                        const FOLDER_NAME = contactid + "/cp"
                                        const config = {
                                                bucketName: Helper.encypt_decrypt(response.data.data).bucket_name,
                                                region: "ap-south-1",
                                                dirName: FOLDER_NAME,
                                                accessKeyId: Helper.encypt_decrypt(response.data.data).aws_access_key,
                                                secretAccessKey: Helper.encypt_decrypt(response.data.data).aws_secret_access_key,
                                                s3Url: "https://enbfile.s3.ap-south-1.amazonaws.com",
                                        }
                                        const ReactS3Client = new S3(config)
                                        ReactS3Client
                                                .uploadFile(editMerchantImg, imgFileKey)
                                                .then(data => console.log("data : ", data))
                                                .catch(err => console.error("error", err))
                                }
                        });
        }

        return (
                <>                        
                        <Headerformobile2 pagename="Profile" navigate={navigate} />
                        <Header />
                        <div className='maindiv ' style={{ maxWidth: "100%", maxHeight: "100vh" }}>
                                <div className='row no-gutters mtp100' >
                                        <div className='path_direction dashbbdbooking' style={{ paddingLeft: "4%", wordSpacing: "3px", alignItems: "center", paddingTop: "20px" }}>
                                                <p>
                                                        <Link to="/dashboard" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Home</Link>
                                                        &gt;
                                                        <Link to="/profile" className='text-decoration-none  m-1' style={{ color: "#7A7A7A" }}>Profile</Link>
                                                </p>
                                        </div>
                                </div>
                                <div className='fullcolorofprofile'>
                                        <div className='row no-gutters justify-content-center mtwnadmob23' >
                                                {/* {set_profile_image.c_logo_url == null ?
                                                        "null"
                                                        : */}
                                                        <div className="firstpicprofile3">
                                                                <div className='iconprofile23'>
                                                                        <div id="xslUploadprofile23">
                                                                                {/* <label htmlFor="file-input">
                                                                                        <button className="btn importarprofile1 " type="button"> */}
                                                                                                <img src = {require('../Assets/Images/log.png')}
                                                                                                // src={(url2 === null) ? "../Assets/Images/log.png" : url2} 
                                                                                                className='rounded-circle imgnewprofile23 mainhwsetprofile' alt="null image"
                                                                                                        // onError={(e) =>
                                                                                                        //         (e.target.onerror = null)(
                                                                                                        //                 (e.target.src = require('../Assets/Images/log.png'))
                                                                                                        //         )
                                                                                                        // } 
                                                                                                        />
                                                                                        {/* </button>
                                                                                </label> */}
                                                                                {/* <input className="file-input flieinp1profile23" accept="image/*" id="file-input" type="file" onChange={previewFile23} /> */}
                                                                        </div>
                                                                </div>
                                                                {/* <div>
                                                                        <div className='icon1profile23'>
                                                                                <div id="xslUpload23">
                                                                                        <label htmlFor="file-input" >
                                                                                                <button className="btn importarprofile23" type="button"><img src={require('../Assets/Images/AddImage.jpg')} className='rounded-circle   imgnewprofile1' /></button>
                                                                                        </label>
                                                                                         <input className="file-input flieinp23" id="file-input" type="file" onChange={previewFile23} /> 
                                                                                </div>
                                                                        </div>
                                                                </div> */}
                                                        </div>
                                                {/* } */}
                                        </div>
                                        <div className='row justify-content-center no-gutters pt-2 pt-md-0' >
                                                <form className='mainformprofile' >
                                                        <div className="form-group row no-gutters  justify-content-around  mt-md-2 ">
                                                                <label htmlFor="nameperson" className="col-6 d-none d-lg-block col-form-label" id="nameidlabel">Name</label>
                                                                <div className="col-lg-6 col-12">
                                                                        <input type="text" className="" id="nameperson" placeholder="Enter your Name here" value={name1} onChange={(e) => { setinputfiledtatas(e); setname1(e.target.value); setnamecross(true) }} />
                                                                        {(namecross) ?
                                                                                <span className='spanofclose crossprofile hidecrossprofile' id="name123" style={{ position: "absolute", top: "8px", right: "-16px", fontSize: "-14px", color: "grey" }} onClick={() => { document.getElementById('nameperson').value = '' }}>
                                                                                        <i className="fa fa-times-circle crosslh" aria-hidden="true"></i>
                                                                                </span>
                                                                                :
                                                                                null
                                                                        }
                                                                </div>
                                                        </div>
                                                        <div className="form-group row no-gutters justify-content-around ">
                                                                <label htmlFor="contact" className="col-6 d-none d-lg-block col-form-label" id="contactlabl" >Contact Number*</label>
                                                                <div className="col-lg-6 col-12" style={{ position: "relative" }}>
                                                                        <input type="text" aria-label="Username" aria-describedby="basic-addon1" id="contact" className='conatctinputalignment' placeholder='Contact no.' maxLength="10" value={MobileNumber} autoComplete="off" onChange={(e) => { const re = /^[0-9\b]+$/; if (e.target.value === '' || re.test(e.target.value)) { setMobileNumber(e.target.value); } }} readOnly></input>
                                                                        <span className='border border-left-0 border-top-0 border-bottom-0 pr-1 cn ' style={{ position: "absolute", top: "8px", left: "5px", fontSize: "15px" }}>+91</span>
                                                                </div>
                                                        </div>
                                                        <div className='altstatehideprofile'>
                                                                <div className="form-group row no-gutters justify-content-start ">
                                                                        <label htmlFor="accontact" className="col-6 col-form-label" id="contactlabl1">Alternate Contact Number*</label>
                                                                        <div className="col-6 mltoseroprofile" style={{ position: "relative", marginLeft: "0px" }}>
                                                                                <input type="text" aria-label="Username" aria-describedby="basic-addon1" id="alcontact" className='conatctinputalignment  ' placeholder='Alternate No.' maxLength="10" value={altMobileNumber} autoComplete="off" onChange={(e) => { const re = /^[0-9\b]+$/; if (e.target.value === '' || re.test(e.target.value)) { setaltMobileNumber(e.target.value); } }} ></input>
                                                                                <span className='border border-left-0 border-top-0 border-bottom-0 pr-1 sidp91none2' style={{ position: "absolute", top: "8px", left: "7px", fontSize: "15px" }}>+91</span>
                                                                                <span className='spanofclosealprofile crossprofile hidecrossprofile' style={{ position: "absolute", top: "8px", right: "-15px", color: "grey" }} onClick={() => { document.getElementById('alcontact').value = '' }}><i className="fa fa-times-circle crosslh crosslh" aria-hidden="true"></i></span>
                                                                        </div>
                                                                </div>
                                                                <div className="form-group row no-gutters justify-content-md-around  ">
                                                                        <label htmlFor="state1" className="col-6 col-form-label" id="stateid" >State*</label>
                                                                        <div className="col-md-6 col-3">
                                                                                <input type="text" className="" id="state1" placeholder='State' value={stateofuser} readOnly />
                                                                                <span className='spanofclose crossprofile hidecrossprofile' style={{ position: "absolute", top: "8px", right: "-15px", color: "grey" }} onClick={() => { document.getElementById('alcontact').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='row no-gutters altstateshowprofile '>
                                                                <div className='col-6 '>
                                                                        <label htmlFor="altmob2" id="stateid" className='d-none d-lg-block '>Alternate no*</label>
                                                                        <div style={{ position: "relative", marginLeft: "0px" }}>
                                                                                <input type="text" className="newwidthforalternateandpstateprofilealt" id="altmob2" placeholder='Alternate No*' maxLength="10" value={altMobileNumber} autoComplete="off" onChange={(e) => { setaltecrossmob(true); const re = /^[0-9\b]+$/; if (e.target.value === '' || re.test(e.target.value)) { setaltMobileNumber(e.target.value); } }} />
                                                                                {altecrossmob ?
                                                                                        <span className='spanofclose1profile' style={{ position: "absolute", top: "8px", right: "-15px", color: "grey" }} onClick={() => { document.getElementById('altmob2').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                                        :
                                                                                        null
                                                                                }
                                                                        </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                        <label htmlFor="pin" className="col-6 col-form-label d-none d-lg-block " id="pincodeid" >Pincode*</label>
                                                                        <input type="text" className="newwidthforalternateandpstateprofilealt" id="pin" placeholder='Pincode*' maxLength="10" value={pincode} autoComplete="off" onChange={(e) => { setpincrossmob(true); const re12 = /^[0-9\b]+$/; if (e.target.value === '' || re12.test(e.target.value)); setpincode(e.target.value); pincodeapifunction(e) }} />
                                                                        {pincrossmob ?
                                                                                <span className='spanofcloseforpincode crossprofile hidecrossprofile' onClick={() => { document.getElementById('pin').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                                :
                                                                                null
                                                                        }
                                                                </div>
                                                        </div>
                                                        <div className="form-group row no-gutters hidestatefromweb32"  >
                                                                <label htmlFor="state" id="stateid" className='col-6 col-form-label d-none d-lg-block'>State*</label>
                                                                <div className="col-lg-6 col-12">
                                                                        <input type="text" className="newwidthforalternateandpstateprofile" id="state244" placeholder='State' value={stateofuser} readOnly />
                                                                </div>
                                                        </div>
                                                        <div className="form-group row no-gutters  justify-content-around order-md-1 ">
                                                                <label htmlFor="pin" className="col-6 col-form-label d-none d-lg-block " id="pincodeid" >Pincode*</label>
                                                                <div className="col-lg-6 col-12">
                                                                        <input type="text" className="newwidthforalternateandpstateprofilealt hidepinfrommob" id="pin" placeholder='Pincode*' maxLength="10" value={pincode} autoComplete="off" onChange={(e) => { const re12 = /^[0-9\b]+$/; if (e.target.value === '' || re12.test(e.target.value)); setpincode(e.target.value); pincodeapifunction(e) }} />
                                                                        <span className='spanofcloseforpincode crossprofile hidecrossprofile' onClick={() => { document.getElementById('pin').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                </div>
                                                        </div>
                                                        <div className="form-group row no-gutters  justify-content-around order-md-1">
                                                                <label htmlFor="dropdownMenuButton" className="col-6 col-form-label d-none d-lg-block" id="cityid">City*</label>
                                                                <div className="col-lg-6 col-12">
                                                                        <input type="text" className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" placeholder='--Select--City--' maxLength="10" value={settingcity} onChange={(e) => { setinputfiledtatas(e); setcitycross(true) }} autoComplete="off" readOnly />
                                                                        <div class="dropdown-menu dropdownwidth" aria-labelledby="dropdownMenuButton">
                                                                                {city.map((val234) => {
                                                                                        return (
                                                                                                <>
                                                                                                        <a class="dropdown-item" onClick={(e) => { console.log(e.target.innerText); setsettingcity(e.target.innerText) }}>{val234.District}</a>
                                                                                                </>
                                                                                        )
                                                                                })
                                                                                }
                                                                        </div>
                                                                        {citycross ?
                                                                                <span className='spanofclose crossprofile hidecrossprofile' style={{ position: "absolute", top: "8px", right: "-15px", color: "grey" }} onClick={() => { document.getElementById('dropdownMenuButton').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                                :
                                                                                null
                                                                        }
                                                                </div>
                                                        </div>
                                                        <div className="form-group row no-gutters  justify-content-around order-md-1">
                                                                <label htmlFor="Areaadd" className="col-6 d-none d-lg-block" id="addressid">Area Address*</label>
                                                                <div className="col-lg-6 col-12">
                                                                        <textarea className=" br14" id="Areaadd" rows="3" placeholder='Area Address*' value={address} onChange={(e) => { setinputfiledtatas(e); setaddress(e.target.value); setaddresscross(true) }}></textarea>
                                                                        {addresscross ?
                                                                                <span className='spanofclose crossprofile hidecrossprofile' style={{ position: "absolute", top: "8px", right: "-15px", color: "grey" }} onClick={() => { document.getElementById('Areaadd').value = '' }}><i className="fa fa-times-circle crosslh" aria-hidden="true"></i></span>
                                                                                :
                                                                                null
                                                                        }
                                                                </div>
                                                        </div>
                                                        <button className='btn submitbuttonprofile text-white pl-3 pr-3 mt-n1 agreebtnprofile' style={{ width: "150px", borderRadius: "20px", fontSize:"18px", fontWeight:"600" }} onClick={(e) => { agreemobile31(); e.preventDefault(); navigate("/dashboard") }}>Agree</button>
                                                        <div className='row justify-content-center no-gutters' style={{ position: "relative", top: "0px", paddingBottom: "10px" }}>
                                                                <div className=' d-lg-none ' style={{width:"100%"}} >
                                                                        <button className='btn btn-block orangecolprofile ' onClick={(e) => { agreemobile31(); e.preventDefault(); setTimeout(()=>{navigate("/dashboard")},500) }}>Agree</button>
                                                                        <button className='btn orangecol2profile btn-block mt-2' onClick={(e) => { logoutalldevices23(); e.preventDefault(); }}>Log Out</button>
                                                                        <button type="button" className='btn orangecol3profile btn-block mt-2'
                                                                        // onClick={() => {setDeactivatePopup(true); iosAlert('Are you sure, you want to deactivate your account ?')}}
                                                                                data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                                                        // onClick={(e) => { logoutalldevices23(); e.preventDefault(); }}
                                                                        >Deactivate</button>
                                                                </div>
                                                        </div>
                                                </form>
                                        </div>
                                </div>
                        </div>

                        <div class="modal" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-sm justify-content-center">
                                        <div class="modal-content" style={{borderRadius:"13px", border:"none", width: '270px', fontFamily: '-apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif', fontSize:'14px', lineHeight:'1.4'}}>
                                                <div class="modal-body">
                                                        Are you sure, you want to deactivate your account ?
                                                </div>
                                                <div class="modal-footer" style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"5px"}}>
                                                        <button style={{border:"none", background:"none", color:"#007aff", fontSize: '17px'}} type="button" data-bs-dismiss="modal">No</button>
                                                </div>
                                                <div class="modal-footer" style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"5px"}}>
                                                        <button style={{border:"none", background:"none", color:"#007aff", fontSize: '17px'}}  data-bs-dismiss="modal" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" >Confirm</button>
                                                </div>
                                        </div>
                                </div>
                        </div>

                        <div class="modal" id="exampleModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-sm justify-content-center" >
                                        <div class="modal-content" style={{borderRadius:"13px", border:"none", width: '270px', fontFamily: '-apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif', fontSize:'14px', lineHeight:'1.4'}}>
                                                <div class="modal-body">
                                                        <i class="fa fa-ban text-danger " aria-hidden="true"></i>
                                                        <br/><br/>
                                                        <p style={{padding:"0", margin:"0"}}>Sorry to see you go...</p>
                                                        <p style={{padding:"0", margin:"0"}}>your account will be deactivated in 30 days!</p>
                                                </div>
                                                <div class="modal-footer" style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"5px"}}>
                                                        <button style={{border:"none", background:"none", color:"#007aff", fontSize: '17px'}} type="button" data-bs-dismiss="modal" onClick={(e) => {  logoutalldevices23(); e.preventDefault(); }}>OK</button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}

