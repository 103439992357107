import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Newcss.css";
import Helper from "../util/Helper";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Productlist({searchItem, logo_shop, shoprodctpage, lat, lng, setividforproductpage, icidFrmIcToShop}) {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"],['km'],['category']);

  const [shopdetailss, setshopdetailss] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [t_that_shop_item_list, set_that_shop_item_list] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openBooking, setOpenBooking] = React.useState(false);
  const [minHeight, setMinHeight] = useState(false);
  const [getBbid, setGetBbid] = useState(null)
  const [pageNo, setPageNo] = useState(0)
  const [getIsTrusted, setGetIsTrusted] = useState(false)
  const [subCategoryList, setSubCategoryList] = useState([])
  const [isActiveSubCategoryBtn, setIsActiveSubCategoryBtn] = useState(null)
  const [selectedMasterCategory, setSelectedMasterCategory] = useState(null)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)

  const handleClickOpenBooking = () => {
    setOpenBooking(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenBooking(false);
  };

  useEffect(() => {
    setPageNo(0);
    if(icidFrmIcToShop && icidFrmIcToShop != null) {
      setSelectedSubCategory(icidFrmIcToShop);
    }
    callFuncForIcId(localStorage.getItem("contact_id"));
  }, [searchItem, selectedSubCategory, selectedMasterCategory]);
    
  useEffect(() => {
    if (getIsTrusted) {
      setPageNo(pageNo + 20)
      shopdetails(localStorage.getItem("contact_id"), selectedMasterCategory, selectedSubCategory);
    }
  }, [getIsTrusted])

  useEffect(() => {
    if(itemList && itemList.length > 0) {
      setGetIsTrusted(false)
      itemList.map((value, index) => {
        if (!t_that_shop_item_list.find((element) => value.iv_id === element.iv_id)) {
          set_that_shop_item_list((prevState) =>
            [...prevState, itemList[index]]
          )
        }
      })
    }
  },[itemList])

  const callFuncForIcId  = (contactId) => {
    const getBillListUrl = {
      method: "GET",
      url: Helper.getWsUrl(cookies.skip) + `/items/get_selected_item_category_new/${contactId}/?web=1`,
      headers: { "Content-Type": "application/json", 'token': cookies.token },
    };
    axios(getBillListUrl)
      .then((response) => {
              if (response.data.code === 200) {
                callFuncForMcId(contactId)
                // console.log("item category : ",Helper.encypt_decrypt(response.data.data))
                if(Helper.encypt_decrypt(response.data.data).length < 4) {
                  setSubCategoryList(Helper.encypt_decrypt(response.data.data))
                } else {
                  setSubCategoryList([])
                }
              } else if (response.data.code === 401) {
                removeCookie("logged_in")
                removeCookie("token")
                setTimeout(() => {
                        navigate('/')
                        localStorage.setItem("isAuthenticated", false)
                        localStorage.removeItem("isAuthenticated")
                }, 1000)
              }  else {
                setSubCategoryList([])
                shopdetails(contactId, selectedMasterCategory, selectedSubCategory);
              }
      })
      .catch((error) => {
              alert(error);
      })
  }

  const callFuncForMcId = (contactId) => {
    const getCategoryListFunc = {
      method: "GET",
      url: Helper.getWsUrl(cookies.skip) + `/items/get_merchant_category/${contactId}/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(getCategoryListFunc)
      .then((response) => {
        if(response.data.code === 200) {
          // console.log("master category id :",Helper.encypt_decrypt(response.data.data)[0].id)
          setSelectedMasterCategory(Helper.encypt_decrypt(response.data.data)[0].id)
          shopdetails(contactId, Helper.encypt_decrypt(response.data.data)[0].id, selectedSubCategory);
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // &mc_id=${mc_id}
  function shopdetails(dashcardidforshopdetails, mc_id, ic_id) {
    const getshopdetails = {
      method: "GET",
      url: Helper.getWsUrl(cookies.skip) + 
          `${(searchItem && searchItem != null) ? 
            `${(ic_id && ic_id != null && mc_id && mc_id != null) ? 
              `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?search=${searchItem}&web=1&b2b=0&ic_id=${ic_id}` 
            :
              `${!(mc_id && mc_id != null && ic_id && ic_id === null) ?
                `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?search=${searchItem}&web=1&b2b=0`
              :
                `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?search=${searchItem}&web=1&b2b=0`
              }`
            }`
          :
            `${(ic_id && ic_id != null && mc_id && mc_id != null) ? 
              `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?page_start=${pageNo}&total_record=20&web=1&b2b=0&ic_id=${ic_id}` 
            :
              `${(mc_id && mc_id != null) ?
                `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?page_start=${pageNo}&total_record=20&web=1&b2b=0`
              :
                `/contact/get_shop_catalogue_details_web/${dashcardidforshopdetails}/?page_start=${pageNo}&total_record=20&web=1&b2b=0`
              }`
            }`
          }`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(getshopdetails)
      .then((response) => {
        if(response.data.code === 200) {
          if(pageNo === 0) {
            set_that_shop_item_list([])
          }
          setLazyLoading(false);
          setshopdetailss(Helper.encypt_decrypt(response.data.data));

          if(searchItem != null) {
            setItemList([])
            set_that_shop_item_list( Helper.encypt_decrypt(response.data.data).item_details );
          } else {
            setItemList( Helper.encypt_decrypt(response.data.data).item_details );
          }
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        } else {
          setItemList([])
          if(pageNo === 0) {
            setLazyLoading(false);
            set_that_shop_item_list([])
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  function addfavshop(itemid, ivid, index) {
    const requestOptions528 = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/contact/add_favorite_shop/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        item_id: itemid,
        iv_id: ivid,
        shop_id: localStorage.getItem("contact_id"),
      },
    };
    axios(requestOptions528)
      .then((response) => {
        if (response.data.code === 201) {
          let filterTableArray = [...t_that_shop_item_list]
          let indexOfEditQty = index
          let indexOfArray
          let filtrTblArrElement = null
          filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(index == indexOfEditQty){
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].is_favorite = 1
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          set_that_shop_item_list(filterTableArray)
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removefavshop(itemid, ivid, index) {
    const requestOptions528 = {
      method: "Delete",
      url: Helper.getWsUrl(cookies.skip) + `/contact/remove_favorite_shop/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        item_id: itemid,
        iv_id: ivid,
        shop_id: localStorage.getItem("contact_id"),
      },
    };
    axios(requestOptions528)
      .then((response) => {
        if (response.data.code === 202) {
          let filterTableArray = [...t_that_shop_item_list]
          let indexOfEditQty = index
          let indexOfArray
          let filtrTblArrElement = null
          filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(index == indexOfEditQty){
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].is_favorite = 0
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          set_that_shop_item_list(filterTableArray)
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addtobag(itmid, ivid, index) {
    const requestOptions528 = {
      method: "POST",
      url: Helper.getWsUrl(cookies.skip) + `/booking/customer_add_booking_bag/?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        contact_id: localStorage.getItem("contact_id"),
        iv_id: ivid,
        quantity: 1.0,
      },
    };
    axios(requestOptions528)
      .then((response) => {
        if (response.data.code === 201) {
          setGetBbid(Helper.encypt_decrypt(response.data.data).bb_id)
          let filterTableArray = [...t_that_shop_item_list]
          let indexOfEditQty = index
          let indexOfArray
          let filtrTblArrElement = null
          filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(index == indexOfEditQty){
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].status = 1
          filtrTblArrElement[0].booking_quantity = 1
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          set_that_shop_item_list(filterTableArray)
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function changeItemQty(bbId, qty, index) {
    const addtocartapiconst = {
      method: "PATCH",
      url: Helper.getWsUrl(cookies.skip) + `/booking/quantity_status_change/${bbId}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
      data: {
        quantity: qty,
      },
    };
    axios(addtocartapiconst)
      .then((response) => {
        if (response.data.code === 200) {
          let filterTableArray = [...t_that_shop_item_list]
          let indexOfEditQty = index
          let indexOfArray
          let filtrTblArrElement = null
          filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(index == indexOfEditQty){
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].booking_quantity = qty
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          set_that_shop_item_list(filterTableArray)
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deletetocartapi(bbId, index) {
    const dlttocartapiconst = {
      method: "DELETE",
      url:
        Helper.getWsUrl(cookies.skip) +
        `/booking/delete_bag_list_items/${bbId}?web=1`,
      headers: { "Content-Type": "application/json", token: cookies.token },
    };
    axios(dlttocartapiconst)
      .then((response) => {
        if (response.data.code === 202) {
          let filterTableArray = [...t_that_shop_item_list]
          let indexOfEditQty = index
          let indexOfArray
          let filtrTblArrElement = null
          filtrTblArrElement = filterTableArray.filter((item, index) => {
            if(index == indexOfEditQty){
              indexOfArray = index
              return item
            }
          })
          filtrTblArrElement[0].status = 0
          filtrTblArrElement[0].booking_quantity = 0
          filterTableArray[indexOfArray] = filtrTblArrElement[0]
          set_that_shop_item_list(filterTableArray)
        } else if (response.data.code === 401) {
          removeCookie("logged_in");
          setTimeout(() => {
            navigate("/");
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("isAuthenticated");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (window.innerHeight > 700) {
      setMinHeight(true);
    }
  }, []);

  return (
    <>
      <Dialog PaperProps={{
          style: {
            position: "absolute",
            bottom: "0",
            color: "black",
            width: "100%",
            height: `${minHeight ? "30%" : "41%"}`,
            maxWidth: "100%",
            padding: "3%",
          },
        }} open={open} onClose={handleClose} TransitionComponent={Transition} >
        <Toolbar>
          <div className="mainLoginBox">
            <div className="LoginBoxTitleWrapper">
              <p className="LoginBoxTitle">Login</p>
            </div>
            <div className="LoginBoxParaWrapper">
              <p className="loginBoxPara">
                To add items to your favourites you need to login.
              </p>
            </div>
            <div className="loginBoxButtonWrapper">
              <button className="loginBoxButton" onClick={() => { navigate("/"); }} >
                Continue to login
              </button>
            </div>
          </div>
        </Toolbar>
      </Dialog>

      <Dialog PaperProps={{
          style: {
            position: "absolute",
            bottom: "0",
            color: "black",
            width: "100%",
            height: `${minHeight ? "30%" : "41%"}`,
            maxWidth: "100%",
            padding: "3%",
          },
        }} open={openBooking} onClose={handleClose} TransitionComponent={Transition} >
        <Toolbar>
          <div className="mainLoginBox">
            <div className="LoginBoxTitleWrapper">
              <p className="LoginBoxTitle">Login</p>
            </div>
            <div className="LoginBoxParaWrapper">
              <p className="loginBoxPara">To book items you need to login.</p>
            </div>
            <div className="loginBoxButtonWrapper">
              <button className="loginBoxButton" onClick={() => { navigate("/"); }}>
                Continue to login
              </button>
            </div>
          </div>
        </Toolbar>
      </Dialog>

      <div class="modal fade" id="AddToBag" tabindex="-1" aria-labelledby="AddToBagLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered spcl-modal">
          <div class="modal-content">
            <div class="modal-body">
              <p style={{ fontSize: "20px" }}>
                Log in or sign to add items in your bag!
              </p>
              <button data-bs-dismiss="modal" onClick={() => { navigate("/"); }} style={{ border: "none", padding: "10px 90px", backgroundColor: "#EE7A37", color: "white", fontWeight: "900", }} >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="Fav" tabindex="-1" aria-labelledby="FavLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered spcl-modal">
          <div class="modal-content">
            <div class="modal-body">
              <p style={{ fontSize: "20px" }}>
                Log in or sign to add items to your favourites!
              </p>
              <button data-bs-dismiss="modal" onClick={() => { navigate("/"); }} style={{ border: "none", padding: "10px 90px",  backgroundColor: "#EE7A37", color: "white", fontWeight: "900", }} >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mainproductlistdiv p-lg-5 pl-2 pr-2 pt-0 ">
        {lazyLoading ?
          <Skeleton className="skeletonForShopDetail" count={1} ></Skeleton>
        :
          <>
            <div className="shadow p-2" id="notprdcutsonmobileview">
              <div className="row">
                <div className="col">
                  <div className="media " style={{ position: "relative" }}>
                    <img className="mr-3 imaghwproctslist" src={logo_shop} alt="Generic  image" onError={(e) =>
                        (e.target.onerror == null)(
                          (e.target.src = require("../Assets/Images/log.png"))
                      )} />
                    <div className="media-body">
                      <div className="marginleftweb">
                        <div className="row">
                          <div className="col-12 text-left fs1 pl-0">
                            {shopdetailss.shop_name}
                          </div>
                          <div className="col-12 text-left fs2 pl-0">
                            {shopdetailss.address1}
                          </div>
                          <div className="col-12 text-left fs3 pl-0">
                            {cookies.category}
                          </div>
                          <div className="col-12 text-left fs4 pl-0">
                            <div className="row justify-content-between">
                              <div className="col-5 col">
                                {shopdetailss.alert}
                              </div>
                              <div className="col-5 text-right">
                                <img src={require("../Assets/Images/locationimage.jpg")} className="locatiojnimagesize" />
                                <span className="colororangetext">
                                  {cookies.km && cookies.km} kms away
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" pt-3" id="showprdcutsonmobileview">
              <div className="row forwebshopdata shadow-sm bg-white ml-1 mr-1 p-2">
                <div className="col-12 p-0 text-left font-weight-bold fs31 text-capitalize text-truncate mt-0">
                  {shopdetailss.shop_name}
                </div>
                <div className="col-12 p-0 text-left fs32 text-capitalize">
                  {shopdetailss.address1}
                </div>
                <div className="col-12  text-left">
                  <div className="row justify-content-between">
                    <div className="col-lg-5 col-7 pl-0 col- text-left">
                      <img src={require("../Assets/Images/locationimage.jpg")} className="locatiojnimagesize" />
                      <span className="colororangetext">
                        {parseFloat(cookies.km && cookies.km).toFixed(2)} kms away
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {/* card */}
        {lazyLoading ?
          <Skeleton height={"125px"} width={"100%"} style={{marginTop:"15px"}} count={5}></Skeleton>
        :
          <div className=" mt-lg-4 mt-0 mbileconatiner pl-md-5 pl-lg-1 pr-md-4 pr-lg-1 ">
            {/* row1 */}
            <div className="subCategoryNavBar" style={{display: subCategoryList && subCategoryList.length > 0 ? "flex" : "none"}}>
              {subCategoryList && subCategoryList.map((value, index) => {
                return (
                  <button onClick={() => {
                        setPageNo(0)
                        setIsActiveSubCategoryBtn(index)
                        setSelectedSubCategory(value.id)
                      }} className={isActiveSubCategoryBtn === index ? "activeSubCategory" : ""} >
                    {value.name}
                  </button>
                )
              })}
            </div>

            <div className="scrollonpagelist" onScroll={(event) => { setGetIsTrusted(event.isTrusted) }}>
              {/* card1 */}
              {t_that_shop_item_list && t_that_shop_item_list.length > 0 ? 
                <>
                  {t_that_shop_item_list && t_that_shop_item_list.map((val399, index) => {
                    return (
                      <>
                        <div className="itemCard">
                          <div className="cardImg">
                            <img src={val399.featured_img_url != null && val399.featured_img_url != "" && val399.featured_img_url != "N/A" ? val399.featured_img_url : require("../Assets/Images/log.png")} alt="Card image cap" onClick={() => { shoprodctpage(true); setividforproductpage(val399.iv_id); navigate('/shop/product')}}
                              onError={(e) =>
                                (e.target.onerror = null)(
                                  (e.target.src = require("../Assets/Images/log.png"))
                                )} />
                          
                            <div className="maindivforheart38 ">
                              {cookies.skip != 0 ?
                                <>
                                  {val399.is_favorite === 0 ?
                                    <img src={require("../Assets/Images/whiteheart.png")} className="whiteimagehw23" id="whiteheart"
                                      onClick={() => { addfavshop( val399.item_id, val399.iv_id, index ); }} ></img>
                                  : 
                                    <img src={require("../Assets/Images/orangeheart.png")} className="whiteimagehw355" id="orangeheart" onClick={() => {removefavshop( val399.item_id, val399.iv_id, index );}} ></img>
                                  }
                                </>
                              :
                                <img src={require("../Assets/Images/whiteheart.png")} className="whiteimagehw23" id="whiteheart" onClick={handleClickOpen} ></img>
                              }
                            </div>
                          </div>
                          <div className="cardbocyformobileset">
                              <h4 className="cardHeading">
                                {val399.itemName}
                              </h4>
                            <div className="cardDetail">
                              <p className="cardUnit">
                                Unit: {val399.min_qty != 0 ? val399.min_qty : 1} {val399.miu_name != null && val399.miu_name != '' ? `(${val399.miu_name})`: null}
                              </p>
                              <div className="cardPrice">
                                {val399.mrp === val399.amount ?
                                  <p>
                                    ₹ {val399.mrp}
                                  </p>
                                : 
                                  <>
                                    <p>
                                      ₹ {val399.amount}
                                      <span>
                                        <del>₹{val399.mrp}</del>
                                      </span>
                                    </p>
                                    <div>
                                      {parseFloat(val399.discount_per).toFixed(2)} %off
                                    </div>
                                  </>
                                }
                              </div>
                            </div>

                            {val399.status != 1 ? 
                              <>
                                {cookies.skip != 0 ? 
                                  <button className="cardButton" onClick={(e) => { addtobag(val399.item_id, val399.iv_id, index);}} >
                                    Add to Bag
                                  </button>
                                : 
                                  <button onClick={handleClickOpenBooking} className="cardButton">
                                    Add to Bag
                                  </button>
                                }
                              </>
                            :
                              <div className="cardBtnToAddQty">
                                <button onClick={() => {
                                    if (val399.booking_quantity > 1) {
                                      changeItemQty( val399.bb_id != null ? val399.bb_id : getBbid, val399.booking_quantity - 1, index);
                                    } else {
                                      deletetocartapi(val399.bb_id != null ? val399.bb_id : getBbid, index);
                                    }
                                  }} > - </button>
                                <p>
                                  {val399.booking_quantity}
                                </p>
                                <button onClick={() => { changeItemQty( val399.bb_id != null ? val399.bb_id : getBbid, val399.booking_quantity + 1, index ); }} >
                                  +
                                </button>
                              </div>
                            }
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              :
                <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                  <img width={'60%'} src={require("../Assets/Images/No-Products_Found.png")} />
                  <p style={{fontSize:"16px", fontWeight:"600", opacity:"0.8", color:"#ea7d3d"}}>No product found</p>
                </div>
              }
            </div>
          </div>
        }       
      </div>
    </>
  );
}
